import Decimal from 'decimal.js';
import { executionConfigs, StageSuccession } from '@/pages/debug/NewExe/configs';
import { ExecutionRecipeConfig } from '@/pages/debug/NewExe/types';
import { ExecutionState, PartIdentifier, WorkOrderKind } from '@/utils/api/fab.types';

export const partIdEq = (a: PartIdentifier, b: PartIdentifier): boolean =>
  (!!a.lot_number && !!b.lot_number && a.lot_number === b.lot_number) ||
  (!!a.serial_number && !!b.serial_number && a.serial_number === b.serial_number);

export const isObject = (x: any): x is object => x && typeof x === 'object' && !Array.isArray(x);

const CODEC_TYPE_PROP = '$$type' as const;
const CODEC_VALUE_PROP = '$$value' as const;

export const jsonSerialize = (_: any, value: any) => {
  if (Decimal.isDecimal(value)) {
    return {
      [CODEC_TYPE_PROP]: 'Decimal',
      [CODEC_VALUE_PROP]: value.toString(),
    };
  }

  if (isObject(value)) {
    const prepared = {};
    for (const key in value) {
      /* @ts-ignore */
      prepared[key] = jsonSerialize(key, value[key]);
    }
    return prepared;
  }

  return value;
};

export const jsonDeserialize = (_: any, value: any) => {
  if (isObject(value)) {
    if (CODEC_TYPE_PROP in value && CODEC_VALUE_PROP in value) {
      if (value[CODEC_TYPE_PROP] === 'Decimal') {
        return new Decimal(value[CODEC_VALUE_PROP] as any);
      }
    }
    const prepared = {};
    for (const key in value) {
      /* @ts-ignore */
      prepared[key] = jsonDeserialize(key, value[key]);
    }
  }
  return value;
};

//todo: extract to generic merge and move to /utils
const mergeConfig = (base: any, override: any): any => {
  if (!isObject(base) || !isObject(override)) {
    return override || base;
  }

  const merged = {};

  for (const key in base) {
    /* @ts-ignore */
    merged[key] = mergeConfig(base[key], override[key]);
  }

  for (const key in override) {
    if (!(key in base)) {
      /* @ts-ignore */
      merged[key] = override[key];
    }
  }

  return merged;
};

export const getRecipeConfig = (recipeId: number): ExecutionRecipeConfig => {
  const recipeCfg = executionConfigs[recipeId];
  if (!recipeCfg) {
    return { ...executionConfigs.default };
  }

  return mergeConfig(executionConfigs.default, recipeCfg);
};

/// Note, this returns 'false' if stage == currentStage
export const isStagePassed = (
  workOrderKind: WorkOrderKind,
  stage: ExecutionState,
  currentStage: ExecutionState
): boolean => {
  if (!(workOrderKind in StageSuccession)) {
    console.warn('unknown workOrderKind[%o]', workOrderKind);
    return false;
  }

  for (const orderedState of StageSuccession[workOrderKind]) {
    // if we encounter this stage first, it means our target stage is after it
    if (orderedState === currentStage) {
      return false;
    }

    if (orderedState === stage) {
      return true;
    }
  }

  return false;
};
