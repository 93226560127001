import React, { useState, useEffect } from 'react';
import { Paper, Stack } from '@mantine/core';
import StatusIndicator from './ExecutionStatusIndicator';
import ExecutionInfo from './ExecutionInfo';
import { ISingleExecutionSlice } from '@/store/interfaces/single-execution/store';
import { IStageController } from '@/store/interfaces';
import { WorkOrderKind } from '@/utils/api/fab.types';

interface QAExecutionCellProps {
    executionKey: string;
    execution: ISingleExecutionSlice;
}

const QAExecutionCell: React.FC<QAExecutionCellProps> = ({ executionKey, execution }) => {
    const [isHighlighted, setIsHighlighted] = useState(false);
    const [prevStatus, setPrevStatus] = useState(execution.status);
    const [prevStage, setPrevStage] = useState(execution.controller?.currentStageType);

    const [column, row] = executionKey.split('::');

    useEffect(() => {
        const currentStage = execution.controller?.currentStageType;
        if (execution.status !== prevStatus || currentStage !== prevStage) {
            setIsHighlighted(true);
            const timer = setTimeout(() => setIsHighlighted(false), 1000);
            setPrevStatus(execution.status);
            setPrevStage(currentStage);
            return () => clearTimeout(timer);
        }
        return () => {};
    }, [execution.status, execution.controller?.currentStageType]);

    const controller = execution.controller as
        unknown as IStageController<WorkOrderKind.Qa> | undefined;

    const qaStage = controller?.stages.qa;

    return (
        <Paper
          p="md"
          withBorder
          style={{
                transition: 'background-color 0.3s ease',
                backgroundColor: isHighlighted ? '#eee' : 'transparent',
                border: execution.focused ? '1px solid #f00' : '1px solid #ddd',
            }}
        >
            <Stack gap="xs">
                <div>{`${column}:${row}`}</div>
                <StatusIndicator label="Execution" status={execution.status} />
                {qaStage && (
                    <StatusIndicator label="Qa" status={qaStage.status} />
                )}

                <ExecutionInfo execution={execution} />
            </Stack>
        </Paper>
    );
};

export default QAExecutionCell;
