import { Logger } from '@/logger';
import { IScanner } from '@/store/interfaces';
import { getGlobal } from '@/utils/global';
import { BarcodeEvent, EV_BARCODE } from '@/utils/KeyInput/Barcode';

type OnScanCallback = (scannedCode: string) => void;

const logger = new Logger('Scanner');

export function createScanner(): IScanner {
  logger.debug('Registering scanner listener');

  let onScanListeners: OnScanCallback[] = [];

  const barcodeInput = getGlobal('barcodeInput');

  barcodeInput.addEventListener(EV_BARCODE, (ev) => {
    const code = (ev as BarcodeEvent).barcode;
    callOnScanListeners(code);
  });

  const callOnScanListeners = (code: string) => {
    logger.debug('Scanned code:', { code, listeners: onScanListeners.length });
    onScanListeners.forEach((cb) => cb(code));
  };

  const onScan = (callback: OnScanCallback) => {
    onScanListeners.push(callback);
    return {
      unsubscribe: () => {
        onScanListeners = onScanListeners.filter((cb) => cb !== callback);
      },
    };
  };
  return {
    onScan,
  };
}
