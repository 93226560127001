import { isDevEnv } from '@/config';
import { Logger } from '@/logger';
import { ExecutionRecipeConfig } from '@/pages/debug/NewExe/types';
import { IState } from '@/store/interfaces';
import { IApi } from '@/store/interfaces/api';
import {
  ICardProgData,
  ICardProgDataLoader,
  ICardProgIO,
  ICardProgStage,
  ICardProgStagePayload,
} from '@/store/interfaces/stages/card-prog';
import { IStage } from '@/store/interfaces/stages/generic';
import { createErrorHandler } from '@/store/utils/error-handler';
import { ExecutionState, WorkbenchExecution } from '@/utils/api/fab.types';
import { isStringWithLength, pick, wait } from '@/utils/fns';

const logger = new Logger('CardProgStage');

// Constants
const BOX_PART_ID = 420;
const NOA_CARD_PART_ID = 412;
const DATA_VERSION = 1;
const PROBE_INTERVAL = 50;
const PROBE_DEBOUNCE = 200;
const RESTART_DELAY = 2000;
const DEFAULT_LOT_NUMBER_PERSISTENCE = 20;

interface CardProgStageParams {
  executionData: WorkbenchExecution;
  config: ExecutionRecipeConfig;
  loader: ICardProgDataLoader;
  state: IState<ICardProgStage>;
  io: ICardProgIO;
  api: IApi;
  onCompletion: () => void;
}

export async function createCardProgStage(args: CardProgStageParams): Promise<ICardProgStage> {
  const {
    state: _state,
    io,
    api,
    loader,
    config,
    executionData: _executionData,
    onCompletion,
  } = args;
  logger.addMetadata({ executionId: _executionData.id });
  let lastProbeUpdate = 0;
  let isRunningCardProbe = false;
  let liveProbeRunning = false;
  let probeInterval: NodeJS.Timeout | null = null;

  const initialData = await loader.loadData(_executionData, config, DATA_VERSION);
  logger.info('Creating CardProg stage', { executionId: _executionData.id, initialData });

  const errorHandler = createErrorHandler(_state, logger);

  const startLiveProbe = () => {
    logger.debug('Live probe started', { executionId: _executionData.id });
    liveProbeRunning = true;
  };

  const stopLiveProbe = () => {
    logger.debug('Live probe stopped', { executionId: _executionData.id });
    liveProbeRunning = false;
  };

  const startProbeLoop = () => {
    logger.debug('Starting probe interval', {
      interval: PROBE_INTERVAL,
      executionId: _executionData.id,
    });
    probeInterval = setInterval(handlePeriodicCardProbe, PROBE_INTERVAL);
  };

  const stopProbeLoop = () => {
    logger.debug('Stopping probe interval', { executionId: _executionData.id });
    if (probeInterval) {
      clearInterval(probeInterval);
      probeInterval = null;
    }
  };

  const detectAndProgramCard = async () => {
    if (_state.get().completed) {
      logger.warn('CardProg::detectAndProgramCard -> Stage already completed, exiting...', {
        executionId: _executionData.id,
      });
      return;
    }

    if (isDevEnv && !io.fab) {
      logger.warn('CardProg::probeCard -> no cardProg available in dev environment', {
        executionId: _executionData.id,
      });
      _state.set((state) => {
        state.data.liveCardUid = undefined;
        state.data.liveCardStatus = 'missing';
      });
      return;
    }

    if (!io.fab) {
      logger.error('CardProg::probeCard -> no cardProg available', {
        executionId: _executionData.id,
      });
      errorHandler.addError('cardProg', 'Card programmer not available');
      return;
    }

    if (_state.get().data.programmed) {
      logger.info('CardProg::probeCard -> card already programmed, exiting', {
        executionId: _executionData.id,
      });
      stopLiveProbe();
      return;
    }

    stopLiveProbe();

    try {
      logger.info('Start reading card', { executionId: _executionData.id });
      const result = await io.fab.cardprog.probeCard({ fast: true });
      logger.info('CardProg reading result', { result, executionId: _executionData.id });
      const { cardId, cardUid, error, status } = result;

      error ? errorHandler.addError('cardProg', error) : errorHandler.removeError('cardProg');

      _state.set((state) => {
        const hasCardUidMismatch =
          isStringWithLength(cardUid) &&
          isStringWithLength(state.data.cardUid) &&
          state.data.cardUid !== cardUid;
        if (hasCardUidMismatch && state.data.noaId) {
          logger.info(
            'Resetting NOA ID due to new card detection. This should allow new card qr to be scanned',
            {
              oldNoaId: state.data.noaId,
              newCardUid: cardUid,
              executionId: _executionData.id,
            }
          );
          state.data.noaId = undefined;
        }
        state.data.liveCardUid = cardUid;
        state.data.liveCardStatus = status;
      });

      const { data: _data } = _state.get();
      const shouldProgramCard = status === 'ready' && cardUid && _data.cardUid !== cardUid;
      if (shouldProgramCard) {
        logger.info('Card ready for programming', { cardUid, executionId: _executionData.id });
        await handleCardProgramming(cardUid, _data);
      } else if (status === 'programmed') {
        const reader = { cardId, cardUid };
        const isValidData = await verifyCardData(reader, _data);
        if (!isValidData) {
          startLiveProbe();
        }
      } else {
        logger.debug('Skipping card programming', {
          cardUid,
          status,
          executionId: _executionData.id,
          data: _data,
        });
        startLiveProbe();
      }
    } catch (error) {
      handleProbeError(error as Error);
    }
  };

  const handleCardProgramming = async (cardUid: string, data: ICardProgData) => {
    if (!isStringWithLength(data.cardId)) {
      logger.warn('CardProg::handleCardProgramming -> No card ID available, skipping programming', {
        cardUid,
        executionId: _executionData.id,
      });
      errorHandler.addError('cardProg', 'No card ID available');
      startLiveProbe();
      return;
    }

    logger.info('Starting card programming', {
      cardId: data.cardId,
      cardUid,
      executionId: _executionData.id,
    });

    const isProgrammed = await programCard(data.cardId, cardUid);
    if (!isProgrammed) {
      logger.warn('Card programming failed', {
        cardId: data.cardId,
        cardUid,
        executionId: _executionData.id,
      });
      errorHandler.addError('cardProg', 'Card programming failed');
      startLiveProbe();
      return;
    }

    logger.info('Card successfully programmed and verified', {
      cardId: data.cardId,
      cardUid,
      executionId: _executionData.id,
    });
    _state.set((state) => {
      state.data.programmed = true;
      state.data.cardUid = cardUid;
    });
    await handleProgrammingSuccess();
  };

  const programCard = async (cardId: string, cardUid: string): Promise<boolean> => {
    try {
      logger.info('Attempting to program card', {
        cardId,
        cardUid,
        executionId: _executionData.id,
      });
      const result = await io.fab.cardprog.programCard(cardId, cardUid);
      logger.info('Card programming result', {
        result,
        cardId,
        cardUid,
        executionId: _executionData.id,
      });

      if (!result.ok) {
        logger.error('CardProg::programCard => Card programming failed', {
          cardId,
          cardUid,
          result,
          executionId: _executionData.id,
        });
        errorHandler.addError(
          'cardProg.program',
          `Card programming failed. ${result.error || 'Unknown error'}`
        );
        // If the card programming failed, we should remove the data to allow for a new card to be programmed
        // TODO: we need to discuss what happens if the card programming fails. Some workers seem to discard the card will other try again with same card and succeed.
        // _state.set((state) => {
        //   state.data.noaId = undefined;
        //   state.data.liveCardUid = undefined;
        //   state.data.cardUid = undefined;
        //   state.data.programmed = false;
        // });
      } else {
        errorHandler.removeError('cardProg.program');
      }
      return result.ok;
    } catch (e) {
      const errorMessage = e instanceof Error ? e.message : 'Unknown error during card programming';
      logger.error('CardProg::programCard error', {
        error: errorMessage,
        cardId,
        cardUid,
        executionId: _executionData.id,
      });
      errorHandler.addError('cardProg', errorMessage);
      return false;
    }
  };

  const handleProgrammingSuccess = async () => {
    try {
      logger.info('Handling successful card programming', { executionId: _executionData.id });
      // Disabled log, as requested by @paulblid
      // const log = await io.fab.cardprog.getLog();
      // _state.set((state) => {
      //   state.data.log = log;
      // });

      // await putLogIntoOutputArtifacts(log);
      await io.fab.cardprog.clearLog().catch((e) => {
        logger.warn('Failed to clear card programming log', {
          error: e,
          executionId: _executionData.id,
        });
      });
    } catch (e) {
      logger.error('CardProg::handleProgrammingSuccess -> Failed to process artifacts', {
        error: e,
        executionId: _executionData.id,
      });
    }

    checkCompletion();
  };

  const handleProbeError = async (error: Error) => {
    const shouldRestartCardProbe = error.message.includes('No card reader found');
    logger.error('CardProg::handleProbeError', {
      error: error.message,
      shouldRestart: shouldRestartCardProbe,
      executionId: _executionData.id,
    });
    errorHandler.addError('cardProg', error.message);

    if (shouldRestartCardProbe) {
      stopLiveProbe();
      logger.info('Attempting to restart card programmer', { executionId: _executionData.id });
      await io.fab.cardprog.restart();
      await wait(RESTART_DELAY).then(startLiveProbe);
    } else {
      startLiveProbe();
    }
  };

  const verifyCardData = async (
    reader: {
      cardId: string | undefined;
      cardUid: string | undefined;
    },
    data: Pick<ICardProgData, 'cardId' | 'cardUid' | 'liveCardUid' | 'boxSerialNumber'>
  ): Promise<boolean> => {
    logger.debug('CardProg::verifyCardData => Verifying card data', {
      reader,
      data,
      executionId: _executionData.id,
    });

    if (!reader.cardId) {
      logger.warn('CardProg::verifyCardData -> Card ID is missing', {
        reader,
        executionId: _executionData.id,
      });
      errorHandler.addError('cardProg', 'Try again with the same card.');
      return false;
    }

    const cachedCardData = loader.loadCardDataFromCache(reader.cardId);

    if (cachedCardData) {
      logger.warn('CardProg::verifyCardData -> Card found in cache. Card already programmed', {
        reader,
        cachedCardData,
        executionId: _executionData.id,
      });
      errorHandler.addError('cardProg', 'Card already programmed');
      return false;
    }

    const isCardIdMatch = reader.cardId && reader.cardId === data.cardId;
    const isCardUidMatch =
      reader.cardUid && (reader.cardUid === data.cardUid || reader.cardUid === data.liveCardUid);
    if (isCardIdMatch && isCardUidMatch) {
      logger.info('CardProg::verifyCardData => Card programmed with correct data', {
        reader,
        data,
        executionId: _executionData.id,
      });
      _state.set((state) => {
        state.data.programmed = true;
        state.data.cardUid = reader.cardUid;
      });
      await handleProgrammingSuccess();
      return true;
    }

    // Reset noaId to allow for a new card to be scanned
    _state.set((state) => {
      state.data.noaId = undefined;
    });

    try {
      const card = await api.fixes.getCard(reader.cardId);
      if (!card) {
        logger.warn('CardProg::verifyCardData -> Card not found in database', {
          reader,
          executionId: _executionData.id,
        });
        errorHandler.addError('cardProg', 'Programmed card not found in database');
      } else if (card.box_serial_number === data.boxSerialNumber) {
        logger.warn('CardProg::verifyCardData -> Card already programmed, but data mismatch', {
          reader,
          card,
          data,
          executionId: _executionData.id,
        });
        errorHandler.addError('cardProg', 'Card already programmed');
      } else if (card && card.box_serial_number !== data.boxSerialNumber) {
        logger.warn('CardProg::verifyCardData -> Card box serial number mismatch', {
          reader,
          executionId: _executionData.id,
        });
        errorHandler.addError('cardProg', 'Programmed card from another box');
      } else {
        logger.warn('CardProg::verifyCardData -> Card data mismatch', {
          reader,
          card,
          data,
          executionId: _executionData.id,
        });
        errorHandler.addError('cardProg', 'Programmed card data mismatch');
      }
    } catch (e) {
      logger.error('CardProg::verifyCardData -> Failed to get card data', {
        error: e,
        reader,
        executionId: _executionData.id,
      });
      errorHandler.addError('cardProg', 'Failed to get card data');
    }
    return false;
  };

  const handlePeriodicCardProbe = async () => {
    if (!liveProbeRunning || isRunningCardProbe) return;

    const now = Date.now();
    if (now - lastProbeUpdate < PROBE_DEBOUNCE) return;

    lastProbeUpdate = now;
    isRunningCardProbe = true;

    try {
      await detectAndProgramCard();
    } catch (e) {
      logger.error('CardProg::probeCheck error', { error: e, executionId: _executionData.id });
    } finally {
      lastProbeUpdate = Date.now();
      isRunningCardProbe = false;
    }
  };

  const getPayload = (): ICardProgStagePayload => {
    const { data: _data } = _state.get();
    if (!_data.boxSerialNumber || !_data.cardUid || !_data.noaId) {
      const error = 'Missing required data for CardProg payload';
      logger.error('CardProg::getPayload error', {
        error,
        data: _data,
        executionId: _executionData.id,
      });
      errorHandler.addError('cardProg', error);
      throw new Error(error);
    }

    logger.info('CardProg payload generated', { payload: _data, executionId: _executionData.id });
    return {
      name: ExecutionState.CardProg,
      box_serial_number: _data.boxSerialNumber,
      card_uid: _data.cardUid,
      card_noa_id: _data.noaId,
    };
  };

  const handleSerialNumberScan: IStage['handleSerialNumberScan'] = (
    serialNumbers,
    identifier,
    itemType
  ) => {
    logger.info('CardProg::handleSerialNumberScan', {
      serialNumbers,
      identifier,
      itemType,
      executionId: _executionData.id,
    });

    if (_state.get().completed) {
      logger.warn('CardProg::handleSerialNumberScan -> Stage already completed, exiting...', {
        executionId: _executionData.id,
      });
      return false;
    }

    const isCardItem = itemType === 'card';
    const boxPart = serialNumbers.find((sn) => sn.part_id === BOX_PART_ID);
    if (!isCardItem && boxPart && isStringWithLength(identifier.serial_number)) {
      handleBoxScan(identifier.serial_number);
      return true;
    }

    const noaCard = serialNumbers.find((sn) => sn.part_id === NOA_CARD_PART_ID);
    if (isCardItem && noaCard && isStringWithLength(identifier.serial_number)) {
      const noaSerialNumber = identifier.serial_number;
      const isUsed = loader.isNoaSerialNumberUsed(noaSerialNumber);
      if (isUsed) {
        logger.warn('CardProg::handleSerialNumberScan -> NOA card already scanned', {
          noadSerialNumber: noaSerialNumber,
          executionId: _executionData.id,
        });
        return false;
      }

      handleNoaCardScan(identifier.serial_number);
      // Save the NOA serial number into the output stage to avoid scanning it again in the output stage
      // This is an optimization to avoid scanning the NOA card twice
      loader.saveNoaSerialIntoOutputStage(NOA_CARD_PART_ID, identifier.serial_number);
    }

    checkCompletion();
    return false;
  };

  const handleBoxScan = (serialNumber: string) => {
    logger.info('Handling box scan', { serialNumber, executionId: _executionData.id });
    _state.set((state) => {
      state.data.boxSerialNumber = serialNumber;
    });

    const executions =
      config.input?.[BOX_PART_ID]?.lotNumberPersistence ||
      config.input?.lotNumberPersistence ||
      DEFAULT_LOT_NUMBER_PERSISTENCE;
    io.barcode.persistBarcode(BOX_PART_ID, serialNumber, executions);
    logger.info('Box serial number persisted', {
      serialNumber,
      executions,
      executionId: _executionData.id,
    });
    checkCompletion();
  };

  const handleNoaCardScan = (serialNumber: string) => {
    logger.info('Handling NOA card scan', { serialNumber, executionId: _executionData.id });
    const { data: _data } = _state.get();

    if (_data.programmed) {
      handleProgrammedNoaCardScan(_data, serialNumber);
    }

    _state.set((state) => {
      state.data.noaId = serialNumber;
    });
  };

  const handleProgrammedNoaCardScan = (data: ICardProgData, newNoaId: string) => {
    logger.warn('CardProg::handleProgrammedNoaCardScan -> Card already programmed but not saved', {
      data: pick(data, [
        'executionId',
        'cardUid',
        'cardId',
        'boxSerialNumber',
        'noaId',
        'programmed',
      ]),
      newNoaId,
      executionId: _executionData.id,
    });

    if (data.noaId && data.noaId !== newNoaId) {
      logger.warn(
        'CardProg::handleProgrammedNoaCardScan -> New noaId card, resetting programmed data',
        {
          oldNoaId: data.noaId,
          newNoaId,
          oldCardUid: data.cardUid,
          executionId: _executionData.id,
        }
      );

      _state.set((state) => {
        state.data.programmed = false;
        state.data.cardUid = undefined;
        state.data.liveCardUid = undefined;
      });
      startLiveProbe();
    }
  };

  const loadBoxSerial = () => {
    const serialNumber = io.barcode.requestLotNumber(BOX_PART_ID);
    const { data: _data } = _state.get();
    if (!isStringWithLength(serialNumber) || isStringWithLength(_data.boxSerialNumber)) {
      logger.debug('No valid box serial number to load', {
        serialNumber,
        currentBoxSerial: _data.boxSerialNumber,
        executionId: _executionData.id,
      });
      return;
    }

    logger.info('Loading box serial number', { serialNumber, executionId: _executionData.id });
    _state.set((state) => {
      state.data.boxSerialNumber = serialNumber;
    });
  };

  const checkCompletion = () => {
    if (_state.get().completed) {
      logger.info('CardProg::checkCompletion -> Stage already completed, exiting...', {
        executionId: _executionData.id,
      });
      return true;
    }

    const { data } = _state.get();
    if (isCompleted(data)) {
      logger.info('CardProg::checkCompletion -> Stage completed, advancing...', {
        data,
        executionId: _executionData.id,
      });
      stopProbeLoop();

      _state.set((state) => {
        state.completed = true;
      });

      onCompletion();
      return true;
    }
    logger.debug('CardProg::checkCompletion -> Stage not yet completed', {
      data,
      executionId: _executionData.id,
    });
    return false;
  };

  const onExecutionDataUpdated = async (executionData: WorkbenchExecution) => {
    if (!('card_id' in executionData.state_data)) {
      logger.warn('Execution data updated, but no card_id present', {
        executionId: _executionData.id,
      });
      return;
    }

    const { card_id, card_uid, card_noa_id, box_serial_number } = executionData.state_data;
    logger.info('Execution data updated', {
      card_id,
      card_uid,
      card_noa_id,
      box_serial_number,
      executionId: _executionData.id,
    });

    _state.set((state) => {
      const { data } = state;
      if (card_id !== data.cardId) {
        logger.info('Updating cardId', {
          oldCardId: data.cardId,
          newCardId: card_id,
          executionId: _executionData.id,
        });
        data.cardId = card_id;
      }
      if (card_uid && card_uid !== data.cardUid) {
        logger.info('Updating cardUid', {
          oldCardUid: data.cardUid,
          newCardUid: card_uid,
          executionId: _executionData.id,
        });
        data.cardUid = card_uid;
      }
      if (card_noa_id && card_noa_id !== data.noaId) {
        logger.info('Updating noaId', {
          oldNoaId: data.noaId,
          newNoaId: card_noa_id,
          executionId: _executionData.id,
        });
        data.noaId = card_noa_id;
      }
      if (box_serial_number && box_serial_number !== data.boxSerialNumber) {
        logger.info('Updating boxSerialNumber', {
          oldBoxSerialNumber: data.boxSerialNumber,
          newBoxSerialNumber: box_serial_number,
          executionId: _executionData.id,
        });
        data.boxSerialNumber = box_serial_number;
      }
    });
  };

  return {
    type: ExecutionState.CardProg,
    errors: {},
    data: initialData,
    completed: false,
    getPayload,
    handleSerialNumberScan,
    onExecutionDataUpdated,
    onEnter: async () => {
      logger.info('CardProg stage onEnter', { executionId: _executionData.id });
      const { data } = _state.get();

      const hasCompleted = checkCompletion();
      if (hasCompleted) {
        logger.info('CardProg::onEnter -> Stage already completed, advancing...', {
          executionId: _executionData.id,
        });
        return;
      }

      loadBoxSerial();
      if (!data.cardUid) {
        logger.info('No cardUid present, starting live probe', { executionId: _executionData.id });
        startLiveProbe();
      }
      startProbeLoop();
    },
    onExit: async () => {
      logger.info('CardProg stage onExit', { executionId: _executionData.id });
      stopLiveProbe();
      stopProbeLoop();
    },
    onPostExit: async () => {
      logger.info('CardProg stage onPostExit', { executionId: _executionData.id });
      const { data } = _state.get();
      if (data.boxSerialNumber) {
        logger.info('Decrementing box serial number', {
          boxSerialNumber: data.boxSerialNumber,
          executionId: _executionData.id,
        });
        io.barcode.decrementBarcodes([data.boxSerialNumber]);
      }
      // Cache the card data in a buffer to check if is the same card in the next scan
      loader.storeCardDataInCache(data);
    },
    onCompletionFailed: async () => {
      // Reset the noaId. The operator may have left the prev card and scanned the old noaId
      _state.set((state) => {
        state.completed = false;
        state.data.noaId = undefined;
      });
    },
    handleReset: () => {
      logger.info('CardProg stage reset', { executionId: _executionData.id });
      _state.set((state) => {
        state.completed = false;
        state.data.programmed = false;
        state.data.liveCardUid = undefined;
        state.data.cardUid = undefined;
        state.data.noaId = undefined;
      });
      startLiveProbe();
      startProbeLoop();
    },
  };
}

/**
 * Checks if the CardProg stage is completed.
 * @param data - The current card programming data
 * @returns True if all required data is present and the card is programmed, false otherwise
 */
function isCompleted(data: ICardProgData): boolean {
  const { programmed, boxSerialNumber, cardId, cardUid, noaId } = data;
  const result = programmed && [boxSerialNumber, cardId, cardUid, noaId].every(isStringWithLength);
  logger.debug('CardProg completion check', {
    result,
    programmed,
    boxSerialNumber,
    cardId,
    cardUid,
    noaId,
  });
  return result;
}
