import { Container, Text } from '@mantine/core';
import { ErrorBoundary } from 'react-error-boundary';
import { useEffect } from 'react';
import { fullOsAndDevicesRestart } from '@/utils/fab-wb/fns';
import { useNamedCommand } from '@/utils/KeyInput/NumpadCommand';
import { StorageKeys, useFabSessionStorage } from '@/utils/hooks';
import { SingleExecution } from './SingleExecution';
import { useSingleExecution } from '@/store/states/singel-execution/hooks/useSingleExecution';

export function Execution() {
  const [autoAssign, setAutoAssign] = useFabSessionStorage(StorageKeys.AutoAssign, false);

  const { startExecution, resetStage, execution, eRenderData, status } = useSingleExecution({
    autoAssign,
  });

  useNamedCommand('FullOsRestart', fullOsAndDevicesRestart);
  useNamedCommand('StartAutoAssign', () => setAutoAssign(true));
  useNamedCommand('StopAutoAssign', () => setAutoAssign(false));
  useNamedCommand('StartTask', () => {
    startExecution();
  });
  useNamedCommand('FullPageRefresh', () => window.location.reload());
  useNamedCommand('ResetStage', resetStage);
  const idle = status === 'idle' || status === 'completed';

  useEffect(() => {
    if (autoAssign && idle) {
      startExecution();
    }
  }, [autoAssign, idle, startExecution]);

  const statusBar = (
    <Text bg="gray" p="5px 15px" mb="sm">
      autoAssign: {autoAssign ? 'yes' : 'no'}, idle: {idle ? 'yes' : 'no'}
    </Text>
  );

  if (idle) {
    if (autoAssign) {
      return (
        <>
          {statusBar}
          Loading next task...
        </>
      );
    }
    return (
      <Container p={10}>
        {statusBar}
        No execution assigned. Command 90 to start.
      </Container>
    );
  }
  return (
    <Container p={10}>
      {statusBar}
      <ErrorBoundary fallback={<p>⚠️Something went wrong</p>}>
        {execution && eRenderData && (
          <SingleExecution execution={execution} eRenderData={eRenderData} />
        )}
      </ErrorBoundary>
    </Container>
  );
}
