import { Logger } from '@/logger';
import { IPrinter } from '@/store/interfaces/printer';
import { LabelPrintDetails, LabelPrinter } from '@/utils/LabelPrinter/LabelPrinter';

const logger = new Logger('Printer');

export function createPrinter(): IPrinter {
  const printerStateChangeListeners: (() => void)[] = [];

  const handlePrinterStateChange = () => {
    printerStateChangeListeners.forEach((cb) => cb());
  };

  const printer = new LabelPrinter({ onPrinterChangeState: handlePrinterStateChange });

  printer.init().catch((error) => {
    logger.error('Printer initialization failed', error);
  });

  return {
    print: async (details: LabelPrintDetails) => {
      logger.debug('Printing label');
      try {
        await printer.print(details);
        return true;
      } catch (error) {
        logger.error('Failed to print label', error);
        return false;
      }
    },
    onPrinterStateChanged: (callback: () => void) => {
      printerStateChangeListeners.push(callback);
      return {
        unsubscribe: () => {
          printerStateChangeListeners.filter((cb) => cb !== callback);
        },
      };
    },
    checkLabelSizeCompatibility: (labelSizes: number[]) => {
      const printerLabelSize = printer.status?.media?.width;
      return labelSizes.includes(printerLabelSize);
    },
    getStatus: () => ({
      initialized: printer.isPrinterReady,
      available: printer.isPrinterReady, // TODO: fix this
      media: printer.status.media,
    }),
  };
}
