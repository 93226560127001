import { BrotherQL } from '@/utils/BrotherQL/BrotherQL';
import { BarcodeType, BQL } from '@/utils/BrotherQL/types';
import { retryRunOnError } from '@/utils/fns';
import { LabelDraw } from '@/utils/LabelPrinter/LabelDraw';
import { log } from '@/utils/log';

type LabelPrinterOptions = {
  onPrinterChangeState?: (newState: boolean) => void;
  autoInit?: boolean;
};
export class LabelPrinter {
  static ERRORS = { NO_PRINTER: 'Printer is offline' };

  usbDevice: USBDevice | null = null;
  printer = new BrotherQL();
  labelDraw = new LabelDraw();

  status = {} as BQL.Response;
  public isInitActive = Promise.resolve(false);
  public isPrinterReady = false;
  private readonly onPrinterChangeState: NonNullable<LabelPrinterOptions['onPrinterChangeState']> =
    () => {};

  constructor(options: LabelPrinterOptions = {}) {
    const { onPrinterChangeState, autoInit } = options;
    if (onPrinterChangeState) {
      this.onPrinterChangeState = onPrinterChangeState;
    }

    if (autoInit) {
      this.initSilently().then((res) => log.warn('LabelPrinter::init ->', res));
    }

    this.addUsbEventListener();
  }

  private addUsbEventListener() {
    this.removeUsbEventListener();

    navigator.usb.addEventListener('connect', this.initSilently);
    navigator.usb.addEventListener('disconnect', this.initSilently);
  }

  public removeUsbEventListener() {
    navigator.usb.removeEventListener('connect', this.initSilently);
    navigator.usb.removeEventListener('disconnect', this.initSilently);
  }

  private initSilently = async () => this.init().catch((error) => error);
  public init = async () => {
    await this.isInitActive;

    const initResult = this.getUsbDevice().then(async () => {
      await this.startPrinter();
      this.labelDraw.setSize(this.getSize());

      if (!this.isPrinterReady) {
        log.warn('LabelPrinter::init -> Printer was offline, now is online');
        this.onPrinterChangeState(true);
      }

      this.isPrinterReady = true;
      return true;
    });

    this.isInitActive = initResult.catch(() => {
      if (this.isPrinterReady) {
        log.warn('LabelPrinter::init -> Printer was online, now is offline');
        this.onPrinterChangeState(false);
      }

      this.isPrinterReady = false;
      return false;
    });

    return initResult;
  };

  public async getUsbDevice() {
    return navigator.usb.getDevices().then((devices) => {
      const printers = devices.filter(
        (device) => device.vendorId === BQL.VendorID && device.productId === BQL.ProductID
      );

      if (printers.length < 1) {
        if (this.usbDevice) {
          try {
            this.usbDevice.close();
          } catch (e) {
            /* empty */
          }
        }
        this.usbDevice = null;
        throw new Error(LabelPrinter.ERRORS.NO_PRINTER);
      }

      [this.usbDevice] = printers;
    });
  }

  public async startPrinter() {
    if (!this.usbDevice) {
      throw new Error(LabelPrinter.ERRORS.NO_PRINTER);
    }

    this.status = await this.printer.start(this.usbDevice);
  }

  public async print(details: LabelPrintDetails) {
    if (!this.usbDevice) {
      const printerIsOk = await retryRunOnError(this.init, { retries: 2 });
      if (!printerIsOk || !this.usbDevice) {
        throw new Error(LabelPrinter.ERRORS.NO_PRINTER);
      }
    }

    const ctx = details.ctx ?? (await this.labelDraw.draw(details));
    const imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
    const rasterLines = await this.printer.convertImageToRasterLines(
      new Uint8Array(imageData.data.buffer),
      ctx.canvas.width
    );

    // return true;
    this.status = await this.printer.print(rasterLines);
  }

  private getSize(): [number, number, number] {
    const labelSize = this.status.media.width;
    if (!labelSize) {
      return [0, 0, 0];
    }

    const labelInfo = BQL.Labels[labelSize];

    return labelInfo
      ? [
          labelInfo.printWidth + labelInfo.rightMargin,
          labelInfo.printWidth + labelInfo.rightMargin,
          labelInfo.rightMargin,
        ]
      : [0, 0, 0];
  }
}

export type LabelPrintDetails = {
  labelText: string;
  barcodeValue: string;
  barcodeType: BarcodeType;
  ctx?: CanvasRenderingContext2D;
};

// const labelPrinter = new LabelPrinter();
// //isi face perms check si starting printer cant mai devreme
// await labelPrinter.init();
//
// //oricand in app
// await labelPrinter.print({ labelText: 'QWERTY', barcodeValue: 'sn:QWERTY', barcodeType: 'qrcode' });
