import { useSingleExecutionStore } from '../store';

export const useController = () => useSingleExecutionStore((state) => state.controller);

export const useStatus = () => useSingleExecutionStore((state) => state.status);

// Custom hook for accessing the input stage state
export const useInputStage = () =>
  useSingleExecutionStore((state) => state.controller?.stages.input);

// Custom hook for accessing the output stage state
export const useOutputStage = () =>
  useSingleExecutionStore((state) => state.controller?.stages.output);

export const useExecutionCompleted = () =>
  useSingleExecutionStore((state) => state.controller?.completed);
