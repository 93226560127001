import { MantineColor } from '@mantine/core';
import { ExecutionRenderData } from '@/pages/debug/NewExe/types';
import { executionStateColor } from '@/pages/debug/NewExe/ui/colors';
import { StageCard } from '@/pages/debug/NewExe/ui/StageCard';
import { StageCardItem } from '@/pages/debug/NewExe/ui/StageCardItem';
import { ExecutionState } from '@/utils/api/fab.types';

const stage = ExecutionState.CardProg;
export type CardProgProps = {
  cardProg: ExecutionRenderData['cardProg'];
  active: boolean;
  done: boolean;
};

export function CardProg(props: CardProgProps) {
  const { active, done, cardProg } = props;
  // todo: init for every stage?

  let statusBg: MantineColor = 'yellow';
  if (cardProg.liveCardUid) {
    switch (cardProg.liveCardStatus) {
      case 'missing':
        statusBg = 'yellow';
        break;

      case 'programmed':
        statusBg = 'red';
        break;

      case 'unusable':
        statusBg = 'pink';
        break;

      case 'ready':
        statusBg = executionStateColor[stage] ?? 'green';
        break;
    }
  }

  return (
    <StageCard {...{ active, done, stage: ExecutionState.CardProg }}>
      <StageCardItem
        stage={stage}
        statusColor={statusBg}
        title="CARD"
        steps={[
          { title: 'STATUS', value: cardProg.liveCardStatus, color: statusBg },
          { title: 'LIVE UID', value: cardProg.liveCardUid },
          { title: 'UID', value: cardProg.cardUid, status: 'waiting' },
          { title: 'BOX SERIAL', value: cardProg.boxSerialNumber, status: 'waiting' },
          { title: 'NOA ID', value: cardProg.noaId, status: 'waiting' },
          { title: 'CARD ID', value: cardProg.cardId, status: 'waiting' },
        ]}
      />
    </StageCard>
  );
}
