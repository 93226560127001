import {
  Group,
  Loader,
  LoadingOverlay,
  rem,
  SimpleGrid,
  Stack,
  Text,
  ThemeIcon,
} from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import React from 'react';
import { executionStateColor } from '@/pages/debug/NewExe/ui/colors';
import { StageTitle } from '@/pages/debug/NewExe/ui/StageTitle';
import { ExecutionState } from '@/utils/api/fab.types';

export type StageCardProps = React.PropsWithChildren<{
  active?: boolean;
  done?: boolean;
  stage: ExecutionState;
}>;

export function StageCard(props: StageCardProps) {
  const { done, stage, active, children } = props;

  return (
    <Stack
      pos="relative"
      my="md"
      pt="md"
      style={{
        borderTop: '0.6px solid rgb(66, 66, 66)',
      }}
    >
      <LoadingOverlay
        visible={done}
        overlayProps={{ radius: 'sm', blur: 0.7 }}
        loaderProps={{
          children: (
            <Group>
              <ThemeIcon color={executionStateColor[stage] ?? 'green'} size={24} radius="xl">
                <IconCheck style={{ width: rem(16), height: rem(16) }} />
              </ThemeIcon>
              <Text fw={900} size="lg">
                Stage Completed!
              </Text>
            </Group>
          ),
        }}
      />

      <Group>
        <StageTitle {...{ stage }} />
        {active && <Loader size="xs" type="oval" display="none" />}
      </Group>

      <SimpleGrid mx="xs" cols={3}>
        {children}
      </SimpleGrid>
    </Stack>
  );
}
