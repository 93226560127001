export function uniq<T>(arr: T[]): T[] {
  return [...new Set(arr)];
}

export function isString(variable: unknown): variable is string {
  return typeof variable === 'string';
}

export function ensureNumber(variable?: string | number | undefined | null): number {
  if (Number.isFinite(variable)) {
    return variable as number;
  }

  if (!variable) {
    return -1; //todo: bad idea
  }

  return isString(variable) ? parseInt(variable, 10) : variable;
}

export async function wait(ms = 300): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function dedupe<T>(array: T[], byKeys: Array<keyof T> = ['id' as keyof T]): T[] {
  return array.filter(
    (o1, index, array2) =>
      array2.findIndex((o2) => byKeys.every((key) => o2[key] === o1[key])) === index
  );
}

export function pick<T, K extends keyof T>(object: T, keys: K[]): Pick<T, K> {
  return keys.reduce(
    (obj: Pick<T, K>, key: K) => {
      if (object && Object.hasOwn(object, key)) {
        obj[key] = object[key];
      }
      return obj;
    },
    {} as Pick<T, K>
  );
}

type RetryRunOnErrorOptions = {
  retries?: number;
  waitBeforeTryMs?: number;
  incrementEachTryWithMs?: number;
};
export async function retryRunOnError<T>(
  fn: () => Promise<T>,
  options?: RetryRunOnErrorOptions
): Promise<T> {
  const { retries = 2, waitBeforeTryMs = 100, incrementEachTryWithMs = 200 } = options ?? {};

  try {
    return await fn();
  } catch (error) {
    if (retries <= 0) {
      throw error;
    }

    if (waitBeforeTryMs > 0) {
      await wait(waitBeforeTryMs);
    }

    return retryRunOnError(fn, {
      ...options,
      retries: retries - 1,
      waitBeforeTryMs: waitBeforeTryMs + incrementEachTryWithMs,
    });
  }
}

export function isStringWithLength(variable: unknown): variable is string {
  return isString(variable) && variable.length > 0;
}
