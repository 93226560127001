export function bytesToHex(hash: Iterable<number>) {
  const hashArray = Array.from(hash);

  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}

export function hexToArrayOfBytes(hex: string) {
  return (hex.match(/[\da-f]{2}/gi) ?? []).map((h) => parseInt(h, 16));
}

export function hexToBytes(hex: string) {
  return new Uint8Array(hexToArrayOfBytes(hex));
}

export function hexToBytes32(hex: string) {
  return new Uint32Array(hexToArrayOfBytes(hex));
}

export function toHexLittleEndian(data: number) {
  const v = new DataView(new ArrayBuffer(32));
  v.setUint16(0, data);
  return v.getUint32(0, true).toString(16);
}

export function isValidHex(hex: string) {
  return /^[0-9a-fA-F]+$/.test(hex);
}

export function getDaysSinceEpoch(): number {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const now = Date.now();
  return Math.floor(now / millisecondsPerDay);
}
