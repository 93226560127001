import { ExecutionState, WorkOrderKind } from '@/utils/api/fab.types';
import { Logger } from '@/logger';
import { StageConfig } from '@/store/interfaces/stages';
import { IStage } from '@/store/interfaces/stages/generic';

const logger = new Logger('StageMap');

type StageKeys<T extends WorkOrderKind> = keyof StageConfig[T];

type StageOrderConfig = {
  [K in WorkOrderKind]: ReadonlyArray<StageKeys<K>>;
};

// Add the stage order here
const stageOrderConfig = {
  [WorkOrderKind.Default]: ['input', 'output'],
  [WorkOrderKind.CardProg]: ['input', 'cardprog', 'output'],
  [WorkOrderKind.Qa]: ['input', 'qa', 'output'],
} as const satisfies StageOrderConfig;

type InferStageOrder<T extends WorkOrderKind> = (typeof stageOrderConfig)[T][number];

function getStageOrder<T extends WorkOrderKind>(
  workOrderKind: T
): ReadonlyArray<InferStageOrder<T>> {
  const order = stageOrderConfig[workOrderKind];
  if (!order) {
    logger.error('Unsupported WorkOrderKind', workOrderKind);
    throw new Error(`Unsupported WorkOrderKind: ${workOrderKind}`);
  }
  return order as ReadonlyArray<InferStageOrder<T>>;
}

export function getNextStage<T extends WorkOrderKind>(
  currentStageType: ExecutionState,
  stages: StageConfig[T],
  workOrderKind: T
): IStage | null {
  const stageOrder = getStageOrder(workOrderKind);
  const currentIndex = stageOrder.findIndex(
    (key) => (stages[key as keyof StageConfig[T]] as IStage).type === currentStageType
  );

  if (currentIndex === -1 || currentIndex === stageOrder.length - 1) {
    return null;
  }

  const nextKey = stageOrder[currentIndex + 1] as keyof StageConfig[T];
  return stages[nextKey] as IStage;
}

export function getPrevStage<T extends WorkOrderKind>(
  currentStage: IStage,
  stages: StageConfig[T],
  workOrderKind: T
): IStage | null {
  const stageOrder = getStageOrder(workOrderKind);
  const currentIndex = stageOrder.findIndex(
    (key) => stages[key as keyof StageConfig[T]] === currentStage
  );

  if (currentIndex === -1 || currentIndex === 0) {
    return null;
  }

  const prevKey = stageOrder[currentIndex - 1] as keyof StageConfig[T];
  return stages[prevKey] as IStage;
}

export function getStage(stageType: ExecutionState, states: StageConfig[WorkOrderKind]): IStage {
  switch (stageType) {
    case ExecutionState.Input:
      return states.input;
    case ExecutionState.Output:
      return states.output;
    case ExecutionState.CardProg:
      return (states as StageConfig[WorkOrderKind.CardProg]).cardprog;
    case ExecutionState.Qa:
      return (states as StageConfig[WorkOrderKind.Qa]).qa;
    default:
      return states.input;
  }
}
