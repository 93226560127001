import { SerialDeviceInfo } from '@/utils/WebSerial/SerialDevice';

export const CardProgWebSerialConfig: SerialDeviceInfo = {
  // chrome://device-log/
  vendorId: 0x0403,
  productId: 0x6010,
  options: {
    baudRate: 115200,
  },

  customReadableTransformer: (readable) =>
    readable.pipeThrough(new TransformStream(new CardProgTransformer())),
};

class CardProgTransformer implements Transformer<string, string> {
  chunks: string;

  constructor() {
    this.chunks = '';
  }

  transform(chunk: string, controller: TransformStreamDefaultController<string>): void {
    this.chunks += chunk;
    if (!this.chunks.endsWith('cardprog> ')) {
      return;
    }

    controller.enqueue(this.chunks.replace(/\r\ncardprog> $/, ''));
    this.chunks = '';
  }

  flush(controller: TransformStreamDefaultController<string>): void {
    controller.enqueue(this.chunks);
  }
}
