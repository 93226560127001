import { Container, Text } from '@mantine/core';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { fabApi } from '@/config';
import { SingleExecution } from '@/pages/debug/NewExe/SingleExecution';
import { isStringWithLength } from '@/utils/fns';
import { StorageKeys, useFabLocalStorage, useFabSessionStorage } from '@/utils/hooks';
import { useCommand, useNamedCommand } from '@/utils/KeyInput/NumpadCommand';

//todo: cleanup; refactor

export function NewExecution() {
  const [autoAssign, setAutoAssign] = useFabSessionStorage(StorageKeys.AutoAssign, false);
  const [workbenchIdNum] = useFabLocalStorage(StorageKeys.WorkbenchId, '');
  const navigate = useNavigate();
  const workbenchId = workbenchIdNum.toString();
  const assignNew = () => {
    if (!assign.isPending) {
      assign.mutateAsync(workbenchId).then(() => setIdle(false));
    }
  };

  useNamedCommand('StartAutoAssign', () => setAutoAssign(true));
  useNamedCommand('StopAutoAssign', () => setAutoAssign(false));
  useNamedCommand('StartTask', () => {
    if (idle) {
      assignNew();
    }
  });
  useNamedCommand('FullPageRefresh', () => window.location.reload());
  useCommand('990', 'Go HOME', () => navigate('/'));

  const query = useQuery({
    queryKey: fabApi.workbenches.executions.key.concat(workbenchId),
    queryFn: () => fabApi.workbenches.executions.byId(workbenchId),
    refetchOnWindowFocus: true,
    enabled: isStringWithLength(workbenchId),
    staleTime: 1,
  });

  const assign = useMutation({
    mutationFn: fabApi.workbenches.executions.assign,
    mutationKey: fabApi.workbenches.executions.key.concat(workbenchId),
    onSuccess: () => {
      query.refetch();
    },
  });

  const [idle, setIdle] = useState(!query.data || !query.data[0]);

  useEffect(() => {
    const isIdle = !query.data || !query.data[0];
    if (isIdle !== idle) {
      setIdle(isIdle);
    }
  }, [query]);

  useEffect(() => {
    if (idle && !query.isLoading && autoAssign && query.data?.length === 0) {
      assignNew();
    }
  }, [autoAssign, idle, query.isLoading]);

  const onFinished = () => {
    query.refetch();
  };
  const statusBar = (
    <Text bg="gray" p="5px 15px" mb="sm">
      autoAssign: {autoAssign ? 'yes' : 'no'}, idle: {idle ? 'yes' : 'no'}
    </Text>
  );

  if (idle) {
    if (autoAssign) {
      return (
        <>
          {statusBar}
          Loading next task...
        </>
      );
    }
    return (
      <Container p={10}>
        {statusBar}
        No execution assigned. Command 90 to start.
      </Container>
    );
  }

  if (query.data) {
    if (query.data[0]) {
      return (
        <Container p={10}>
          {statusBar}
          <ErrorBoundary fallback={<p>⚠️Something went wrong</p>}>
            <SingleExecution execution={query.data[0]} onFinished={onFinished}></SingleExecution>
          </ErrorBoundary>
        </Container>
      );
    }
    setIdle(true);
  } else {
    return <>something's fucked up</>;
  }
}
