import { Loader } from '@mantine/core';
import { FullyCenter } from '@/components/FullyCenter/index';

export function FullyCenterLoader() {
  return (
    <FullyCenter>
      <Loader size="xl" />
    </FullyCenter>
  );
}
