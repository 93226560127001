import { Logger } from '@/logger';
import { ExecutionRecipeConfig } from '@/pages/debug/NewExe/types';
import { IState } from '@/store/interfaces';
import { IQaDataLoader, IQaStage } from '@/store/interfaces/stages/qa';
import { ITester } from '@/store/interfaces/tester';
import { createErrorHandler } from '@/store/utils/error-handler';
import { ExecutionState, WorkbenchExecution } from '@/utils/api/fab.types';

const logger = new Logger('QAStage');

interface IQAStageParams {
  executionData: WorkbenchExecution;
  config: ExecutionRecipeConfig;
  loader: IQaDataLoader;
  state: IState<IQaStage>;
  io: {
    tester: ITester;
  };
  onCompletion: () => void;
}

export const createQaStage = async (args: IQAStageParams): Promise<IQaStage> => {
  logger.addMetadata({ executionId: args.executionData.id });

  const { state, io } = args;
  const errorHandler = createErrorHandler(state, logger);
  const startTest = async () => {
    errorHandler.removeError('qa');

    state.set((newState) => {
      newState.status = 'running';
    });

    try {
      const deviceId = 'mock-device-id'; //TODO: get device id from somewhere
      const result = await io.tester.test(deviceId);
      logger.info('QA Stage test result', result);
      state.set((newState) => {
        if (result.passed) {
          newState.status = 'success';
        } else {
          newState.status = 'failed';
        }

        newState.completed = true;
      });
      args.onCompletion();
    } catch (error) {
      logger.error('QA Stage test error', error);
      state.set((newState) => {
        newState.status = 'error';
        errorHandler.addError('qa', 'QA test failed');
      });
    }
  };

  return {
    type: ExecutionState.Qa,
    status: 'idle',
    data: {
      version: 1,
      executionId: 1,
    },
    errors: {},
    completed: false,
    getPayload: () => ({
      name: ExecutionState.Qa,
    }),
    onEnter: () => {
      logger.info('QA Stage onEnter', args);
      startTest();
    },
    onExit: () => {
      logger.info('QA Stage onExit');
    },
    onPostExit: () => {
      logger.info('QA Stage onPostExit');
    },
  };
};
