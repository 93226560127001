const logConfig = {
  // If true, errors will be logged to the console no matter of what
  letErrorsPass: true,
};

export const log = new Proxy(console, {
  get(...args) {
    const isLogEnabled =
      process.env.NODE_ENV === 'development' || localStorage.getItem('log_enabled') === 'true';
    const isErrorAndShouldPass = logConfig.letErrorsPass && args[1] === 'error';

    if (isLogEnabled || isErrorAndShouldPass) {
      return Reflect.get(...args);
    }

    return _nope;
  },
});

function _nope() {}
