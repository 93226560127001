
// when app is initialized, init the cardprog manager
// this will keep the SerialPort active when it is requested (until a specified timeout is reached)
// ready to be re-used.

import { CardProg } from '@/utils/CardProg/CardProg';
import { CardProgWebSerialConfig } from '@/utils/CardProg/config';
import { SerialDevice } from '@/utils/WebSerial/SerialDevice';

const RELEASE_TIMEOUT_MS    = 5 * 60_000;


// TODO: IDLE TIMEOUT PORT RELEASE (i.e. no active wait() for the last x minutes)

export class CardProgManager {
  serialDevice: SerialDevice | null = null;
  serialStateUnsubscribe: () => any = () => {};
  cardProg: CardProg | null = null;
  cardProgPromises: Array<{resolve: (v: CardProg) => any, reject: (err: any) => any}> = [];

  
  private waitForCardProg(): Promise<CardProg> {
    const {promise, resolve, reject} = Promise.withResolvers<CardProg>();
    this.cardProgPromises.push( { resolve, reject });
    return promise;
  }

  private setCardProg(val: CardProg) {
    this.cardProg = val;
    if(val) {
      for(const { resolve } of this.cardProgPromises) {
        resolve(val);
      }
      this.cardProgPromises = [];
    }
  }

  private clearCardProg(reason: any) {
    this.cardProg = null;
    for (const { reject } of this.cardProgPromises) {
      reject(reason);
    }
    this.cardProgPromises = [];
  }

  // gets a cached version.
  async getCardProg(): Promise<CardProg> {
    if(this.cardProg) {
      return this.cardProg;
    }

    if(!this.serialDevice) {
      this.serialDevice = new SerialDevice({info: CardProgWebSerialConfig});
      this.serialStateUnsubscribe = this.serialDevice.onStateChange(state => {
        if(!this.serialDevice) {
          console.error('orphan subscribe?')
        }

        if (!state.isLoading) {
          if (state.portState === 'open') {
            if(!this.cardProg) {
              if(!this.serialDevice) {
                console.error('state open but no serialDevice is set');
                return;
              }

              console.log('init cardProg');
              this.setCardProg(new CardProg(this.serialDevice));
            }
          }
  
          if (state.portState === 'close') {
            this.serialDevice = null;
            this.serialStateUnsubscribe();
            this.serialStateUnsubscribe = () => {};
            this.clearCardProg('serial port closed');
          }  
        }
      });
    }

    return this.waitForCardProg();
  }
}