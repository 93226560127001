import { createBrowserRouter } from 'react-router-dom';
import { CardsPage } from '@/pages/debug/Cards/Cards.page';
import { authAction, authLoader, AuthPage } from '@/pages/Auth/AuthPage';
import { configAction, ConfigPage } from '@/pages/Config/Config.page';
import { CardProgPage } from '@/pages/debug/CardProg/CardProg.page';
import { LaserFullPage } from '@/pages/debug/Laser/Laser-full.page';
import { LaserPage } from '@/pages/debug/Laser/Laser.page';
import { NewExecution } from './pages/debug/NewExe/NewExe.page';
import { Execution } from './pages/debug/Execution/Execution.page';
import ParallelExecutionPage from './pages/debug/ParallelExecution/ParallelExecution.page';

export const router = createBrowserRouter([
  {
    path: '/',
    lazy: () => import('@/pages/root/Layout'),
    children: [
      {
        index: true,
        lazy: () => import('@/pages/Workbenches/RedirectToWorkbench'),
      },
      {
        path: 'workbenches/:wbId',
        lazy: () => import('@/pages/Workbenches/Layout'),
        children: [
          {
            index: true,
            //todo: cleanup old version after some time
            lazy: () => import('@/pages/Workbenches/Execution'),
            // Component: NewExecution,
          },
          {
            path: 'new',
            lazy: () => import('@/pages/Workbenches/Execution/New'),
          },
        ],
      },
      {
        path: '*',
        lazy: () => import('@/pages/Workbenches/RedirectToWorkbench'),
      },
    ],
  },
  {
    path: '/config',
    action: configAction,
    Component: ConfigPage,
  },
  {
    path: '/auth',
    action: authAction,
    loader: authLoader,
    Component: AuthPage,
  },
  {
    path: '/debug/cardprog',
    Component: CardProgPage,
  },
  {
    path: '/debug/laser',
    Component: LaserPage,
  },
  {
    path: '/debug/laser-full',
    Component: LaserFullPage,
  },
  {
    path: '/debug/newexe',
    Component: NewExecution,
  },
  {
    path: '/debug/execution',
    Component: Execution,
  },
  {
    path: '/debug/parallel-execution',
    Component: ParallelExecutionPage,
  },
  {
    path: '/debug/cards',
    Component: CardsPage,
  },
  {
    path: '/cardfix',
    lazy: () => import('@/pages/CardFix/CardFix.page').then((mod) => ({ Component: mod.CardFix })),
  },
  {
    path: '/lfs',
    lazy: () => import('@/pages/LFS/LFS.page').then((mod) => ({ Component: mod.LFSPage })),
  },
  {
    path: '/print-sn-lmb/:partName',
    lazy: () =>
      import('@/pages/SNPrint/LMBPrint.page').then((mod) => ({ Component: mod.LMBPrintPage })),
  },
  {
    path: '/print-sn-lb/:partName',
    lazy: () =>
      import('@/pages/SNPrint/LBPrint.page').then((mod) => ({ Component: mod.LBPrintPage })),
  },
  {
    path: '/motor-harness',
    lazy: () =>
      import('@/pages/MotorHarness/MotorHarness.page').then((mod) => ({
        Component: mod.MotorHarnessPage,
      })),
  },
  {
    path: '/debug/artefacts',
    lazy: () => import('@/pages/debug/Artefacts/Artefacts.page'),
  },
]);
