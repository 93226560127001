import { UserAuthorization } from '@/utils/api/fab.types';
import { fabReadFromLocalStorage, useFabLocalStorage } from '@/utils/hooks';

const AUTH_KEY = 'user';

export function getAuthHeader(): HeadersInit {
  const wbKey = localStorage.getItem('wb.key');
  const userAuthorization = fabReadFromLocalStorage<UserAuthorization>(AUTH_KEY);
  const token = userAuthorization?.token;

  return {
    ...(wbKey && { 'x-workbench-key': wbKey }),
    ...(token && { Authorization: `Bearer ${token}` }),
  };
}

export function setAuthUser(user: UserAuthorization) {
  localStorage.setItem(AUTH_KEY, JSON.stringify(user));
}

export function useSetAuthUser() {
  const [, setUser] = useFabLocalStorage<UserAuthorization>(AUTH_KEY);

  return setUser;
}

export function clearAuthUser() {
  localStorage.removeItem(AUTH_KEY);
  sessionStorage.clear();
}
