import { Code, CodeProps } from '@mantine/core';
import { executionStateColor } from '@/pages/debug/NewExe/ui/colors';
import { ExecutionState } from '@/utils/api/fab.types';

type StageTitleProps = {
  stage: ExecutionState;
};
export function StageTitle(props: StageTitleProps) {
  const { stage } = props;

  const colors: Pick<CodeProps, 'color' | 'c'> = {
    c: 'white',
    color: executionStateColor[stage],
  };

  return (
    <Code {...colors} fw="bold" p="5px">
      {stage}
    </Code>
  );
}
