import { Alert, Center, Loader, Stack, Text, Title } from '@mantine/core';
import { IconInfoCircle, IconLock } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { ActionFunctionArgs, redirect, useFetcher, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useNamedCommand } from '@/utils/KeyInput/NumpadCommand';
import { fabApi } from '@/config';
import { CreateUserAuthorization } from '@/utils/api/fab.types';
import { useSetAuthUser } from '@/utils/auth';
import { authState } from '@/utils/auth/state';
import { ensureNumber } from '@/utils/fns';
import { useKeydownEvents } from '@/utils/KeyInput/KeydownEvents';

export function AuthPage() {
  const location = useLocation();
  const fetcher = useFetcher();
  const params = new URLSearchParams(location.search);
  const from = params.get('from') || '/';
  const setUser = useSetAuthUser();
  useNamedCommand('FullPageRefresh', () => window.location.reload());

  const [userAuth, setUserAuth] = useState<CreateUserAuthorization>();

  const authorizationMutation = useMutation({
    mutationFn: fabApi.user.authorization,
  });

  useKeydownEvents({
    onBarcodeRead: (code) => {
      console.debug(code);
      const scannedUserAuth = code.split(':');
      if (scannedUserAuth.length !== 3 || scannedUserAuth[0] !== 'auth') {
        return;
      }

      const [, id, key] = scannedUserAuth;
      setUserAuth({ id: ensureNumber(id), key });
    },
  });

  useEffect(() => {
    if (!userAuth || !userAuth.id || !userAuth.key) {
      return;
    }

    authorizationMutation.mutateAsync(userAuth).then((response) => {
      setUser(response);
      const { token: _, ...user } = response;
      Sentry.setUser(user);
      fetcher.submit({ configured: 'true', redirectTo: from }, { method: 'patch' });
    });
  }, [userAuth]);

  return (
    <Center h="90vh" maw="50vw" mx="auto">
      <Stack>
        <Title>
          <IconLock /> <Trans>Log in</Trans>
        </Title>
        <Text c="dimmed" size="lg">
          <Trans>Please scan your badge to continue.</Trans>
        </Text>
        {authorizationMutation.isPending ? (
          <Loader
            size="xl"
            style={{ visibility: !authorizationMutation.error ? 'visible' : 'hidden' }}
          />
        ) : null}
        {authorizationMutation.error ? (
          <Alert variant="light" color="red" title="Submit error" my="md" icon={<IconInfoCircle />}>
            {authorizationMutation.error.message}
          </Alert>
        ) : null}
      </Stack>
    </Center>
  );
}

export async function authLoader() {
  if (await authState.isAuthenticated()) {
    console.info('[auth.loader] ok, redirecting to "/"');
    return redirect('/');
  }

  return null;
}

export async function authAction(args: ActionFunctionArgs) {
  const { request } = args;
  const formData = await request.formData();
  const configured = (formData.get('configured') as string | null) === 'true';

  try {
    await authState.setAuthenticated(configured);
  } catch (error) {
    return {
      error: 'Error configuring config',
    };
  }

  console.info('[auth.action] all done, redirecting to "/"');
  redirect('/');
  return null;
}
