import {
  Code,
  Group,
  List,
  Loader,
  MantineColor,
  rem,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core';
import { IconCheck, IconCircleDashed, IconX } from '@tabler/icons-react';
import React from 'react';
import { NumPadKbd } from '@/components/NumPadKbd/NumPadKbd';
import { executionStateColor } from '@/pages/debug/NewExe/ui/colors';
import { ExecutionState } from '@/utils/api/fab.types';
import { isStringWithLength } from '@/utils/fns';

export type StepsListProps = {
  steps: Array<{
    title: string;
    value: string | undefined | null;
    status?: 'ok' | 'fail' | 'partial' | 'waiting';
    hidden?: boolean;
    color?: MantineColor;
    r?: (ren: {
      Text: (text: string) => JSX.Element;
      Value: (text?: string | null | undefined) => JSX.Element;
    }) => React.ReactNode;
  }>;
  stage: ExecutionState;
  title?: string;
  cmd?: string;
};

export function StepsList(props: StepsListProps) {
  const { steps, cmd, title, stage } = props;

  return (
    <>
      <Group wrap="nowrap" align="flex-start">
        {cmd && <NumPadKbd keys={cmd} />}
        <Title order={4} mb="md">
          {title}
        </Title>
      </Group>

      <List spacing="xs" size="sm">
        {steps
          .filter((x) => x.hidden !== true)
          .map((step, index) => {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const { status, r, value, title, color } = step;
            const valIsOk = isStringWithLength(value);
            let Icon = valIsOk ? IconCheck : IconX;
            let iconColor = valIsOk ? executionStateColor[stage] ?? 'green' : 'pink.6';

            if (status) {
              if (status === 'ok') {
                Icon = IconCheck;
                iconColor = executionStateColor[stage] ?? 'green';
              } else if (status === 'fail') {
                Icon = IconX;
                iconColor = 'pink';
              } else if (status === 'partial') {
                Icon = IconCircleDashed;
                iconColor = 'orange';
              }
            }

            if (color) {
              iconColor = color;
              Icon = IconCircleDashed;
            }

            const ren = {
              Text: (text: string) => <Text size="sm">{text.toUpperCase()}:</Text>,
              Value: (text?: typeof value) => (
                <Code style={{ fontSize: '14px' }} c={color ?? iconColor} fw={900}>
                  {text?.toUpperCase() ??
                    (status === 'waiting' ? (
                      <Loader color={color ?? iconColor} size="xs" type="dots" />
                    ) : (
                      'N/A'
                    ))}
                </Code>
              ),
            };

            return (
              <List.Item
                key={index}
                icon={
                  <ThemeIcon color={iconColor} size={rem(20)} radius="xl">
                    <Icon style={{ width: rem(14), height: rem(14) }} />
                  </ThemeIcon>
                }
              >
                <Group align="center" wrap="nowrap" gap="xs">
                  {r ? (
                    r(ren)
                  ) : (
                    <>
                      {ren.Text(title)}
                      {ren.Value(value)}
                    </>
                  )}
                </Group>
              </List.Item>
            );
          })}
      </List>
    </>
  );
}
