import { ExecutionRenderData } from '@/pages/debug/NewExe/types';
import { executionStateColor } from '@/pages/debug/NewExe/ui/colors';
import { StageCard } from '@/pages/debug/NewExe/ui/StageCard';
import { StageCardItem } from '@/pages/debug/NewExe/ui/StageCardItem';
import { ExecutionState } from '@/utils/api/fab.types';

export type OutputProps = {
  outputs: ExecutionRenderData['outputs'];
  active: boolean;
  done: boolean;
};

export function Outputs(props: OutputProps) {
  const { outputs, active, done } = props;

  return (
    <StageCard {...{ active, done, stage: ExecutionState.Output }}>
      {outputs.map((output) => {
        const { serialized, identifier } = output;
        let bg = active ? 'red' : 'gray';
        if (output.valid) {
          bg = executionStateColor[ExecutionState.Output] ?? 'green';
        }

        return (
          <StageCardItem
            key={output.cmd}
            cmd={output.cmd}
            focused={output.focused}
            stage={ExecutionState.Output}
            statusColor={bg}
            title={output.partName}
            steps={[
              { title: 'qty', value: output.qty },
              serialized
                ? { title: 'Serial Number', value: identifier.serial_number, status: 'waiting' }
                : { title: 'Lot Number', value: identifier.lot_number, status: 'waiting' },
            ]}
          />
        );
      })}
    </StageCard>
  );
}
