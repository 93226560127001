/* eslint-disable max-classes-per-file */
import { useEffect } from 'react';
import { Config } from '@/config';
import { getGlobal } from '@/utils/global';
import { Logger } from '@/logger';

export const EV_BARCODE = 'barcode';

const logger = new Logger('BarcodeInput');

export class BarcodeEvent extends Event {
  barcode: string;

  constructor(barcode: string) {
    super(EV_BARCODE);
    this.barcode = barcode;
  }
}

export class BarcodeInput extends EventTarget {
  msg: string = '';
  lastInput: number = 0;
  id: number;
  constructor() {
    super();
    console.log('BarcodeInput::constructor()');
    this.id = Date.now();
    const cb = this.onKeyDown.bind(this);
    // since this is a global class, we don't care about unregistering the event.
    window.addEventListener('keydown', cb);
  }
  onKeyDown(event: KeyboardEvent) {
    const { key, code } = event;
    const isEnter = code === 'Enter';

    if (code.startsWith('Numpad')) {
      return;
    }

    // if timeout is reached, reset.
    const now = Date.now();
    if (this.lastInput) {
      const delta = now - this.lastInput;
      if (delta > Config.barcodeInterCharacterTimeoutMs) {
        this.lastInput = 0;
        this.msg = '';
      }
    }

    if (isEnter) {
      logger.debug('BarcodeInput::onKeyDown() - Enter key pressed, dispatching event', {
        msg: this.msg,
      });
      if (this.msg.length) {
        this.dispatchEvent(new BarcodeEvent(this.msg));
      }

      this.msg = '';
      this.lastInput = 0;
      return;
    }

    if (key.length > 1) {
      return;
    }

    this.msg += key;
    this.lastInput = now;
  }
}

export function useBarcode(callback: (code: string) => any) {
  const barcodeInput = getGlobal('barcodeInput');
  useEffect(() => {
    const wrappedCallback = (ev: BarcodeEvent) => {
      callback(ev.barcode);
    };
    /* @ts-ignore */
    barcodeInput.addEventListener(EV_BARCODE, wrappedCallback);
    return () => {
      /* @ts-ignore */
      barcodeInput.removeEventListener(EV_BARCODE, wrappedCallback);
    };
  }, [barcodeInput]);
}
