/**
 * Generates grid keys based on the number of columns and rows.
 * @param columns The number of columns in the grid.
 * @param rows The number of rows in the grid.
 * @returns An array of grid keys in the format "columnId::rowId".
 */
export function createGridKeys(columns: number, rows: number): string[] {
  const keys: string[] = [];
  for (let col = 1; col <= columns; col += 1) {
    for (let row = 1; row <= rows; row += 1) {
      keys.push(`${col}::${row}`);
    }
  }
  return keys;
}

/**
 * Extracts the column and row indices from a grid key.
 * @param key The grid key in the format "columnId::rowId".
 * @returns An object containing the column and row indices.
 * @throws Error if the key format is invalid.
 */
export function getIndicesFromKey(key: string): { column: number; row: number } {
  const parts = key.split('::');
  if (parts.length !== 2) {
    throw new Error('Invalid grid key format. Expected "columnId::rowId".');
  }

  const column = parseInt(parts[0], 10);
  const row = parseInt(parts[1], 10);

  if (Number.isNaN(column) || Number.isNaN(row)) {
    throw new Error('Invalid grid key format. Column and row must be numbers.');
  }

  return { column, row };
}

/**
 * Creates a grid key from column and row indices.
 * @param column The column index.
 * @param row The row index.
 * @returns A grid key in the format "columnId::rowId".
 */
export function createKeyFromIndices(column: number, row: number): string {
  return `${column}::${row}`;
}
