import { Alert, Button, Code, Fieldset, Group, Spoiler, TextInput } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { IconInfoCircle } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { isString } from '@/utils/fns';
import { fabApi } from '@/config';

const stationId = localStorage.getItem('wb.id') || '';
const stationKey = localStorage.getItem('wb.key');

export function StationName() {
  const [id, setId] = useInputState(stationId);

  useEffect(() => {
    localStorage.setItem('wb.id', id);
  }, [id]);

  const mutation = useMutation({
    mutationFn: fabApi.workbenches.createKey,
  });

  useEffect(() => {
    if (!mutation.data) {
      return;
    }

    localStorage.setItem('wb.key', mutation.data.key);
  }, [mutation.data]);
  const keyExists = isString(mutation.data?.key) || isString(stationKey);

  return (
    <Fieldset legend="Station Identifiers" mb="xl">
      <Group>
        <TextInput
          label="Workbench ID"
          value={id}
          // disabled={stationId.length > 0}
          onChange={setId}
        />
        <Button
          mt="lg"
          loading={mutation.isPending}
          onClick={() => mutation.mutate(id)}
          disabled={keyExists}
        >
          {keyExists ? 'Key was created' : 'Create Key'}
        </Button>
      </Group>
      {mutation.error ? (
        <Alert variant="light" color="red" title="Submit error" my="md" icon={<IconInfoCircle />}>
          {mutation.error.message}
        </Alert>
      ) : null}

      {keyExists ? <WorkbenchConfig id={id} /> : null}
    </Fieldset>
  );
}

export function WorkbenchConfig({ id }: { id: string }) {
  const query = useQuery({
    queryKey: fabApi.workbenches.key.concat(id),
    queryFn: () => fabApi.workbenches.byId(id),
    refetchOnWindowFocus: false,
    // skip: !isString(id),
  });

  if (query.isPending) {
    return <span>Loading...</span>;
  }

  if (query.isError) {
    return <span>Error: {query.error.message}</span>;
  }

  const config = query.data;

  return (
    <Spoiler maxHeight={100} showLabel="Show more" hideLabel="Hide">
      <Code block my="lg">
        {JSON.stringify(config, null, 2)}
      </Code>
    </Spoiler>
  );
}
