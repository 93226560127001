import { CardProgTypes } from '@/utils/CardProg/CardProg';
import { Sigs } from '@/utils/CardProg/Sigs';
import { bytesToHex, hexToBytes, hexToBytes32, isValidHex } from '@/utils/CardProg/utils';
import { isString } from '@/utils/fns';

let sigs: Sigs;

export class SigCard {
  card: SigCardData;
  sigs: Sigs;
  cardProgSigDataResponse: CardProgTypes.SetSigDataResponse | null = null;

  constructor(sigCardObj: SigCardData, options: SigCardOptions = {}) {
    this.card = sigCardObj;
    this.cardProgSigDataResponse = options.cardProgSigDataResponse ?? null;

    if (!sigs) {
      sigs = new Sigs();
    }

    this.sigs = sigs;
  }

  static fromCardProgResponse(response: CardProgTypes.SetSigDataResponse) {
    return new SigCard(
      {
        cardId: hexToBytes(response.card_id),
        flags: hexToBytes32(response.flags),
        sigDate: hexToBytes32(response.sig_date),
        cardUid: hexToBytes(response.card_uid),
      },
      { cardProgSigDataResponse: response }
    );
  }

  public getSignature() {
    const { cardId, flags, sigDate, cardUid } = this.card;
    const sig = [cardId, flags, sigDate, cardUid].reduce(
      (prev, cur) => prev.concat(Array.from(cur)),
      [] as number[]
    );

    return Uint8Array.from(sig);
  }

  public checkSignatures(signatures?: Parameters<typeof this.sigs.checkSignatures>[1]) {
    if (!signatures) {
      if (!this.cardProgSigDataResponse) {
        throw new Error('No signatures provided');
      }

      signatures = {
        hmac: this.cardProgSigDataResponse.hmac,
        ecdsa: this.cardProgSigDataResponse.ecdsa_der,
      };
    }

    return this.sigs.checkSignatures(this, signatures);
  }

  public async checkBlocks(blocks?: Partial<CardProgTypes.CardBlocks>) {
    if (!blocks) {
      if (!this.cardProgSigDataResponse) {
        throw new Error('No blocks provided');
      }

      blocks = this.cardProgSigDataResponse;
    }

    await SigCard.checkBlocksIntegrity(blocks);

    const { flags = '' } = this.cardProgSigDataResponse ?? {};
    const { cardId, sigDate } = this.card;
    const sig = this.getSignature();
    const calculatedHmac = await this.sigs.getHmacSignature(bytesToHex(sig));
    const calculatedHmacHex = bytesToHex(calculatedHmac).padStart(64, '0');
    const calculatedEc = this.sigs.getEcSignature(await this.sigs.getSha256(sig));
    const ecdsaR = calculatedEc.r.toString('hex').padStart(64, '0');
    const ecdsaS = calculatedEc.s.toString('hex').padStart(64, '0');

    const expectedBlockKeys: CardProgTypes.CardBlocks = {
      block04: [bytesToHex(cardId), flags, bytesToHex(sigDate)].join(''),
      block05: calculatedHmacHex.slice(0, 32),
      block06: calculatedHmacHex.slice(32),
      block08: ecdsaR.slice(0, 32),
      block09: ecdsaR.slice(32),
      block10: ecdsaS.slice(0, 32),
      block12: ecdsaS.slice(32),
    };

    const allBlockMatchExpectedValues = BLOCK_KEYS.every(
      (blockKey) => blocks[blockKey]?.toLowerCase() === expectedBlockKeys[blockKey].toLowerCase()
    );

    if (!allBlockMatchExpectedValues) {
      console.warn({ expectedBlockKeys, blocks }, { ecdsaS, ecdsaR, calculatedHmacHex });
      throw new Error('Invalid blocks; mismatched excepted data');
    }

    return true;
  }

  public static async checkBlocksIntegrity(blocks: Partial<CardProgTypes.CardBlocks>) {
    const allBlocksSeemsValid = BLOCK_KEYS.every(
      (block) =>
        blocks[block] &&
        isString(blocks[block]) &&
        blocks[block]?.length === 32 &&
        isValidHex(blocks[block] ?? '')
    );

    if (!allBlocksSeemsValid) {
      throw new Error('Invalid blocks; malformed or missing data');
    }

    return true;
  }
}

const BLOCK_KEYS: (keyof CardProgTypes.CardBlocks)[] = [
  'block04',
  'block05',
  'block06',
  'block08',
  'block09',
  'block10',
  'block12',
];

interface SigCardData {
  cardId: Uint8Array;
  flags: Uint32Array;
  sigDate: Uint32Array;
  cardUid: Uint8Array;
}

type SigCardOptions = {
  cardProgSigDataResponse?: CardProgTypes.SetSigDataResponse | null;
};
