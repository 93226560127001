import { BQL } from '@/utils/BrotherQL/types';

export class BrotherQL {
  public debug = true;
  public device: USBDevice;
  private inputEndpointNumber = 0;
  private outputEndpointNumber = 0;
  private _previousStatus?: BQL.Response;

  constructor() {
    //@ts-expect-error
    this.device = null;
  }

  public async start(device: USBDevice) {
    if (!device) {
      throw new Error('No printer specified!');
    }

    if (device.productId !== BQL.ProductID) {
      throw new Error('bad product id?!');
    }

    this.device = device;
    this.log('device', device);

    await device.open();
    this.log('device opened', device);

    await device.selectConfiguration(1);
    this.log('device selectConfiguration(1)', device);

    await device.claimInterface(0);
    this.log('device claimInterface', device.configuration!.interfaces?.[0]?.claimed);
    const usbInterface = device.configuration!.interfaces[0];

    if (!usbInterface) {
      throw new Error('No usb interface were found');
    }

    usbInterface.alternate.endpoints.forEach((endpoint) => {
      if (endpoint.direction === 'in') {
        this.inputEndpointNumber = endpoint.endpointNumber;
      } else if (endpoint.direction === 'out') {
        this.outputEndpointNumber = endpoint.endpointNumber;
      }
    });

    if (!this.inputEndpointNumber || !this.outputEndpointNumber) {
      throw new Error('No input/output endpoints were found');
    }

    await this.transfer(BQL.Commands.clear);
    await this.transfer(BQL.Commands.initialize);

    return this.getStatus();
  }

  __getStatusWaitCount = 0;
  public async getStatus(): Promise<BQL.Response> {
    await this.transfer(BQL.Commands.status);
    const receiveResult = await this.receive();

    if (receiveResult.status !== 'ok' || !receiveResult.data) {
      return Promise.reject(new Error('Bad status received!'));
    }

    const resultArray = new Uint8Array(receiveResult.data.buffer);
    this.log('receiveResult', { receiveResult, resultArray });
    if (resultArray.length === 0) {
      this.log(
        'No status response data but status is ok, waiting (%o) for response...',
        this.__getStatusWaitCount
      );

      this.__getStatusWaitCount += 1;
      if (this.__getStatusWaitCount > 3) {
        this.__getStatusWaitCount = 0;
        if (this._previousStatus) {
          console.warn('BrotherQL:lib -> No status response.data but status is ok');
          return this._previousStatus;
        }

        throw new Error('No status response.data but status is ok');
      }
      await wait(100);
      return this.getStatus().then((r) => {
        this.__getStatusWaitCount = 0;
        return r;
      });
    }

    const status = this.parseStatusResponse(resultArray);
    this._previousStatus = status;
    this.log('status', status);
    return status;
  }

  private parseStatusResponse(response: Uint8Array): BQL.Response {
    if (response.length !== 32 || response[0] !== 0x80) {
      console.error(response, [response.length, response[0] !== 0x80]);
      throw new Error('Received an invalid response');
    }

    const error: string[] = [];
    switch (response[8]) {
      case 0x01:
        error.push('No media when printing');
        break;
      case 0x02:
        error.push('End of media');
        break;
      case 0x04:
        error.push('Tape cutter jam');
        break;
    }

    switch (response[9]) {
      case 0x04:
        error.push('Transmission error');
        break;
      case 0x40:
        error.push('Cannot feed');
        break;
      case 0x80:
        error.push('System error');
    }

    const width = response[10];

    let mediaType;
    switch (response[11]) {
      case 0x0a:
        mediaType = BQL.MediaType.ContinuousTape;
        break;
      case 0x0b:
        mediaType = BQL.MediaType.DieCutLabels;
        break;
      case 0x00:
      default:
        mediaType = BQL.MediaType.None;
        break;
    }

    const length = response[17];

    let statusType = BQL.StatusType.ReplyToStatusRequest;
    switch (response[18]) {
      case 0x01:
        statusType = BQL.StatusType.PrintingCompleted;
        break;
      case 0x02:
        statusType = BQL.StatusType.ErrorOccurred;
        break;
      case 0x05:
        statusType = BQL.StatusType.Notification;
        break;
      case 0x06:
        statusType = BQL.StatusType.PhaseChange;
        break;
    }

    return {
      statusType,
      error,
      media: {
        type: mediaType,
        width,
        length,
      },
    };
  }

  async receive() {
    return this.device.transferIn(this.inputEndpointNumber, 32);
  }

  public async transfer(data: BufferSource) {
    this.log('sending:', data);
    return this.device.transferOut(this.outputEndpointNumber, data);
  }

  public async convertImageToRasterLines(
    imageData: Uint8Array,
    imageWidth: number
  ): Promise<Uint8Array[]> {
    const bytesPerRow = imageWidth * 4;
    const renderLineCount = imageData.length / bytesPerRow;

    const rasterLines: Uint8Array[] = [];
    for (let column = 0; column < imageWidth; column += 1) {
      const rasterLine = new Uint8Array(90);
      let byteIndex = 1;
      let bitIndex = 3;

      for (let row = 0; row < renderLineCount; row += 1, bitIndex -= 1) {
        if (bitIndex < 0) {
          byteIndex += 1;
          bitIndex += 8;
        }
        const alphaIndex = row * bytesPerRow + column * 4 + 3;
        rasterLine[byteIndex] |= (imageData[alphaIndex] > 0xff / 2 ? 1 : 0) << bitIndex;
      }

      rasterLines.push(rasterLine);
    }

    return rasterLines;
  }

  async print(rasterLines: Uint8Array[]) {
    const status = await this.getStatus();

    await this.transfer(BQL.Commands.mode);

    const mediaCommand = BQL.Commands.media(status.media.width, status.media.length);
    const patchMediaCommandWithLineCount = new DataView(mediaCommand.buffer);
    patchMediaCommandWithLineCount.setUint32(7, rasterLines.length, true);

    await this.transfer(mediaCommand);

    await this.transfer(BQL.Commands.autoCut);
    await this.transfer(BQL.Commands.cutAtEnd);

    const mediaInfo = BQL.Labels[status.media.width.toString()];
    if (!mediaInfo) {
      throw new Error(`Unknown media: ${status.media.width}`);
    }

    await this.transfer(BQL.Commands.setMargins);

    for (const line of rasterLines) {
      await this.transfer(BQL.Commands.raster(line));
    }

    await this.transfer(BQL.Commands.print);

    return this.getStatus();
  }

  log(...attr: any) {
    if (this.debug) {
      console.debug(...attr);
    }
  }
}

function wait(ms = 100) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
