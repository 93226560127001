import React, { useEffect } from 'react';
import { Container } from '@mantine/core';
import ParallelExecutionGrid from './ParallelExecutionGrid';
import { useParallelExecution } from '@/store/states/parallel-executions/hooks/useParallelExecutions';
import { createGridKeys } from './grid';

const ParallelExecutionPage: React.FC = () => {
    const columns = 4; // Number of columns in your grid
    const rows = 3; // Number of rows in your grid
    const gridKeys = createGridKeys(columns, rows);
    console.log(gridKeys);
    const { startExecution, focusExecution } = useParallelExecution({ gridKeys });

    useEffect(() => {
        startExecution('1::1');
        focusExecution('1::1');
        setTimeout(() => {
            startExecution('2::2');
            focusExecution('2::2');
        }, 5000);
    }, [startExecution]);

    return (
        <Container fluid>
            <ParallelExecutionGrid />
        </Container>
    );
};

export default ParallelExecutionPage;
