import { Code, Title } from '@mantine/core';
import { CardProgCount } from '@/pages/debug/NewExe/ui/CardProgCount';
import { CardProg } from '@/pages/debug/NewExe/ui/CardProg';
import { ExecutionErrors } from '@/pages/debug/NewExe/ui/Errors';
import { Inputs } from '@/pages/debug/NewExe/ui/Inputs';
import { Outputs } from '@/pages/debug/NewExe/ui/Outputs';
import { ExecutionTitle } from '@/pages/debug/NewExe/ui/Title';
import { isStagePassed } from '@/pages/debug/NewExe/util';
import { ExecutionState, WorkbenchExecution } from '@/utils/api/fab.types';
import { useExecution } from './useExecution';

export type SingleExecutionProps = {
  execution: WorkbenchExecution;
  onFinished: () => void;
};

export function SingleExecution({ execution, onFinished }: SingleExecutionProps) {
  const [eRenderData, io] = useExecution(execution);
  const { errors, executionCount, loading, recipeName, activeStage, inputs, cardProg, outputs } =
    eRenderData;

  if (!loading && activeStage === ExecutionState.Finished) {
    onFinished();
  }

  // todo: status indicator
  if (loading) {
    return <Title>Loading</Title>;
  }

  const getCommonProps = (stage: ExecutionState) => ({
    io,
    done: isStagePassed(execution.work_order_kind, stage, activeStage),
    active: activeStage === stage,
  });

  //todo: move to Title component?
  const titleAppend = executionCount ? (
    <>
      {eRenderData.hasCardProg ? <CardProgCount cardProg={cardProg} /> : null}
      <Code block ml="auto" color="violet" fw={900}>
        {executionCount.total === Infinity
          ? executionCount.current
          : `${executionCount.current} / ${executionCount.total}`}
      </Code>
    </>
  ) : null;

  return (
    <>
      <ExecutionTitle {...{ recipeName, activeStage, append: titleAppend }} />

      <ExecutionErrors {...{ errors }} />

      <Inputs
        {...{
          inputs,
          ...getCommonProps(ExecutionState.Input),
        }}
      />

      {eRenderData.hasCardProg ? (
        <CardProg {...{ cardProg, ...getCommonProps(ExecutionState.CardProg) }} />
      ) : null}

      <Outputs {...{ outputs, ...getCommonProps(ExecutionState.Output) }} />
    </>
  );
}
