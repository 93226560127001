import { IStageController, IState } from '../../interfaces';

/**
 * Function that abstracts the state access. Allows for code reuse on stores with different state structures (ex: different object nesting).
 * @param setGlobalState
 * @param getGlobalState
 * @returns
 */
export const createControllerStateProxy = (
  setGlobalState: (updater: (draft: { controller: IStageController | null }) => void) => void,
  getGlobalState: () => { controller: IStageController | null }
): IState<IStageController> => ({
  get: () => {
    const { controller } = getGlobalState();
    if (!controller) {
      throw new Error('Controller not initialized');
    }
    return controller;
  },
  set: (updater) =>
    setGlobalState((draft) => {
      if (!draft.controller) {
        throw new Error('Controller not initialized');
      }
      updater(draft.controller);
    }),
});

export function createEncapsulatedState<T, V>(
  state: IState<T>,
  selector: (state: T) => V
): IState<V> {
  return {
    get: () => selector(state.get()),
    set: (updater: (currentState: V) => void) => {
      state.set((controllerState) => {
        const currentState = selector(controllerState);
        return updater(currentState);
      });
    },
  };
}
