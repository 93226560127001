import { QueryClient } from '@tanstack/react-query';
import { fabApi, isDevEnv } from '@/config';
import { BarcodeStorage } from '@/pages/debug/NewExe/BarcodeStorage';
import type { ExecutionRenderStore } from '@/pages/debug/NewExe/ExecutionRender';
import { type ExecutionRecipeConfig } from '@/pages/debug/NewExe/types';
import { jsonDeserialize, jsonSerialize } from '@/pages/debug/NewExe/util';
import {
  CardProgAdvanceBody,
  ExecutionState,
  FabUploadRequest,
  PartIdentifier,
  SerialNumber,
  UploadContentType,
  WorkbenchExecution,
} from '@/utils/api/fab.types';
import { FabExposed, ProbeCardResult } from '@/utils/fab-wb/types';
import { isStringWithLength, pick, wait } from '@/utils/fns';
import { log } from '@/utils/log';

const DATA_VERSION = 1;
type Data = {
  version: number;
  executionId: number;
  cardId: string;
  programmed: boolean;
  cardUid?: string;
  liveCardUid?: string;
  liveCardStatus: 'missing' | 'ready' | 'programmed' | 'unusable';
  probeCardResult: ProbeCardResult;
  noaId?: string;
  boxSerialNumber?: string;
  log?: Awaited<ReturnType<FabExposed['cardprog']['getLog']>>;
};

const BOX_PART_ID = 420;
// const NOA_CARD_PART_ID = 413; //?
const NOA_CARD_PART_ID = 412;
const STORAGE_KEY = 'exe.cardprog';
type ErrorKeys = 'cardProg' | string;

type ExecutionCardProgProps = {
  execution: WorkbenchExecution;
  storeData?: Data | null;
  config: ExecutionRecipeConfig;
  store: ExecutionRenderStore;
};
type ExecutionCardProgLoadProps = {
  execution: WorkbenchExecution;
  q: QueryClient;
  config: ExecutionRecipeConfig;
  store: ExecutionRenderStore;
};

export class ExecutionCardProg {
  store: ExecutionRenderStore;
  data: Data;
  config: ExecutionRecipeConfig;
  barcodeStorage: BarcodeStorage;
  liveProbeRunning: boolean = false;
  dispatchUpdate: () => any;
  errors: Partial<Record<ErrorKeys, string>> = {};

  // TODO: store cardprog log & upload it on output
  constructor(props: ExecutionCardProgProps) {
    const { execution, config, storeData, store } = props;
    log.debug('ExecutionCardProg::constructor()', this);

    this.store = store;
    this.barcodeStorage = store.e.barcodeStorage!;
    this.config = config;
    this.dispatchUpdate = store.dispatchUpdate;

    this.data = {
      version: DATA_VERSION,
      executionId: execution.id,
      liveCardUid: undefined,
      liveCardStatus: 'missing',
      probeCardResult: { status: 'missing', keyGrp: 'a' },
      programmed: false,
      // log: '',

      cardId: '',
      ...('card_id' in execution.state_data && {
        cardId: execution.state_data.card_id,
        cardUid: execution.state_data.card_uid || undefined,
        noaId: execution.state_data.card_noa_id || undefined,
        boxSerialNumber: execution.state_data.box_serial_number || undefined,
      }),
      ...storeData,
    };

    this.loadBoxSerial();

    this._probCheckInterval = setInterval(this.probeCheck, 50);
    if (!this.data.cardUid) {
      this.startLiveProbe();
    }
  }

  async onWorkbenchExecutionUpdate(execution: WorkbenchExecution, _q: QueryClient) {
    if (!('card_id' in execution.state_data)) {
      return false;
    }
    let updated = false;
    const { card_id, card_uid, card_noa_id, box_serial_number } = execution.state_data;

    if (card_id !== this.data.cardId) {
      updated = true;
      this.data.cardId = card_id;
    }

    if (card_uid && card_uid !== this.data.cardUid) {
      updated = true;
      this.data.cardUid = card_uid;
    }

    if (card_noa_id && card_noa_id !== this.data.noaId) {
      updated = true;
      this.data.noaId = card_noa_id;
    }

    if (box_serial_number && box_serial_number !== this.data.boxSerialNumber) {
      updated = true;
      this.data.boxSerialNumber = box_serial_number;
    }

    return updated;
  }

  error = (key: keyof typeof this.errors, msg: string | null) => {
    if (msg === null) {
      this.errors[key] = undefined;
    } else {
      this.errors[key] = msg;
    }
    this.dispatchUpdate();
  };

  private loadBoxSerial() {
    const serialNumber = this.barcodeStorage.getPersistedBarcode(BOX_PART_ID);
    if (!isStringWithLength(serialNumber) || isStringWithLength(this.data.boxSerialNumber)) {
      return;
    }

    this.data.boxSerialNumber = serialNumber;
  }

  _probCheckInterval: ReturnType<typeof setInterval>;
  lastProbeUpdate = 0;
  isRunningCardProbe = false;
  probeCheck = () => {
    if (!this.liveProbeRunning || this.isRunningCardProbe) {
      return;
    }

    const now = Date.now();
    if (now - this.lastProbeUpdate < 300) {
      return;
    }

    this.lastProbeUpdate = now;
    this.isRunningCardProbe = true;
    this.probeCard().finally(() => {
      this.lastProbeUpdate = Date.now();
      this.isRunningCardProbe = false;
    });
  };

  private async probeCard() {
    //check if we run on browser and have no access to cardProg
    if (isDevEnv && typeof fab === 'undefined') {
      this.data.liveCardUid = undefined;
      this.data.liveCardStatus = 'missing';

      return undefined;
    }

    if (this.data.programmed) {
      log.debug('CardProg::probeCard -> we have already programmed a card for this task, exiting');
      return this.stopLiveProbe();
    }

    this.stopLiveProbe();
    return fab.cardprog
      .probeCard({ fast: true })
      .then(async (result) => {
        log.info('cardProg %o', result);
        const { cardUid, error, status } = result;
        this.error('cardProg', error ?? null);

        if (localStorage.getItem('features:reset_noa_id_on_card_switch') === 'true') {
          //Reset the noaId if the card was switched
          if (isStringWithLength(cardUid) && this.data.liveCardUid !== cardUid && this.data.noaId) {
            this.data.noaId = undefined;
          }
        } else {
          localStorage.setItem('features:reset_noa_id_on_card_switch', 'false');
        }

        this.data.liveCardUid = cardUid;
        this.data.liveCardStatus = status;

        if (status === 'ready' && cardUid && this.data.cardUid !== cardUid) {
          if (isStringWithLength(this.data.cardId)) {
            this.data.programmed = await fab.cardprog
              .programCard(this.data.cardId, cardUid)
              .then((programResult) => {
                if (!programResult.ok) {
                  // eslint-disable-next-line no-debugger
                  debugger;

                  console.error('fab.cardprog.programCard -> error', {
                    params: [this.data.cardId, cardUid],
                    programResult,
                  });
                }

                if (programResult.error) {
                  this.error('cardProg.program', programResult.error);
                } else {
                  this.error('cardProg.program', null);
                }

                return programResult.ok;
              })
              .catch((e) => {
                this.error('cardProg', e.message);
                return false;
              });
            // this.data.cardUid = cardUid;
          }

          if (this.data.programmed) {
            this.data.cardUid = cardUid;
            this.saveData();

            /*
            todo: should we save all programmed cards by this wb? cardUid+noaId
             */

            try {
              this.data.log = await fab.cardprog.getLog();
              await this.putLogIntoOutputArtefacts();
              await fab.cardprog.clearLog().catch(() => {});
            } catch (e) {
              log.error('CardProg:probeCard:artefacts..', e);
            }

            try {
              await this.store.e.tryToAdvance();
            } catch (e) {
              log.error('CardProg:probeCard:tryToAdvance..', e);
            }
          } else {
            this.startLiveProbe();
          }
        } else {
          this.startLiveProbe();
        }

        this.store.dispatchUpdate();
      })
      .catch(async (error) => {
        const shouldRestartCardProbe = `${error?.message ?? error}`.includes(
          'No card reader found'
        );
        log.error('catch:fab.cardprog:', error.message, shouldRestartCardProbe);
        this.error('cardProg', error.message);
        if (shouldRestartCardProbe) {
          this.stopLiveProbe();
          await fab.cardprog.restart();
          await wait(2000).then(this.startLiveProbe);
        } else {
          this.startLiveProbe();
        }
      });
  }

  private startLiveProbe = () => {
    log.info('CardProg:startLiveProbe');
    this.liveProbeRunning = true;
  };

  private stopLiveProbe = () => {
    log.info('CardProg:stopLiveProbe');
    this.liveProbeRunning = false;
  };

  async putLogIntoOutputArtefacts() {
    if (!this.data.log || this.data.log.byteLength === 0) {
      return;
    }

    const uploadData: FabUploadRequest = {
      body: new Uint8Array(this.data.log),
      contentType: UploadContentType.text,
      filename: 'file.txt',
    };

    log.time('CardProg::putArtefactIdIntoOutput');
    await this.store.q
      .fetchQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: fabApi.artefacts.key,
        queryFn: () => fabApi.artefacts.upload(uploadData),
        staleTime: 1,
      })
      .then((artefact) => {
        if (!artefact.id) {
          return;
        }

        this.store.e.output?.data.artefacts.push(artefact.id);
      })
      .catch((e) => {
        log.error('CardProg::putArtefactIdIntoOutput -> Failed %o', e);
      })
      .finally(() => {
        log.timeEnd('CardProg::putArtefactIdIntoOutput');
      });
  }

  private saveData() {
    if (!this.data) {
      return;
    }

    const dataToSave = pick(this.data, [
      'version',
      'executionId',
      'cardUid',
      'cardId',
      'boxSerialNumber',
      'noaId',
      'programmed',
    ]);
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(dataToSave, jsonSerialize));
  }

  private static loadDataFromStorage(executionId: number): Data | null {
    const serializedData = sessionStorage.getItem(STORAGE_KEY);
    if (!serializedData) {
      return null;
    }

    try {
      const data = JSON.parse(serializedData, jsonDeserialize) as Data;
      if (data.executionId !== executionId || data.version !== DATA_VERSION) {
        return null;
      }
      return data;
    } catch (_) {
      return null;
    }
  }

  static async load(props: ExecutionCardProgLoadProps): Promise<ExecutionCardProg> {
    const { execution, config, store } = props;
    const storeData = ExecutionCardProg.loadDataFromStorage(execution.id);

    return new ExecutionCardProg({
      execution,
      storeData,
      store,
      config,
    });
  }

  onScanPart(
    serialNumbers: SerialNumber[],
    identifier: PartIdentifier,
    hint?: 'box' | 'card'
  ): boolean {
    log.info(
      'ExecutionCardProg::onScanPart [serialNumbers=%o][identifier=%o]',
      serialNumbers,
      identifier,
      hint
    );

    const isCardHint = hint === 'card';
    const boxPart = serialNumbers.find((sn) => sn.part_id === BOX_PART_ID);
    if (!isCardHint && boxPart && isStringWithLength(identifier.serial_number)) {
      this.data.boxSerialNumber = identifier.serial_number;

      const executions =
        this.config.input?.[BOX_PART_ID]?.lotNumberPersistence ||
        this.config.input?.lotNumberPersistence ||
        20;
      this.barcodeStorage.persistBarcode(BOX_PART_ID, identifier.serial_number, executions);

      return true;
    }

    //check if it is NoaID and copy it, do not consume
    const noaCard = serialNumbers.find((sn) => sn.part_id === NOA_CARD_PART_ID);
    if (noaCard && isStringWithLength(identifier.serial_number)) {
      log.info(
        'ExecutionCardProg::onScanPart -> catch NoaID[serialNumbers=%o]',
        identifier.serial_number
      );

      if (this.data.programmed) {
        log.error(
          'ExecutionCardProg::onScanPart -> we have already programmed a card[noa_id=%] but did not saved (advance) it..',
          this.data.noaId,
          JSON.stringify(
            pick(this.data, [
              'executionId',
              'cardUid',
              'cardId',
              'boxSerialNumber',
              'noaId',
              'programmed',
            ])
          )
        );

        if (this.data.noaId && this.data.noaId !== identifier.serial_number) {
          log.error('ExecutionCardProg::onScanPart -> noa_id mismatch, resetting cardProg..', {
            noaId: this.data.noaId,
            identifier: identifier.serial_number,
            oldUid: this.data.cardUid,
          });

          this.data.programmed = false;
          this.data.cardUid = undefined;
          this.startLiveProbe();
        }
      }

      this.data.noaId = identifier.serial_number;
    }

    return false;
  }

  getAdvanceBody(): CardProgAdvanceBody {
    if (!this.data.boxSerialNumber || !this.data.cardUid || !this.data.noaId) {
      throw new Error('missing ids on cardProg advanceBody()');
    }

    return {
      name: ExecutionState.CardProg,
      box_serial_number: this.data.boxSerialNumber,
      card_uid: this.data.cardUid,
      card_noa_id: this.data.noaId,
    };
  }

  isReady() {
    const { boxSerialNumber, cardId, cardUid, noaId } = this.data;
    // const isCardReady = this.data.liveCardStatus === 'ready';
    const isCardProgrammed = this.data.programmed;

    return [boxSerialNumber, cardId, cardUid, noaId].every(isStringWithLength) && isCardProgrammed;
  }

  afterExit() {
    const { boxSerialNumber } = this.data;
    if (boxSerialNumber) {
      this.barcodeStorage.decrementBarcodes([boxSerialNumber]);
    }

    this.stopLiveProbe();
  }

  async onEnter(_execution: WorkbenchExecution, _q: QueryClient) {
    log.info('CardProg::onEnter');
    if (this.isReady()) {
      log.info('CardProg::onEnter -> seems ok to advance, trying...');
      await this.store.e.tryToAdvance();
    }
  }
}
