import { UserAuthorization } from '@/utils/api/fab.types';
import { isString } from '@/utils/fns';
import { fabReadFromLocalStorage } from '@/utils/hooks';

export const authState = {
  async isAuthenticated() {
    const value = fabReadFromLocalStorage<UserAuthorization>('user');

    return value && isString(value.token);
  },

  async setAuthenticated(value: boolean) {
    //todo: set user token here?
    return Promise.resolve(value);
  },
};
