import { type ExecutionConfig } from '@/pages/debug/NewExe/types';
import { ExecutionState, WorkOrderKind } from '@/utils/api/fab.types';
import { BarcodeType } from '@/utils/BrotherQL/types';

const CARD_BOX_SERIALIZE = 23;
const PROGRAM_NFC_CARD = 22;
const SERIALIZE_BATTERY_CELL = 24;

export const executionConfigs: ExecutionConfig = {
  default: {
    input: {
      lotNumberPersistence: 20,
    },
    output: {
      allowEarlyScan: false,
      serialNumberAction: 'generate',
      scanRequired: true,
    },
  },

  [CARD_BOX_SERIALIZE]: {
    output: {
      serialNumberAction: 'generate',
      print: {
        validLabelSizes: [29],
        codeType: BarcodeType.QRCODE,
        labelPrefix: 'sn',
      },
    },

    finished: {
      restartCounterOn: Infinity,
    },
  },

  [PROGRAM_NFC_CARD]: {
    input: {
      lotNumberPersistence: 200,
    },
    output: {
      allowEarlyScan: true,
      serialNumberAction: 'useExisting',
    },
    finished: {
      restartCounterOn: 200,
    },
  },

  [SERIALIZE_BATTERY_CELL]: {
    input: {
      lotNumberPersistence: 130,
    },
    output: {
      serialNumberAction: 'generate',
      print: {
        validLabelSizes: [12],
        codeType: BarcodeType.DATAMATRIX,
        labelPrefix: 'sn',
      },
    },
    finished: {
      restartCounterOn: 130,
    },
  },
};

export const StageSuccession = {
  [WorkOrderKind.Default]: [ExecutionState.Input, ExecutionState.Output, ExecutionState.Finished],
  [WorkOrderKind.CardProg]: [
    ExecutionState.Input,
    ExecutionState.CardProg,
    ExecutionState.Output,
    ExecutionState.Finished,
  ],
  [WorkOrderKind.Qa]: [ExecutionState.Input, ExecutionState.Qa, ExecutionState.Output],
};
