import { Button, Checkbox, Container, Group, Stack } from '@mantine/core';
import { useEffect, useState } from 'react';
import { BarcodeType } from '@/utils/BrotherQL/types';
import { LabelDraw } from '@/utils/LabelPrinter/LabelDraw';

import s from './s.png';
import { canvasToGcode3, loadImgAndFixCanvas } from '@/pages/debug/Laser/utils';

// const ppmm = (px: number) => px / 3.7795280352161;
const mmpx = (mm: number) => mm * 10;

const config = {
  dpi: 10,
  width: 400,
  height: 415,
};

const def = {
  startX: 6,
  startY: 177.5,

  imgSizeX: 50,
  imgSizeY: 50,

  marginX: 11,
  marginY: 19.5,

  padding2: 16,
};

const imgs = [
  [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0],
  [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0],
  [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
  [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 0],
];

const labelDraw = new LabelDraw();
labelDraw.setSize([mmpx(def.imgSizeX), mmpx(def.imgSizeY), 0]);

export function LaserFullPage() {
  let canvas: HTMLCanvasElement;
  let ctx: CanvasRenderingContext2D;
  const [withBg, setWithBg] = useState(true);
  const [withQr, setWithQr] = useState(true);

  useEffect(() => {
    canvas = document.getElementById('canvas') as HTMLCanvasElement;
    ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    canvas.width = mmpx(config.width);
    canvas.height = mmpx(config.height);
    // @ts-ignore
    window.ctx = ctx;
  });

  async function drawQr(x: number, y: number) {
    if (withQr) {
      const id = Math.random().toString(36).slice(2, 10).toUpperCase();
      await labelDraw.draw({
        labelText: '',
        barcodeValue: `sn:${id}`,
        barcodeType: BarcodeType.DATAMATRIX,
        fixedFontSize: true,
        // shrinkImage: false,
        rightMargin: 0,
        topMargin: mmpx(1),
      });
      ctx.drawImage(labelDraw.mainCanvas, mmpx(x), mmpx(y));
    } else {
      ctx.rect(mmpx(x), mmpx(y), mmpx(def.imgSizeX), mmpx(def.imgSizeY)); // drawing a rectangle the simple way
    }
  }

  const drawOnCanvas = async () => {
    ctx.lineWidth = mmpx(1);

    drawQr(35, 415-65);

    // drawQr(0, 0);
    // for (let y = 0; y < imgs.length; y += 1) {
    //   for (let x = 0; x < imgs[y].length; x += 1) {
    //     const yy = def.marginY * y + y * def.imgSizeY + def.startY;
    //     const xx = def.marginX * x + x * def.imgSizeX + def.startX;

    //     if((y === 5 || y === 4) && x === 0) {
    //       let xxfinal = xx + (imgs[y][x] === 2 ? def.padding2 : 0); 
    //       console.log(xxfinal, 415 - yy, imgs[y][x]);
    //     }

    //     if(x !== 0 || y !== 5) {
    //       continue;
    //     }

    //     if (imgs[y][x] === 1) {
    //       // await drawQr(def.startPosY + x * def.imgSize + x * def.imgSize + startX, yy);
    //       await drawQr(xx, yy);
    //     }

    //     if (imgs[y][x] === 2) {
    //       await drawQr(xx + def.padding2, yy);
    //     }
    //   }
    // }

    ctx.stroke();
  };

  useEffect(() => {
    drawOnCanvas();
  });

  const genGecode = () => {
    loadImgAndFixCanvas(canvas);
    const code = canvasToGcode3(canvas);
    document.getElementById('gcode')!.innerHTML = code.join('\n');
  }
  useEffect(() => {}, []);

  return (
    <Container mt="lg">
      <Stack>
        <Group>
          <Button onClick={drawOnCanvas}>draw</Button>
          <Checkbox checked={withBg} onChange={(event) => setWithBg(event.currentTarget.checked)} />
          <Checkbox checked={withQr} onChange={(event) => setWithQr(event.currentTarget.checked)} />
          <Button onClick={genGecode}>gen gcode</Button>
        </Group>
        <Group>
          <canvas
            id="canvas"
            style={{
              border: '2px dashed lime',
              background: `white url(${s}) no-repeat contain`,
              width: '100%',
              backgroundColor: '#fff',
              backgroundImage: withBg ? `url(${s})` : undefined,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              // height: `${config.height}mm`,
            }}
          />
          <textarea id="gcode" />
        </Group>
      </Stack>
    </Container>
  );
}

/////
