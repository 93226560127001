import { useCallback, useEffect, useMemo, useState } from 'react';
import { BQL } from '@/utils/BrotherQL/types';

// eslint-disable-next-line import/no-mutable-exports
export let _brotherDevice: USBDevice | null = null;

type BrotherQLPermissionStatus =
  | { error: string }
  | { ok: boolean; serialNumber: string | undefined };

export function useBrotherQLEnsurePermissions() {
  const [status, $setStatus] = useState<BrotherQLPermissionStatus>({
    error: 'Unknown permission status',
  });

  const setStatus = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (status: BrotherQLPermissionStatus, device: USBDevice | null = null) => {
      _brotherDevice = device;
      $setStatus(status);
      return status;
    },
    []
  );

  const requestPermissions = useCallback(
    () =>
      navigator.usb
        .requestDevice({ filters: [{ vendorId: BQL.VendorID }] })
        .then(async (device) => {
          if (device.productId === BQL.ProductID_EditorMode) {
            return setStatus({ error: 'Disable "Editor" mode!' });
          }

          if (device.productId !== BQL.ProductID) {
            return setStatus({ error: 'Unknown printer model!' });
          }

          return setStatus({ ok: true, serialNumber: device.serialNumber }, device);
        })
        .catch((error: Error) => setStatus({ error: error.message })),
    []
  );

  const tryToGetDevice = useCallback(
    () =>
      navigator.usb.getDevices().then((devices) => {
        const printers = devices.filter(
          (device) => device.vendorId === BQL.VendorID && device.productId === BQL.ProductID
        );
        const device = printers[0];

        if (!device) {
          setStatus({ error: 'Devices not found!' });
          throw new Error('Devices not found!');
        }

        if (device.productId === BQL.ProductID_EditorMode) {
          return setStatus({ error: 'Disable "Editor" mode!' });
        }

        if (device.productId !== BQL.ProductID) {
          return setStatus({ error: 'Unknown printer model!' });
        }

        return setStatus({ ok: true, serialNumber: device.serialNumber }, device);
      }),
    []
  );

  const onConnect = useCallback((event: USBConnectionEvent) => {
    setStatus({ ok: true, serialNumber: event.device?.serialNumber }, event.device);
  }, []);

  const onDisconnect = useCallback(() => {
    setStatus({ error: 'Disconnected' });
  }, []);

  useEffect(() => {
    console.log('useBrotherQLEnsurePermissions:init');
    tryToGetDevice().catch(requestPermissions);

    navigator.usb.addEventListener('connect', onConnect);
    navigator.usb.addEventListener('disconnect', onDisconnect);

    return () => {
      navigator.usb.removeEventListener('connect', onConnect);
      navigator.usb.removeEventListener('disconnect', onDisconnect);
    };
  }, []);

  return useMemo(
    () => ({
      status,
      requestPermissions,
      tryToGetDevice,
    }),
    [status]
  );
}

//deprecated
export function useBrotherQLDevice() {
  //todo: check for editor lite mode
  // if product_id() == 0x2049 "You must disable Editor Lite mode before you can print"
  //
  const [brotherDevice, setBrotherDevice] = useState<USBDevice>();

  useEffect(() => {
    navigator.usb.getDevices().then((devices) => {
      console.log(`useBrotherQL: Total devices: ${devices.length}`);
      const printers = devices.filter(
        (device) => device.vendorId === BQL.VendorID && device.productId === BQL.ProductID
      );

      if (printers.length < 1) {
        console.error('no brotherQL devices not found! paninc!');
        //todo: request perms or maybe printer is offline
        //disable Auto Power-Off Time?
        // navigator.usb
        //   .requestDevice({ filters: [{ vendorId: BQL.VendorID }] })
        //   .then(async (device) => {})
        //   .catch((error) => {
        //     console.error(error);
        //   });
        return;
      }

      const device = printers[0];
      console.debug(
        'printers found (%o), using the first one (serial number: %o)',
        devices.length,
        device.serialNumber
      );

      setBrotherDevice(device);
    });
  }, []);

  return brotherDevice;
}

export function useBrotherQLDeviceWithRefresh() {
  const [brotherDevice, setBrotherDevice] = useState<USBDevice>();

  const refreshConnection = useCallback(
    () =>
      navigator.usb.getDevices().then((devices) => {
        const printers = devices.filter(
          (device) => device.vendorId === BQL.VendorID && device.productId === BQL.ProductID
        );

        if (printers.length < 1) {
          //todo: request perms or maybe printer is offline
          return;
        }

        const device = printers[0];
        setBrotherDevice(device);
      }),
    []
  );

  useEffect(() => {
    refreshConnection();
  }, []);

  return useMemo(
    () => ({
      brotherDevice,
      refreshConnection,
    }),
    [brotherDevice]
  );
}
