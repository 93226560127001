import { Button, Container, Group, Stack, Textarea, TextInput } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { useEffect, useRef, useState } from 'react';
import { CardProgWebSerialConfig } from '@/utils/CardProg/config';
import { CardProg, CardProgTypes } from '@/utils/CardProg/CardProg';
import { SigCard } from '@/utils/CardProg/SigCard';
import { getDaysSinceEpoch, toHexLittleEndian } from '@/utils/CardProg/utils';
import { useSerial } from '@/utils/WebSerial/WebSerial';

export function CardProgPage() {
  const serialDevice = useSerial(CardProgWebSerialConfig);
  const [stringValue, setStringValue] = useInputState('');
  const cardProg = useRef<CardProg>();

  // useEffect(() => {
  //   console.debug('>serial.portState', serial.portState);
  //   cardProg.current = new CardProg(serial);
  // }, [serial.portState]);

  useEffect(() => {
    console.debug('mounted config! serial => ', serialDevice, serialDevice?.portState);

    if (!serialDevice) {
      return;
    }

    console.debug('init cardProg', serialDevice, serialDevice.isLoading);
    cardProg.current = new CardProg(serialDevice);
    /*cardProg.current = new CardProg(serial);
    /!*const input = {
      card_id: '11223344 55667788',
      card_uid: '00112233',
      flags: '00000000',
      sig_date: 'AC4D0000',
      hmac: '507620B6 AF1D529D 136C35B0 D83AB8CD 47735AD9 E305050C 05AE9691 2121ED30',
      ecdsa_der:
        '30450220 3E743EA2 4C8334BD 86CBFD32 787230E8 73020705 D5F97D59 564D4967 72C7D771 022100D5 BF1695C0 5D668539 C1CC797D D0D36DCF 35CE19AA ACE5C8F8 9C7E1C96 1CE315',
    };

    const sigCard = SigCard.getCardFromHexInput({
      cardId: input.card_id,
      cardUid: input.card_uid,
      flags: input.flags,
      sigDate: input.sig_date,
    });

    sigCard
      .checkSignatures({ hmac: input.hmac, ecdsa: input.ecdsa_der })
      .then((r) => console.warn('checkSignatures %o', r));*!/

    */
    const r = serialDevice.subscribe((message) => {
      console.debug('serial.subscribe -> message', message);
    });

    return () => {
      r();
    };
  }, [serialDevice]);

  const [log, setLog] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const programCard = async () => {
    if (!serialDevice) {
      setLog('serialDevice not initialized');
      console.error('serialDevice not initialized');
      return;
    }

    setIsLoading(true);
    setLog('');

    cardProg.current = new CardProg(serialDevice);

    if (!cardProg.current) {
      console.error('cardProg not initialized');
      setLog('Error: cardProg not initialized');
      setIsLoading(false);
      return;
    }

    try {
      // await cardStartRegistrationProcess({ cardId: '3344556677889900', serial });
      const cardUid = await cardProg.current.detectCard();
      setLog((prev) => `${prev}\n$cardUid:\n${cardUid}\n`);

      // const cardSig = await retryRunOnError(() => cardProg.current!.readCardSig('a'));
      // setLog((prev) => `${prev}\n$cardSig:\n${JSON.stringify(cardSig, null, '  ')}\n`);
      // await SigCard.checkBlocksIntegrity(cardSig);

      const sigDate = getDaysSinceEpoch();
      const data: CardProgTypes.SigData = {
        cardId: '3344556677889900',
        cardUid,
        sigDate: sigDate.toString(),
      };
      console.debug('>>SigData data', data);
      const setSigData = await cardProg.current.setSigData(data);
      setLog((prev) => `${prev}\n$setSigData:\n${JSON.stringify(setSigData, null, '  ')}\n`);

      const sigCard = SigCard.fromCardProgResponse({
        ...setSigData,
        card_id: data.cardId,
        card_uid: cardUid,
        sig_date: toHexLittleEndian(sigDate).padEnd(8, '0'),
      });

      sigCard.checkSignatures({ hmac: setSigData.hmac, ecdsa: setSigData.ecdsa_der }).then((r) => {
        console.warn('checkSignatures %o', r);
        setLog((prev) => `${prev}\nSignature match = ${JSON.stringify(r, null, '  ')}`);
      });

      const isBlocksOk = await sigCard.checkBlocks();
      setLog((prev) => `${prev}\nBlock match = ${isBlocksOk}`);

      setLog((prev) => `${prev}\nLOG: \n${cardProg.current!.getLog()}\n`);

      // const commitCardSig = await cardProg.current.commitCardSig();
      // setLog((prev) => `${prev}\n$commitCardSig:\n${JSON.stringify(commitCardSig, null, '  ')}\n`);
      // const mifareKeys = await cardProg.current.getMifareKeys();
      // setLog((prev) => `${prev}\n$mifareKeys:\n${mifareKeys}\n`);
      //
      // const getSignKeys = await cardProg.current.getSigKeys();
      // setLog((prev) => `${prev}\n$getSignKeys:\n${JSON.stringify(getSignKeys, null, '  ')}\n`);
      //
      // const cardUid = await cardProg.current.detectCard();
      // setLog((prev) => `${prev}\n$cardUid:\n${cardUid}\n`);
      //
      // const cardSig = await cardProg.current.readCardSig();
      // setLog((prev) => `${prev}\n$cardSig:\n${cardSig}\n`);
      /**
       * scan qr (+ verify it exists in system)
       *
       * detect_card -> until we get UID
       *
       * --- generate card_Id + register card in system
       *
       * set_sig_data (+verify it)
       *
       * commit_card_sig (re-attempt if failed, need to set_sig_data again)
       *
       * protect_card (re-attempt if failed, it will succeed, stop after 5 attempts)
       *
       * read_card_sig (keygrp=a now) - triple-check the data
       *
       * (or whenever we stop after error) - upload report to system detailing card success/fail + serial output
       */
    } catch (e) {
      console.error(e);
      setLog((prev) => `${prev}\nError: ${e}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container mt="lg">
      <Stack>
        <Group>
          <Button
            onClick={() => {
              console.debug('>>>serialDevice', serialDevice);

              serialDevice?.manualConnectToPort().then(
                async (x) => {
                  console.log('connected!');
                  cardProg.current = new CardProg(serialDevice!);

                  const cardUid = await cardProg.current.detectCard();
                  console.debug('>>>>', cardUid);
                },
                (e) => console.log(e)
              );
            }}
            size="xl"
          >
            connect
          </Button>

          <Button
            onClick={async () => {
              const res = await cardProg.current?.detectCard().catch((e) => console.error(e));
              console.log('detectCard', res);
            }}
            size="xl"
          >
            detect_card
          </Button>

          <Button
            onClick={() => {
              serialDevice!.manualDisconnectFromPort().then(console.warn);
            }}
            size="xl"
            color="orange"
          >
            disconnect
          </Button>
          <form
            onSubmit={(e) => {
              e.preventDefault();

              serialDevice!.subscribe((m) => console.log('serial.subscribe', m));

              serialDevice!.write(`${stringValue}\n`).then((r) => {
                console.log('res', r);
              });

              setStringValue('');
            }}
          >
            <TextInput value={stringValue} onChange={setStringValue} size="xl" />
          </form>
        </Group>

        <Group grow>
          <Stack>
            <Button onClick={programCard} color="red" loading={isLoading}>
              do all stuff with card!
            </Button>
            <Textarea value={log} readOnly size="xl" rows={20} />
          </Stack>
        </Group>
      </Stack>
    </Container>
  );
}

/////
