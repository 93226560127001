import { alpha, Container, getThemeColor, MantineColor, useMantineTheme } from '@mantine/core';
import { ExecutionIO } from '@/pages/debug/NewExe/ExecutionIO';
import { useCommand } from '@/utils/KeyInput/NumpadCommand';
import { StepsList, StepsListProps } from '@/pages/debug/NewExe/ui/StepsList';

type StageCardItemProps = {
  statusColor: MantineColor;
  focused?: boolean;
  io?: ExecutionIO;
} & StepsListProps;

export function StageCardItem(props: StageCardItemProps) {
  const { stage, io, focused, cmd, steps, title, statusColor } = props;
  const theme = useMantineTheme();
  const bg = alpha(getThemeColor(statusColor, theme), 0.05);
  if (cmd && io) {
    useCommand(cmd, `Select ${title}`, io.onCommand);
  }
  let borderColor = statusColor;
  if (focused) {
    //todo: use css :focus outline
    borderColor = 'white';
  }

  return (
    <Container
      p={10}
      style={{
        border: `5px solid ${getThemeColor(borderColor, theme)}`,
        borderRadius: '10px',
      }}
      bg={bg}
    >
      <StepsList {...{ stage, cmd, steps, title }} />
    </Container>
  );
}
