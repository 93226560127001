import { Kbd, rem } from '@mantine/core';
import { IconKeyboard } from '@tabler/icons-react';

type NumPadKbdProps = { keys: string | string[] };

export function NumPadKbd(props: NumPadKbdProps) {
  const { keys } = props;

  return (
    <Kbd style={{ textAlign: 'center', fontWeight: '900' }}>
      <IconKeyboard style={{ width: rem(23), height: rem(23) }} />
      {([] as string[]).concat(keys).join('')}
    </Kbd>
  );
}
