import Decimal from 'decimal.js';
import { getRecipeConfig } from '@/pages/debug/NewExe/util';
import { IStageController, IState } from '@/store/interfaces';
import {
  ExecutionDependencies,
  ISingleExecutionDependencies,
} from '@/store/interfaces/dependencies';
import { IInputDataLoader, IInputStageData } from '@/store/interfaces/stages/input';
import { IOutputDataLoader, IOutputStageData } from '../../interfaces/stages/output';
import { ICardProgData, ICardProgDataLoader } from '../../interfaces/stages/card-prog';
import { ISingleExecutionStore } from '../../interfaces/single-execution/store';
import { Logger } from '@/logger';
import { IApi } from '../../interfaces/api';
import { WorkbenchExecution, WorkOrderKind } from '@/utils/api/fab.types';
import { createBarcodeIO } from './barcode';
import { IQaDataLoader } from '@/store/interfaces/stages/qa';
import { lru } from '@/utils/lru';

const logger = new Logger('ExecutionDependencies');

async function getExecutionByWorkbenchId(
  workbenchId: string,
  api: IApi
): Promise<WorkbenchExecution> {
  const [existingExecution] = await api.workbenches.executions.byId(workbenchId);

  if (existingExecution) {
    return existingExecution;
  }

  // TODO: maybe the post should return the execution data
  await api.workbenches.executions.assign(workbenchId);

  const [newExecution] = await api.workbenches.executions.byId(workbenchId);

  return newExecution;
}

export async function loadExecutionDependencies({
  workbenchId,
  api,
  state,
}: {
  workbenchId: string;
  api: ISingleExecutionDependencies['api'];
  state: IState<ISingleExecutionStore>;
}): Promise<ExecutionDependencies> {
  const executionData = await getExecutionByWorkbenchId(workbenchId, api);
  if (!executionData.recipe) {
    throw new Error('Invalid execution data supplied');
  }
  const config = getRecipeConfig(executionData.recipe.id);

  return {
    executionData,
    config,
    barcode: createBarcodeIO(executionData.recipe.id),
    loader: {
      inputLoader: createInputStageLoader({
        state,
        api,
      }),
      outputLoader: createOutputStageLoader({
        state,
        api,
      }),
      cardProgLoader: createCardProgStageLoader({
        // Maybe we should use cardProgLoader only if the execution type is cardprog
        state: state as unknown as IState<ISingleExecutionStore<WorkOrderKind.CardProg>>,
      }),
      qaLoader: createQaStageLoader({
        state,
        api,
      }),
    },
  };
}

function createInputStageLoader({
  state,
  api,
}: {
  state: IState<ISingleExecutionStore>;
  api: IApi;
}): IInputDataLoader {
  return {
    loadData: async (executionData, dataVersion): Promise<IInputStageData> => {
      const storedState = state.get().controller?.stages.input;
      if (
        storedState &&
        storedState.data.executionId === executionData.id &&
        storedState.data.version === dataVersion
      ) {
        logger.debug('Loading input data from storage');
        return storedState.data;
      }

      logger.debug('Loading input data from API');

      if (!executionData.recipe) {
        logger.error('Invalid execution data supplied');
        throw new Error('Incomplete data, recipe missing');
      }

      const partEntries = await Promise.all(
        executionData.recipe.inputs.map(async (item) => {
          const partId = item.part_id.toString();
          const parts = await api.parts.byIds(partId);

          //todo: sometimes server returns array sometimes object?
          const part = Array.isArray(parts) ? parts[0] : parts;

          return [
            part.id,
            {
              serialized: part.serialized,
              partId: part.id,
              partName: part.description,
              reqQty: new Decimal(item.part_qty),
              loaded: [],
            },
          ];
        })
      );

      return {
        version: dataVersion,
        executionId: executionData.id,
        recipeId: executionData.recipe.id,
        parts: Object.fromEntries(partEntries),
      };
    },
  };
}

function createOutputStageLoader({
  state,
  api,
}: {
  state: IState<ISingleExecutionStore>;
  api: IApi;
}): IOutputDataLoader {
  return {
    loadData: async (executionData, config, dataVersion): Promise<IOutputStageData> => {
      const storedState = state.get().controller?.stages.output;
      if (
        storedState &&
        storedState.data.executionId === executionData.id &&
        storedState.data.version === dataVersion
      ) {
        logger.debug('Loading input data from storage');
        return storedState.data;
      }

      const outputParts = await api.parts.byIds(executionData.recipe!.output_part_id.toString());

      //todo: sometimes server returns array sometimes object?
      const outputPart = Array.isArray(outputParts) ? outputParts[0] : outputParts;

      let parts = [];
      if (outputPart.serialized) {
        const partNumDecimal = new Decimal(executionData.recipe!.output_part_qty).div(
          new Decimal(1)
        );
        if (!partNumDecimal.isInt()) {
          throw new Error('Output part quantity is fractional, not supported');
        }
        const partNum = partNumDecimal.toNumber();

        parts = [];
        for (let i = 0; i < partNum; i += 1) {
          parts.push({
            qty: new Decimal(1),
            identifier: {},
            scanned: false,
          });
        }
      } else {
        throw new Error('support for lot-numbered output parts not implemented');
      }

      // todo: handle case where output is with lot number!!!
      const scanRequired =
        config.output?.scanRequired ||
        (outputPart.serialized && config.output?.serialNumberAction === 'generate');

      return {
        version: dataVersion,
        executionId: executionData.id,
        partId: outputPart.id,
        serialized: outputPart.serialized,
        partName: outputPart.description || 'N/A',
        artefacts: [],
        scanRequired,
        parts,
      };
    },
  };
}

function createCardProgStageLoader({
  state,
}: {
  state: IState<ISingleExecutionStore<WorkOrderKind.CardProg>>;
}): ICardProgDataLoader {
  return {
    loadData: async (executionData, config, dataVersion) => {
      // Card prog stage can be undefined if the execution type is not cardprog
      const { controller } = state.get();
      let storedData = controller?.stages.cardprog?.data;

      if (storedData?.executionId !== executionData.id || storedData?.version !== dataVersion) {
        storedData = undefined;
      }

      return {
        version: dataVersion,
        executionId: executionData.id,
        liveCardUid: undefined,
        liveCardStatus: 'missing',
        probeCardResult: { status: 'missing', keyGrp: 'a' },
        programmed: false,
        cardId: '',
        ...('card_id' in executionData.state_data && {
          cardId: executionData.state_data.card_id,
          cardUid: executionData.state_data.card_uid || undefined,
          noaId: executionData.state_data.card_noa_id || undefined,
          boxSerialNumber: executionData.state_data.box_serial_number || undefined,
        }),
        ...storedData,
      };
    },
    saveArtifactIntoOutput: (artifactId: number) => {
      state.set((data) => {
        data.controller!.stages.output.data.artefacts.push(artifactId);
      });
    },
    saveNoaSerialIntoOutputStage: (noaPartId: number, noaSerialNumber: string) => {
      state.set((data) => {
        const { output } = data.controller!.stages;

        if (Number(output.data.partId) !== Number(noaPartId)) {
          logger.error('Noa serial number does not match output part id');
          return;
        }
        const { parts } = data.controller!.stages.output.data;
        const part = parts.find((p) => !p.scanned);
        if (!part) {
          logger.error('No part found to save noa serial into');
          return;
        }
        part.identifier.serial_number = noaSerialNumber;
        part.scanned = true;
      });
    },
    storeCardDataInCache: (cardData) => {
      const defaultCache = { capacity: 250, cache: {}, order: [] };

      state.set((newState) => {
        const cache = newState.programmedCardCache || defaultCache;
        const newCache = lru(cache, {
          type: 'put',
          key: cardData.cardId,
          value: cardData,
        });
        newState.programmedCardCache = newCache;
      });
    },
    loadCardDataFromCache: (cardId) => {
      const cache = state.get().programmedCardCache;
      if (!cache) {
        return null;
      }
      let cardData: ICardProgData | null = null;

      // Lru cache should be updated after every get operation
      state.set((newState) => {
        const newCache = lru(cache, { type: 'get', key: cardId });
        newState.programmedCardCache = newCache;
        cardData = newCache.cache[cardId] || null;
      });

      return cardData;
    },
    isNoaSerialNumberUsed: (noaSerialNumber) => {
      const { programmedCardCache } = state.get();
      if (!programmedCardCache) {
        return false;
      }
      return Object.values(programmedCardCache.cache).some(
        (cardData) => cardData.noaId === noaSerialNumber
      );
    },
  };
}

function createQaStageLoader({
  state,
}: {
  state: IState<ISingleExecutionStore>;
  api: IApi;
}): IQaDataLoader {
  return {
    loadData: async (executionData, _config, dataVersion) => {
      const controller = state.get().controller as unknown as IStageController<WorkOrderKind.Qa>;
      const storedState = controller.stages.qa;
      if (
        storedState &&
        storedState.data.executionId === executionData.id &&
        storedState.data.version === dataVersion
      ) {
        logger.debug('Loading input data from storage');
        return storedState.data;
      }

      // TODO: get QA data from API
      return {
        version: dataVersion,
        executionId: executionData.id,
      };
    },
  };
}
