import { Logger } from '@/logger';
import { IStageController, IState } from '../interfaces';
import { IErrorKeys } from '../interfaces/errors';
import { IStage } from '../interfaces/stages/generic';

export function createErrorHandler(state: IState<IStage | IStageController<any>>, logger: Logger) {
  return {
    addError: (key: IErrorKeys, message: string) => {
      logger.error('Error added', { key, message });
      state.set((stage) => {
        stage.errors[key] = message;
      });
    },
    removeError: (key: IErrorKeys) => {
      logger.info('Error removed', { key });
      state.set((stage) => {
        stage.errors[key] = null;
      });
    },
  };
}
