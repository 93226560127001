import { QueryClient } from '@tanstack/react-query';
import Decimal from 'decimal.js';
import { fabApi } from '@/config';
import { BarcodeStorage } from '@/pages/debug/NewExe/BarcodeStorage';
import { type ExecutionRecipeConfig } from '@/pages/debug/NewExe/types';
import {
  ExecutionInputBody,
  ExecutionState,
  PartIdentifier,
  WorkbenchExecution,
} from '@/utils/api/fab.types';
import { jsonDeserialize, jsonSerialize, partIdEq } from '../util';

const DATA_VERSION = 1;
type Data = {
  executionId: number;
  recipeId: number;
  version: number;
  parts: {
    [partId: number]: {
      serialized: boolean;
      partId: number;
      partName: string;
      reqQty: Decimal;
      loaded: Array<{
        qty: Decimal;
        identifier: PartIdentifier;
      }>;
    };
  };
};

const STORAGE_KEY = 'exe.input';

type ExecutionInputProps = {
  data: Data;
  config: ExecutionRecipeConfig;
  barcodeStorage: BarcodeStorage;
};
type ExecutionInputLoadProps = {
  execution: WorkbenchExecution;
  q: QueryClient;
  config: ExecutionRecipeConfig;
  barcodeStorage: BarcodeStorage;
};

export class ExecutionInput {
  data: Data;
  config: ExecutionRecipeConfig;
  barcodeStorage: BarcodeStorage;

  constructor(props: ExecutionInputProps) {
    const { data, config, barcodeStorage } = props;
    this.data = data;
    this.barcodeStorage = barcodeStorage;
    this.config = config;

    this.loadLots();
  }

  private loadLots() {
    for (const partId in this.data.parts) {
      const part = this.data.parts[partId];
      if (!part.loaded.length && !part.serialized) {
        const loadedLot = this.barcodeStorage.getPersistedBarcode(part.partId);
        if (loadedLot) {
          part.loaded.push({
            qty: part.reqQty,
            identifier: { lot_number: loadedLot },
          });
        }
      }
    }
  }

  private saveData() {
    if (this.data) {
      sessionStorage.setItem(STORAGE_KEY, JSON.stringify(this.data, jsonSerialize));
    }
  }

  private static loadDataFromStorage(executionId: number): Data | null {
    const serializedData = sessionStorage.getItem(STORAGE_KEY);
    if (!serializedData) {
      return null;
    }

    try {
      const data = JSON.parse(serializedData, jsonDeserialize) as Data;
      if (data.executionId !== executionId || data.version !== DATA_VERSION) {
        return null;
      }
      return data;
    } catch (_) {
      return null;
    }
  }

  private static async loadDataFromServer(
    data: WorkbenchExecution,
    q: QueryClient,
    _config: ExecutionRecipeConfig
  ): Promise<Data> {
    if (!data.recipe) {
      throw new Error('Incomplete data, recipe missing');
    }

    const partEntries = await Promise.all(
      data.recipe.inputs.map(async (item) => {
        const parts = await q.fetchQuery({
          queryKey: fabApi.parts.key.concat(item.part_id.toString()),
          queryFn: () => fabApi.parts.byIds(item.part_id.toString()),
          staleTime: 60 * 60_000,
        });
        //todo: wtf? sometimes server returns array sometimes object?
        const part = Array.isArray(parts) ? parts[0] : parts;

        return [
          part.id,
          {
            serialized: part.serialized,
            partId: part.id,
            partName: part.description,
            reqQty: new Decimal(item.part_qty),
            loaded: [],
          },
        ];
      })
    );

    return {
      version: DATA_VERSION,
      executionId: data.id,
      recipeId: data.recipe.id,
      parts: Object.fromEntries(partEntries),
    };
  }

  static async load(props: ExecutionInputLoadProps): Promise<ExecutionInput> {
    const { execution, config, barcodeStorage, q } = props;
    const storageData = ExecutionInput.loadDataFromStorage(execution.id);
    if (storageData) {
      return new ExecutionInput({ data: storageData, config, barcodeStorage });
    }

    const serverData = await ExecutionInput.loadDataFromServer(execution, q, config);
    const obj = new ExecutionInput({ data: serverData, config, barcodeStorage });
    obj.saveData();

    return obj;
  }

  getLoadedInputStatus(partId: number): [boolean, string] {
    if (!(partId in this.data.parts)) {
      return [false, 'unknown part id'];
    }

    const { serialized, reqQty, loaded } = this.data.parts[partId];

    let totalQty = new Decimal(0);
    for (const part of loaded) {
      totalQty = totalQty.add(part.qty);
      if (serialized) {
        if (!part.identifier.serial_number) {
          return [false, 'missing serial number'];
        }
      } else if (!part.identifier.lot_number) {
        return [false, 'missing lot number'];
      }
    }

    if (!totalQty.eq(reqQty)) {
      return [false, 'req. qty not met'];
    }

    return [true, 'ok'];
  }

  isReady(): boolean {
    return Object.values(this.data.parts).every(
      ({ partId }) => this.getLoadedInputStatus(partId)[0]
    );
  }

  onScanPart(partId: number, identifier: PartIdentifier) {
    // default behavior is to just 'push' into the loadedInputs array
    // and eliminate entries if total qty is too big.
    if (!(partId in this.data.parts)) {
      return false;
    }

    const { loaded, reqQty, serialized } = this.data.parts[partId];
    if (loaded && loaded.some((part) => partIdEq(part.identifier, identifier))) {
      return false;
    }

    if (serialized && identifier.lot_number) {
      return false;
    }

    if (!serialized && identifier.serial_number) {
      return false;
    }

    // todo: eventually for a lot, the qty should be provided
    const qty = serialized ? new Decimal(1.0) : reqQty;

    let totalQty = loaded.reduce((acc, part) => acc.add(part.qty), new Decimal(0));

    while (totalQty.add(qty) > reqQty && loaded.length) {
      const { qty: subQty } = loaded.shift()!;
      totalQty = totalQty.sub(subQty);
    }

    loaded.push({
      identifier,
      qty,
    });

    if (identifier.lot_number) {
      const executions =
        this.config.input?.[partId]?.lotNumberPersistence ||
        this.config.input?.lotNumberPersistence ||
        20;
      this.barcodeStorage.persistBarcode(partId, identifier.lot_number, executions);
    }

    this.saveData();
    return true;
  }

  afterExit() {
    const lotNumbers = Object.values(this.data.parts)
      .filter((part) => !part.serialized)
      .flatMap((part) => part.loaded.map((loaded) => loaded.identifier.lot_number!));
    this.barcodeStorage.decrementBarcodes(lotNumbers);
  }

  getAdvanceBody(): ExecutionInputBody {
    const inputParts = Object.values(this.data.parts).flatMap((part) =>
      part.loaded.map(({ qty, identifier }) => ({
        part_id: part.partId,
        qty: qty.toString(),
        identifier,
      }))
    );

    return {
      name: ExecutionState.Input,
      input_parts: inputParts,
    };
  }
}
