import { createJSONStorage } from 'zustand/middleware';
import Decimal from 'decimal.js';
import { isObject } from '@/pages/debug/NewExe/util';

const CODEC_TYPE_PROP = '$$type' as const;
const CODEC_VALUE_PROP = '$$value' as const;

type CodecValue = {
  [CODEC_TYPE_PROP]: 'Decimal' | 'Date' | 'Infinity';
  [CODEC_VALUE_PROP]: string;
};

const isCodecValue = (value: unknown): value is CodecValue =>
  isObject(value) &&
  CODEC_TYPE_PROP in value &&
  CODEC_VALUE_PROP in value &&
  typeof value[CODEC_VALUE_PROP] === 'string';

const serializeSpecialTypes = (value: unknown): CodecValue | undefined => {
  if (Decimal.isDecimal(value)) {
    return { [CODEC_TYPE_PROP]: 'Decimal', [CODEC_VALUE_PROP]: value.toString() };
  }
  if (value instanceof Date) {
    return { [CODEC_TYPE_PROP]: 'Date', [CODEC_VALUE_PROP]: value.toISOString() };
  }
  if (value === Infinity) {
    return { [CODEC_TYPE_PROP]: 'Infinity', [CODEC_VALUE_PROP]: 'Infinity' };
  }
  // Array buffer type is too large to be stored in localStorage
  return undefined;
};

const deserializeSpecialTypes = (value: CodecValue): Decimal | Date | Number => {
  switch (value[CODEC_TYPE_PROP]) {
    case 'Decimal':
      return new Decimal(value[CODEC_VALUE_PROP]);
    case 'Date':
      return new Date(value[CODEC_VALUE_PROP]);
    case 'Infinity':
      return Infinity;
    default:
      throw new Error(`Unknown codec type: ${value[CODEC_TYPE_PROP]}`);
  }
};

export const jsonSerialize = (_: unknown, value: unknown): unknown => {
  const specialType = serializeSpecialTypes(value);
  if (specialType) return specialType;

  if (isObject(value)) {
    return Object.fromEntries(
      Object.entries(value).map(([key, val]) => [key, jsonSerialize(key, val)])
    );
  }

  if (Array.isArray(value)) {
    return value.map((item) => jsonSerialize('', item));
  }

  return value;
};

export const jsonDeserialize = (_: unknown, value: unknown): unknown => {
  if (isCodecValue(value)) {
    return deserializeSpecialTypes(value);
  }

  return value;
};

export const storageParser = createJSONStorage(() => sessionStorage, {
  reviver: jsonDeserialize,
  replacer: jsonSerialize,
});
