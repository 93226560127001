import React from 'react';
import { Text } from '@mantine/core';
import { ISingleExecutionSlice } from '@/store/interfaces/single-execution/store';

interface ExecutionInfoProps {
    execution: ISingleExecutionSlice;
}

const ExecutionInfo: React.FC<ExecutionInfoProps> = ({ execution }) => {
    const { controller } = execution;
    const currentStage = controller?.getCurrentStage?.();

    return (
        <>
            <Text size="sm">Stage: {currentStage?.type || 'N/A'}</Text>
            <Text size="sm">
                {/* {currentStage?.data.serialNumber ? `SN: ${currentStage.data.serialNumber}` : 'No SN'} */}
                No SN
            </Text>
            <Text size="sm">
                {/* {currentStage?.data.lotNumber ? `Lot: ${currentStage.data.lotNumber}` : 'No Lot'} */}
                No Lot
            </Text>
        </>
    );
};

export default ExecutionInfo;
