// @ts-ignore
import { toCanvas } from 'bwip-js';
import { BarcodeType } from '@/utils/BrotherQL/types';

export class LabelDraw {
  MAX_LABEL_TEXT_LENGTH = 10;
  mainCanvas: HTMLCanvasElement;
  barcodeCanvas: HTMLCanvasElement;
  mainCtx: CanvasRenderingContext2D;
  size: [number, number, number] = [306, 306, 12];

  constructor() {
    this.mainCanvas = <HTMLCanvasElement>document.createElement('canvas');
    this.barcodeCanvas = <HTMLCanvasElement>document.createElement('canvas');

    const ctx = this.mainCanvas.getContext('2d');
    if (!ctx) {
      throw new Error('Can not get canvas context');
    }
    this.mainCtx = ctx;
  }

  setSize(size: [number, number, number]) {
    this.size = size;
  }

  private async drawBarcode(barcodeValue: string, barcodeType: BarcodeType = BarcodeType.QRCODE) {
    try {
      toCanvas(this.barcodeCanvas, {
        bcid: barcodeType,
        text: barcodeValue,
        scale: 30,
        ...(barcodeType === BarcodeType.QRCODE && { options: 'eclevel=M' }),
      });

      return true;
    } catch (e) {
      const error = `${e}`
        .replace(/Error: /, '')
        .replace(/bwipp(\.)*/, '')
        .replace(/.+:/, '');
      throw new Error(error);
    }
  }

  async draw(values: DrawValues) {
    const { barcodeValue, barcodeType, labelText, fixedFontSize, shrinkImage = true } = values;

    if (barcodeValue) {
      await this.drawBarcode(barcodeValue, barcodeType);
    }

    const width = values.width ?? this.size[0];
    const height = values.height ?? this.size[1];
    const rightMargin = values.rightMargin ?? this.size[2];

    this.mainCanvas.width = width;
    this.mainCanvas.height = height;

    const textToPrint = labelText.toUpperCase();
    const textToMeasure = fixedFontSize ? '0'.repeat(this.MAX_LABEL_TEXT_LENGTH) : textToPrint;
    const { fontSize, textMetrics } = this.measureTextOnCanvas(textToMeasure, width - rightMargin);

    this.drawText(textToPrint, fontSize);

    const topMargin = values.topMargin ?? this.mm(2);
    const textHeight = textMetrics.fontBoundingBoxAscent;
    const imageSizeAdjustmentFactor = shrinkImage ? -1 : 1;
    const adjustedImageSize =
      Math.min(width, height + textHeight * imageSizeAdjustmentFactor) - topMargin * 2;

    if (!shrinkImage) {
      this.mainCtx.clearRect(0, 0, this.mainCanvas.width, this.mainCanvas.height);
      this.mainCanvas.height = height + textHeight;
      this.drawText(textToPrint, fontSize);
    }

    const centerXPos = (width - adjustedImageSize) / 2;
    this.drawImageProportional(centerXPos, topMargin, adjustedImageSize, adjustedImageSize);

    return this.mainCtx;
  }

  private drawImageProportional(
    xPos: number = 0,
    yPos: number = 0,
    width: number = this.mainCanvas.width,
    height: number = this.mainCanvas.height
  ): void {
    const imageWidth = this.barcodeCanvas.width;
    const imageHeight = this.barcodeCanvas.height;
    const ratio = Math.min(width / imageWidth, height / imageHeight);
    let newWidth = imageWidth * ratio; // new proportional width
    let newHeight = imageHeight * ratio; // new proportional height
    let sourceX: number;
    let sourceY: number;
    let sourceWidth: number;
    let sourceHeight: number;
    let aspectRatio = 1;

    // decide which gap to fill
    if (newWidth < width) {
      aspectRatio = width / newWidth;
    }
    if (Math.abs(aspectRatio - 1) < 1e-14 && newHeight < height) {
      aspectRatio = height / newHeight; // updated
    }
    newWidth *= aspectRatio;
    newHeight *= aspectRatio;

    // calculate source rectangle
    sourceWidth = imageWidth / (newWidth / width);
    sourceHeight = imageHeight / (newHeight / height);

    sourceX = imageWidth - sourceWidth;
    sourceY = imageHeight - sourceHeight;

    // make sure source rectangle is valid
    if (sourceX < 0) {
      sourceX = 0;
    }
    if (sourceY < 0) {
      sourceY = 0;
    }
    if (sourceWidth > imageWidth) {
      sourceWidth = imageWidth;
    }
    if (sourceHeight > imageHeight) {
      sourceHeight = imageHeight;
    }

    // fill image in destination rectangle
    this.mainCtx.drawImage(
      this.barcodeCanvas,
      sourceX,
      sourceY,
      sourceWidth,
      sourceHeight,
      xPos,
      yPos,
      width,
      height
    );
  }

  private drawText(text: string, fontSize: number, margin = 0) {
    this.mainCtx.fillStyle = '#000';
    this.mainCtx.textAlign = 'center';
    this.mainCtx.textBaseline = 'bottom';
    this.mainCtx.font = `900 ${fontSize}px "JetBrains Mono"`;
    this.mainCtx.fillText(text, this.mainCanvas.width / 2 - margin, this.mainCanvas.height);
  }

  private measureTextOnCanvas(text: string, maxWidth: number) {
    let fontSize = 300;

    do {
      fontSize -= 1;
      this.mainCtx.font = `900 ${fontSize}px "JetBrains Mono"`;
    } while (this.mainCtx.measureText(text).width > maxWidth && fontSize >= 1);

    return {
      fontSize,
      textMetrics: this.mainCtx.measureText(text),
    };
  }

  mm(unit: number) {
    return Math.round(unit * 11.4193548);
  }
}

type DrawValues = {
  labelText: string;
  barcodeType?: BarcodeType;
  barcodeValue?: string;
  shrinkImage?: boolean;
  fixedFontSize?: boolean;
  width?: number;
  height?: number;
  rightMargin?: number;
  topMargin?: number;
};
