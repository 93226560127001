import { ApiClient } from '@/utils/api/Client';
import { type ApiPath, FabApi } from '@/utils/api/fab';

export const isDevEnv = process.env.NODE_ENV === 'development';

const getTld = () => {
  if (localStorage.getItem('useProdApi')) {
    return 'com';
  }
  return document.location.host.replace(/.+\./, '') === 'com' ? 'com' : 'dev';
};

const tld = getTld();
export const fabClient = new ApiClient<ApiPath>({ baseUrl: `https://fab-api.persidius.${tld}` });

export const fabApi = new FabApi(fabClient);

// basic stuff
// 1. the recipe 'input' - where we record input stuff and keep
// the lot numbers across executions (if execution recipe_id is same)
// 2. the recipe 'output' - where we generate stuff

export const Config = {
  decimalPrecision: 2,
  barcodeInterCharacterTimeoutMs: 500,
  isTestMode: false,
  isMockEnabled: false,
};
