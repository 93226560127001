import { router } from '@/routes';
import { FabUploadRequest } from '@/utils/api/fab.types';
import { clearAuthUser, getAuthHeader } from '@/utils/auth';

export class ApiClientError extends Error {
  statusCode?: number;
  statusText?: string;
  constructor(message?: string) {
    super(message);
  }
}

export class ApiClient<ApiPath extends string> {
  private readonly baseUrl: string;

  constructor(config: ApiClientConfig) {
    this.baseUrl = config.baseUrl;
  }

  private async http<T>(url: string, config: RequestInit) {
    const request = new Request(url, {
      ...config,
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json; charset=UTF-8',
        ...getAuthHeader(),
        ...config.headers,
      },
      body: config.body ? JSON.stringify(config.body) : null,
    });
    const response = await fetch(request);

    return this.handleResponse<T>(response);
  }

  private async handleResponse<T>(response: Response) {
    if (response.status === 401) {
      clearAuthUser();
      setTimeout(() => router.navigate('/'), 1);
      throw new Error('Unauthorized');
    }

    if (response.ok) {
      try {
        return (await response.json()) as unknown as T;
      } catch (e) {
        console.warn('[api-client]: can not json.parse response -> %o', e);
        // return (await response.text()) as unknown as T;
        const errorMessage = await response.text();
        const err = new ApiClientError(errorMessage);
        err.statusCode = response.status;
        err.statusText = response.statusText;
        return Promise.reject(err);
      }
    } else {
      const errorMessage = await response.text();
      console.warn('[api-client]: response not ok -> [%o, %o]', response.statusText, errorMessage);
      const err = new ApiClientError(errorMessage);
      err.statusCode = response.status;
      err.statusText = response.statusText;
      return Promise.reject(err);
    }
  }

  private getPath(path: string): string {
    return `${this.baseUrl}/${path}`;
  }

  async get<T>(path: ApiPath) {
    return this.http<T>(this.getPath(path), {
      method: 'GET',
    });
  }

  async put<T, D>(path: ApiPath, data?: D) {
    return this.http<T>(this.getPath(path), {
      method: 'PUT',
      // @ts-ignore
      body: data,
    });
  }

  async post<T, D>(path: ApiPath, data?: D) {
    return this.http<T>(this.getPath(path), {
      method: 'POST',
      // @ts-ignore
      body: data,
    });
  }

  async fabUpload<T>(path: ApiPath, data: FabUploadRequest) {
    const request = new Request(this.getPath(path), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': data.contentType,
        ...getAuthHeader(),
      },
      body: data.body,
    });
    const response = await fetch(request);

    return this.handleResponse<T>(response);
  }
}

export interface ApiClientConfig {
  baseUrl: string;
}
