import { createContext, useContext, useEffect, useRef, useState } from 'react';

// these are sort of 'global' commands and should be respected everywhere;
export const Commands = {
  FullOsRestart: '009',
  Confirm: '00',
  Restart: '01',
  RestartAction: '02',
  Retry: '03',
  FullPageRefresh: '55',
  StartTask: '90',
  Logout: '91',
  ResetStage: '92',
  StartAutoAssign: '98',
  StopAutoAssign: '99',
};

type ActiveCommands = {
  [cmdCode: string]: {
    name: string;
    callback: (cmd: string, name: string) => any;
  };
};

export class NumpadInput {
  private cmd: string = '';
  private backspaceCount: number = 2;
  activeCommands: ActiveCommands = {};
  private onInputCallbacks: Array<(cmd: string, backspaceCount: number) => any> = [];

  static use(): NumpadInput {
    const numpadInput = useRef(new NumpadInput());
    useEffect(() => {
      const cb = numpadInput.current.onKeyDown.bind(numpadInput.current);
      window.addEventListener('keydown', cb);
      return () => {
        window.removeEventListener('keydown', cb);
      };
    }, [numpadInput]);

    return numpadInput.current;
  }

  onKeyDown(event: KeyboardEvent) {
    const { code } = event;
    if (!code.startsWith('Numpad') && code !== 'Backspace') {
      return;
    }

    if (code[6] >= '0' && code[6] <= '9') {
      this.cmd += code[6];
      this.backspaceCount = 0;
      this.callInputHandlers();
      return;
    }

    if (code === 'NumpadEnter') {
      const match = this.activeCommands[this.cmd];
      if (match) {
        match.callback(this.cmd, match.name);
      }

      this.cmd = '';
      this.backspaceCount = 0;
      this.callInputHandlers();
      return;
    }

    if (code === 'Backspace') {
      this.cmd = this.cmd.substring(0, this.cmd.length - 1);
      if (this.cmd.length === 0) {
        this.backspaceCount += 1;
      }

      this.callInputHandlers();
    }
  }

  private callInputHandlers() {
    for (const handler of this.onInputCallbacks) {
      handler(this.cmd, this.backspaceCount);
    }
  }

  registerInputHandler(callback: (cmd: string, backspaceCount: number) => any) {
    this.onInputCallbacks.push(callback);
  }

  unregisterInputHandler(callback: (cmd: string, backspaceCount: number) => any) {
    const ix = this.onInputCallbacks.indexOf(callback);
    if (ix !== -1) {
      this.onInputCallbacks.splice(ix, 1);
    }
  }

  registerCommand(cmd: string, name: string, callback: (cmd: string, name: string) => any): string {
    if (this.activeCommands[cmd]) {
      throw Error(`There is already a handler registered for command code ${cmd}`);
    }
    this.activeCommands[cmd] = {
      callback,
      name,
    };

    return cmd;
  }

  unregisterCommand(cmd: string) {
    delete this.activeCommands[cmd];
  }
}

export const NumpadInputContext = createContext(new NumpadInput());

// don't think this is ever used but serves
// as base for useNamedCommand
export function useCommand(
  cmd: string,
  name: string,
  callback: (cmd: string, name: string) => any
) {
  const numpadInput = useContext(NumpadInputContext);
  useEffect(() => {
    const id = numpadInput.registerCommand(cmd, name, callback);
    return () => {
      numpadInput.unregisterCommand(id);
    };
  }, [numpadInput]);
}

// used throughout the code with generic commands (eg logout or refresh)
export function useNamedCommand(
  name: keyof typeof Commands,
  callback: (cmd: string, name: string) => any
) {
  const cmd = Commands[name];
  return useCommand(cmd, name, callback);
}

// this is used almost exclusively by the
// CommandModal component
export function useNumpadInput() {
  const [input, setInput] = useState({ cmd: '', backspaceCount: 0 });

  const onInput = (cmd: string, backspaceCount: number) => {
    setInput({ cmd, backspaceCount });
  };
  const numpadInput = useContext(NumpadInputContext);
  useEffect(() => {
    numpadInput.registerInputHandler(onInput);
    return () => {
      numpadInput.unregisterInputHandler(onInput);
    };
  }, [setInput, numpadInput]);

  return input;
}
