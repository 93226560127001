import { create, StateCreator } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { devtools, persist } from 'zustand/middleware';
import { Logger } from '../../../logger';
import { jsonDeserialize, jsonSerialize, storageParser } from '../dependencies/storage';
import {
  IParallelExecutionsActions,
  IParallelExecutionsStore,
} from '@/store/interfaces/parallel-executions/store';
import { createEncapsulatedState } from '../controller/state-factory';
import { createSingleExecution } from '../singel-execution/store';
import {
  ISingleExecutionSlice,
  ISingleExecutionStore,
} from '@/store/interfaces/single-execution/store';

const logger = new Logger('ParallelExecutionsStore');

const STORAGE_KEY = 'parallel-executions-storage';

type IParallelExecutionsSlice = IParallelExecutionsStore & IParallelExecutionsActions;

const createParallelExecutionsSlice: StateCreator<
  IParallelExecutionsSlice,
  [['zustand/immer', never], ['zustand/persist', unknown], ['zustand/devtools', never]]
> = (set, get) => ({
  errors: {},
  status: 'idle',
  executions: {},
  initializeGrid: async ({ gridKeys, workbenchId, dependencies }) => {
    // TODO: make sure it's working with restore from storage
    // Watch out for memory leaks

    const { executions: prevExecutions } = get();
    const isInitialized = Object.keys(prevExecutions).length > 0;
    if (!isInitialized) {
      // Initialize the grid
      const executions = gridKeys.reduce(
        (acc, key) => {
          const executionState = createEncapsulatedState(
            { get, set },
            (state) => state.executions[key]
          );

          const executionStore: ISingleExecutionStore = {
            status: 'idle',
            controller: null,
            errors: {},
            focused: false,
          };
          const executionActions = createSingleExecution(executionState);

          const execution = {
            ...executionStore,
            ...executionActions,
          };

          acc[key] = execution;
          return acc;
        },
        {} as Record<string, ISingleExecutionSlice>
      );

      set({ executions });
      return;
    }

    // Restore the grid

    for (const key of gridKeys) {
      const executionState = createEncapsulatedState(
        { get, set },
        (state) => state.executions[key]
      );

      const executionActions = createSingleExecution(executionState);

      executionState.set((newState) => {
        // eslint-disable-next-line guard-for-in
        for (const actionKey in executionActions) {
          // @ts-expect-error - we know that actionKey is a key of executionActions
          newState[actionKey] = executionActions[actionKey];
        }
      });

      if (executionActions.isExecutionPending()) {
        await executionActions.startExecution({ workbenchId, dependencies });
      }
    }
  },
  startExecution: async ({ executionKey, workbenchId, dependencies }) => {
    const execution = get().executions[executionKey];
    if (!execution) {
      logger.error('Execution not found', { executionKey });
      return;
    }

    await execution.startExecution({ workbenchId, dependencies });
  },
  focusExecution: ({ executionKey }) => {
    const hasExecution = get().executions[executionKey];
    if (!hasExecution) {
      logger.error('Execution not found', { executionKey });
      return;
    }

    set((state) => {
      const { executions } = state;
      // Clear the focus from all other executions
      Object.values(executions).forEach((execution) => {
        execution.focused = false;
      });

      const execution = executions[executionKey];
      execution.focused = true;
    });
  },
});

export const useParallelExecutionsStore = create<IParallelExecutionsSlice>()(
  devtools(
    persist(immer(createParallelExecutionsSlice), {
      name: STORAGE_KEY,
      storage: storageParser,
    }),
    {
      serialize: {
        replacer: jsonSerialize,
        reviver: jsonDeserialize,
      },
    }
  )
);
