import { ApiClient } from '@/utils/api/Client';
import {
  Artefact,
  CreateUserAuthorization,
  ExecutionAdvanceParams,
  FabUploadRequest,
  Part,
  PartEdit,
  PartWithSuppliers,
  POCreateDelivery,
  PurchaseOrder,
  PurchaseOrderCreate,
  PurchaseOrderEdit,
  PurchaseOrderWithDeliveries,
  Recipe,
  RecipeCreate,
  RecipeDeprecate,
  Stock,
  StockHistory,
  StockWithInventory,
  Supplier,
  SupplierCreate,
  SupplierUpdate,
  SupplierWithParts,
  UserAuthorization,
  WorkbenchesCreateKeyResponse,
  WorkbenchExecution,
  SerialNumber,
  SerialNumberCreate,
  Lot,
  CardBoxContents,
  CardData,
} from '@/utils/api/fab.types';
import { isString } from '@/utils/fns';

// shared between workbench & dashboard apps
export class FabApi {
  private readonly client: ApiClient<ApiPath>;

  constructor(client: ApiClient<ApiPath>) {
    this.client = client;
  }

  private path(path: ApiPath, ...j: (string | number)[]): ApiPath {
    return [path, ...j]
      .filter((x) => x && x.toString().length > 0)
      .map((x) => `${x}`)
      .join('/') as ApiPath;
  }

  public readonly parts = {
    key: ['parts'],
    get: () => this.client.get<Part[]>('parts'),
    byIds: (partOrId: string | string[]) =>
      this.client.get<PartWithSuppliers[]>(
        this.path('parts', isString(partOrId) ? partOrId : partOrId.join(','))
      ),
    update: (part: WithId<PartEdit>) =>
      this.client.put<PartWithSuppliers, WithId<PartEdit>>(this.path('parts', part.id), part),
    add: (part: PartEdit) => this.client.post<PartWithSuppliers, PartEdit>('parts', part),
  };

  public readonly suppliers = {
    key: ['suppliers'],
    get: () => this.client.get<Supplier[]>('suppliers'),
    byId: (partOrId: Stringified<IdOnly<Supplier>> | string) =>
      this.client.get<SupplierWithParts>(
        this.path('suppliers', isString(partOrId) ? partOrId : partOrId.id)
      ),
    update: (supplier: WithId<SupplierUpdate>) =>
      this.client.put<SupplierWithParts, WithId<SupplierUpdate>>(
        this.path('suppliers', supplier.id),
        supplier
      ),
    add: (supplier: SupplierCreate) =>
      this.client.post<SupplierWithParts, SupplierCreate>('suppliers', supplier),
  };

  public readonly purchaseOrders = {
    key: ['purchase_orders'],
    get: () => this.client.get<PurchaseOrder[]>('purchase_orders'),
    byId: (purchaseOrderOrId: Stringified<IdOnly<PurchaseOrder>> | string) =>
      this.client.get<PurchaseOrderWithDeliveries>(
        this.path(
          'purchase_orders',
          isString(purchaseOrderOrId) ? purchaseOrderOrId : purchaseOrderOrId.id
        )
      ),
    update: (purchaseOrder: WithId<PurchaseOrderEdit>) =>
      this.client.put<PurchaseOrderWithDeliveries, WithId<PurchaseOrderEdit>>(
        this.path('purchase_orders', purchaseOrder.id),
        purchaseOrder
      ),
    add: (purchaseOrder: PurchaseOrderCreate) =>
      this.client.post<PurchaseOrderWithDeliveries, PurchaseOrderCreate>(
        'purchase_orders',
        purchaseOrder
      ),
    createDelivery: (purchaseDelivery: WithId<POCreateDelivery>) =>
      this.client.post<PurchaseOrderWithDeliveries, WithId<POCreateDelivery>>(
        this.path('purchase_orders', purchaseDelivery.id, 'deliveries'),
        purchaseDelivery
      ),
  };

  public readonly stocks = {
    key: ['stocks'],
    get: () => this.client.get<Stock[]>('stocks'),
    byId: (partOrId: Stringified<IdOnly<Stock>> | string) =>
      this.client.get<StockWithInventory>(
        this.path('stocks', isString(partOrId) ? partOrId : partOrId.id)
      ),
    getHistory: (partOrId: Stringified<IdOnly<Stock>> | string) =>
      this.client.get<StockHistory[]>(
        this.path('stocks', isString(partOrId) ? partOrId : partOrId.id, 'history')
      ),
  };

  public readonly recipes = {
    key: ['recipes'],
    get: () => this.client.get<Recipe[]>('recipes'),
    deprecate: (recipe: WithId<RecipeDeprecate>) =>
      this.client.put<Recipe, WithId<RecipeDeprecate>>(this.path('recipes', recipe.id), recipe),
    add: (recipe: RecipeCreate) => this.client.post<Recipe, RecipeCreate>('recipes', recipe),
  };

  public readonly workbenches = {
    key: ['workbenches'],
    get: () => this.client.get<Recipe[]>('workbenches'),
    byId: (id: string) => this.client.get<StockWithInventory>(this.path('workbenches', id)),
    ping: (id: string) => this.client.post<string, string>(this.path('workbenches', id, 'ping')),
    createKey: (id: string) =>
      this.client.post<WorkbenchesCreateKeyResponse, string>(this.path('workbenches', id, 'key')),
    executions: {
      key: ['workbenches', 'executions'],
      byId: (id: string) =>
        this.client.get<WorkbenchExecution[]>(this.path('workbenches', id, 'executions')),
      assign: (id: string) => this.client.post(this.path('workbenches', id, 'executions')),
      advance: <T>(params: ExecutionAdvanceParams<T>) =>
        this.client.post<WorkbenchExecution, T>(
          this.path('executions', params.id, 'state', params.nextState),
          params.body
        ),
    },
  };

  public readonly user = {
    key: ['user'],
    authorization: (createUserAuthorization: CreateUserAuthorization) =>
      this.client.post<UserAuthorization, CreateUserAuthorization>(
        this.path('user', 'authorization'),
        createUserAuthorization
      ),
  };

  public readonly artefacts = {
    key: ['artefacts'],
    upload: (data: FabUploadRequest) =>
      this.client.fabUpload<Artefact>(this.path('artefacts', data.filename), data),
  };

  public readonly serialNumbers = {
    key: ['serial_numbers'],
    getBySN: (serialNumber: string) =>
      this.client.get<SerialNumber[]>(this.path('serial_numbers', serialNumber)),
    create: (serialNumber: SerialNumberCreate) =>
      this.client.post<SerialNumber, SerialNumberCreate>('serial_numbers', serialNumber),
  };

  public readonly lots = {
    key: 'lots',
    getByLotNumber: (lotNumber: string) => this.client.get<Lot>(this.path('lots', lotNumber)),
  };

  public readonly fixes = {
    key: ['fixes'],
    getCardBox: (boxSerialNumber: string) =>
      this.client.get<CardBoxContents>(this.path('fixes', 'card_box', boxSerialNumber)),
    getCard: (cardId: string) => this.client.get<CardData>(this.path('fixes', 'card', cardId)),
  };
}

export type ApiPath =
  | 'parts'
  | 'suppliers'
  | 'purchase_orders'
  | 'stocks'
  | 'recipes'
  | 'user'
  | 'artefacts'
  | 'executions'
  | 'serial_numbers'
  | 'workbenches'
  | 'lots'
  | 'fixes';

export type WithoutId<T> = Omit<T, 'id'>;
export type IdOnly<T extends { id: any }> = NonNullable<Pick<T, 'id'>>;
export type WithId<T> = { id: string } & Partial<WithoutId<T>>;
export type Stringified<T> = {
  [P in keyof T]: string;
};
