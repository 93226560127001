import { Code, Group, Loader } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { ExecutionRenderData } from '@/pages/debug/NewExe/types';
import { fabApi } from '@/config';

type CardProgCountProps = {
  cardProg: ExecutionRenderData['cardProg'];
};

export function CardProgCount(props: CardProgCountProps) {
  const { cardProg } = props;
  const { boxSerialNumber, noaId, cardUid, liveCardUid, liveCardStatus } = cardProg ?? {};

  const query = useQuery({
    queryKey: fabApi.fixes.key.concat(boxSerialNumber),
    queryFn: () => fabApi.fixes.getCardBox(boxSerialNumber),
    refetchOnWindowFocus: true,
    staleTime: 1,
    enabled: false,
  });

  useEffect(() => {
    query.refetch();
  }, [noaId, cardUid, boxSerialNumber, liveCardUid, liveCardStatus]);

  const { data } = query;
  const count = data?.length ?? '?';
  const isWorking = query.isPending || query.isLoading;

  return (
    <Code block ml="auto" color="teal" fw={900}>
      <Group>
        <Loader size="xs" color="yellow" style={{ visibility: isWorking ? 'visible' : 'hidden' }} />
        {count} / 200
      </Group>
    </Code>
  );
}
