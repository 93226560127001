import { jsonDeserialize, jsonSerialize } from '@/pages/debug/NewExe/util';

const DATA_VERSION = 1;
type BarcodeStorageData = {
  version: number;
  recipeId: number;
  barcodes: {
    [partId: string]: {
      partId: number;
      value: string;
      remainingExecutions: number;
    };
  };
};
const STORAGE_KEY = 'wb.barcodes';

export class BarcodeStorage {
  data: BarcodeStorageData;

  constructor(recipeId: number) {
    const storageData = this.loadFromStorage(recipeId);
    if (storageData) {
      this.data = storageData;
    } else {
      this.data = {
        version: DATA_VERSION,
        recipeId,
        barcodes: {},
      };
      this.saveData();
    }
  }

  // todo: call on unauth!!
  static clear() {
    sessionStorage.removeItem(STORAGE_KEY);
  }

  private saveData() {
    if (this.data) {
      sessionStorage.setItem(STORAGE_KEY, JSON.stringify(this.data, jsonSerialize));
    }
  }

  private loadFromStorage(recipeId: number): BarcodeStorageData | null {
    const serializedData = sessionStorage.getItem(STORAGE_KEY);
    if (!serializedData) {
      return null;
    }

    try {
      const data = JSON.parse(serializedData, jsonDeserialize) as BarcodeStorageData;
      if (data.recipeId !== recipeId || data.version !== DATA_VERSION) {
        return null;
      }
      return data;
    } catch (_) {
      return null;
    }
  }

  getPersistedBarcode(partId: number | undefined): string | null {
    if (!partId) {
      return null;
    }

    const barcode = this.data.barcodes[partId.toString()];
    if (!barcode) {
      return null;
    }

    if (!barcode.remainingExecutions) {
      return null;
    }

    return barcode.value;
  }

  persistBarcode(partId: number, barcodeValue: string, executions: number) {
    if (!executions) {
      return;
    }

    this.data.barcodes[partId.toString()] = {
      value: barcodeValue,
      partId,
      remainingExecutions: executions,
    };
  }

  decrementBarcodes(barcodeValues: string[]) {
    const barcodesMap = Object.fromEntries(barcodeValues.map((barcode) => [barcode, barcode]));
    let updated = false;

    const barcodes = Object.values(this.data.barcodes);
    for (const barcode of barcodes) {
      if (barcode.value in barcodesMap) {
        updated = true;
        barcode.remainingExecutions -= 1;
        if (barcode.remainingExecutions === 0) {
          delete this.data.barcodes[barcode.partId.toString()];
        }
      }
    }

    if (updated) {
      this.saveData();
    }
  }
}
