import { Center } from '@mantine/core';
import React from 'react';

type FullyCenterProps = React.PropsWithChildren<{}>;

export function FullyCenter(props: FullyCenterProps) {
  return (
    <Center h="90vh" maw="50vw" mx="auto">
      {props.children}
    </Center>
  );
}
