import { BarcodeStorage } from '@/pages/debug/NewExe/BarcodeStorage';
import { IBarcodeIO } from '@/store/interfaces/barcode';

export const createBarcodeIO = (recipeId: number): IBarcodeIO => {
  const barcodeStorage = new BarcodeStorage(recipeId);

  return {
    requestLotNumber: (partId: number) => barcodeStorage.getPersistedBarcode(partId),
    decrementBarcodes: (codes: string[]) => {
      barcodeStorage.decrementBarcodes(codes);
    },
    persistBarcode: (partId: number, lot: string, iter: number) => {
      barcodeStorage.persistBarcode(partId, lot, iter);
    },
  };
};
