export interface ILogger {
  // Log levels
  debug: (message: string, ...args: unknown[]) => void;
  info: (message: string, ...args: unknown[]) => void;
  warn: (message: string, ...args: unknown[]) => void;
  error: (message: string, ...args: unknown[]) => void;

  // Log with time measurement
  startTimer: (label: string) => void;
  endTimer: (label: string) => void;

  // Structured logging
  log: (level: LogLevel, message: string, context?: Record<string, unknown>) => void;

  // Set minimum log level
  setLogLevel: (level: LogLevel) => void;

  // Add metadata to all subsequent logs
  addMetadata: (metadata: Record<string, unknown>) => void;

  // Create a child logger with additional context
  child: (options: { module: string }) => ILogger;
}

export enum LogLevel {
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}
