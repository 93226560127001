import { create, StateCreator } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { devtools, persist } from 'zustand/middleware';
import { createController } from '../controller/controller';
import { IStageController, IState } from '../../interfaces';
import { createControllerStateProxy, createEncapsulatedState } from '../controller/state-factory';
import { Logger } from '../../../logger';
import {
  ISingleExecutionActions,
  ISingleExecutionSlice,
  ISingleExecutionStore,
} from '../../interfaces/single-execution/store';
import { loadExecutionDependencies } from '../dependencies/execution';
import { handleScan } from '../../utils/scan-handler';
import { IErrorKeys } from '../../interfaces/errors';
import { jsonDeserialize, jsonSerialize, storageParser } from '../dependencies/storage';
import { ExecutionDependencies, ISingleExecutionDependencies } from '../../interfaces/dependencies';
import { WorkOrderKind } from '@/utils/api/fab.types';

const logger = new Logger('SingleExecutionStore');

const STORAGE_KEY = 'single-execution-storage';

export function createSingleExecution(
  state: IState<ISingleExecutionStore>
): ISingleExecutionActions {
  const isExecutionPending = () => {
    const { status } = state.get();
    return status === 'in-progress' || status === 'initializing' || status === 'error';
  };

  const startExecution: ISingleExecutionActions['startExecution'] = async ({
    workbenchId,
    dependencies,
  }) => {
    logger.debug('Starting execution');
    state.set((newState) => {
      newState.status = 'initializing';
    });

    const setError = (key: IErrorKeys, message: string | null) => {
      state.set((newState) => {
        newState.errors[key] = message;
      });
    };

    try {
      const executionDependencies = await initializeExecution(workbenchId, dependencies, state);
      const controller = await createAndSetupController(executionDependencies, dependencies, state);

      state.set((newState) => {
        newState.controller = controller;
        newState.status = 'in-progress';
        newState.errors = {};
      });
      controller.getCurrentStage().onEnter();
    } catch (error) {
      logger.error('Error starting execution:', error);
      const message = error instanceof Error ? error.message : 'A controller error occurred';
      setError('controller', message);
      state.set((newState) => {
        newState.status = 'error';
      });
    }
  };

  const resetStage = () => {
    const { controller } = state.get();

    if (!controller) {
      logger.warn('No controller to reset');
      return;
    }

    controller.handleResetStage();
  };

  return {
    startExecution,
    isExecutionPending,
    resetStage,
  };
}

const createSingleExecutionSlice: StateCreator<
  ISingleExecutionSlice,
  [['zustand/immer', never], ['zustand/persist', unknown], ['zustand/devtools', never]]
> = (set, get) => {
  logger.info('Creating single execution store');
  const state = createEncapsulatedState({ get, set }, (newState) => newState);
  const singleExecution = createSingleExecution(state);
  return {
    controller: null,
    focused: true,
    errors: {},
    status: 'idle',
    ...singleExecution,
  };
};

async function initializeExecution(
  workbenchId: string,
  dependencies: ISingleExecutionDependencies,
  state: IState<ISingleExecutionStore>
): Promise<ExecutionDependencies> {
  const executionDependencies = await loadExecutionDependencies({
    workbenchId,
    api: dependencies.api,
    state,
  });

  state.set((prevState) => {
    const restartCounterOn = executionDependencies.config.finished?.restartCounterOn;
    if (!restartCounterOn) return;

    if (!prevState.executionsCounter) {
      prevState.executionsCounter = {
        counter: 0,
        limit: restartCounterOn,
      };
    }
  });

  return executionDependencies;
}

async function createAndSetupController(
  executionDependencies: ExecutionDependencies,
  dependencies: ISingleExecutionDependencies,
  state: IState<ISingleExecutionStore>
): Promise<IStageController> {
  const controllerState = createControllerStateProxy(state.set, state.get);
  const executionKind = executionDependencies.executionData.work_order_kind;

  const handleStagesCompletion = () => {
    logger.info('Controller completed');
    state.set((prevState) => {
      prevState.status = 'completed';
      updateExecutionCounter(prevState);
    });
    const _controller = state.get().controller;
    _controller?.onExit();
    cleanupIOListeners();
  };

  const controller = await createController({
    ...dependencies,
    ...executionDependencies,
    io: {
      ...dependencies.io,
      barcode: executionDependencies.barcode,
    },
    executionKind,
    state: controllerState,
    onCompletion: handleStagesCompletion,
  });

  const { cleanupIOListeners } = setupIOListeners(controller, dependencies, state);

  return controller as IStageController;
}

function setupIOListeners(
  controller: IStageController<WorkOrderKind>,
  dependencies: ISingleExecutionDependencies,
  state: IState<ISingleExecutionStore>
) {
  const scanSub = dependencies.io.scanner.onScan(async (scannedCode) => {
    const isFocused = state.get().focused;
    if (!isFocused) {
      return;
    }
    try {
      logger.debug('Handling scan:', scannedCode);
      state.set((prevState) => {
        prevState.errors.scan = null;
      });
      const payload = await handleScan({ scannedCode, api: dependencies.api });
      logger.debug('Scan handled:', scannedCode);

      if (payload.type === 'lot') {
        controller.handleLotNumberScan(payload.partId, payload.identifier);
      } else if (payload.type === 'serial') {
        controller.handleSerialNumberScan(
          payload.serialNumbers,
          payload.identifier,
          payload.itemType
        );
      }
    } catch (error) {
      logger.error('Error handling scan:', error);
      const errorMessage =
        error instanceof Error && error.message ? error.message : 'An scan error occurred';
      state.set((prevState) => {
        prevState.errors.scan = errorMessage;
      });
    }
  });

  const printSub = dependencies.io.printer.onPrinterStateChanged(() => {
    controller.handlePrinterStateChanged();
  });

  const cleanupIOListeners = () => {
    scanSub.unsubscribe();
    printSub.unsubscribe();
  };

  return { cleanupIOListeners };
}

function updateExecutionCounter(state: ISingleExecutionStore) {
  if (!state.executionsCounter) return;

  if (state.executionsCounter.counter < state.executionsCounter.limit) {
    logger.info('ExecutionFinished::onEnter() - incrementing counter..');
    state.executionsCounter.counter += 1;
  } else {
    logger.info('ExecutionFinished::onEnter() - limit reached, restarting counter..');
    state.executionsCounter.counter = 1;
  }
}

export const useSingleExecutionStore = create<ISingleExecutionSlice>()(
  devtools(
    persist(immer(createSingleExecutionSlice), {
      name: STORAGE_KEY,
      storage: storageParser,
    }),
    {
      serialize: {
        replacer: jsonSerialize,
        reviver: jsonDeserialize,
      },
    }
  )
);
