import { Modal, Text, Mark, Code, List, ListItem } from '@mantine/core';
import { useNumpadInput, NumpadInputContext } from '@/utils/KeyInput/NumpadCommand';
import { useContext } from 'react';

export function CommandModal() {
  const input = useNumpadInput();
  const numpadInput = useContext(NumpadInputContext);
  const opened = input.cmd.length > 0 || input.backspaceCount === 1;
  const matches = Object.entries(numpadInput.activeCommands).filter(([cmd, _]) =>
    cmd.startsWith(input.cmd)
  );

  const possibleCommands = matches.map(([cmd, { name }]) => {
    const rest = cmd.substring(input.cmd.length);
    const matched = (
      <Text span fw={700}>
        <Mark>{input.cmd}</Mark>
        {rest}
      </Text>
    );
    return (
      <ListItem key={name}>
        {matched}: {name}
      </ListItem>
    );
  });

  const title = <>Command {input.cmd.length > 0 ? <Code>{input.cmd}</Code> : null}</>;
  let helpTextContent: string | null = null;
  if (input.cmd.length === 0 && input.backspaceCount === 1) {
    helpTextContent = 'Backspace to close';
  }

  if (!helpTextContent && matches.length === 1) {
    helpTextContent = 'Enter to execute';
  }

  if (!helpTextContent && matches.length === 0) {
    helpTextContent = 'Invalid command';
  }

  // todo: fix size on this thing
  const helpText = helpTextContent ? (
    <Text size="sm" c="dimmed" fs="italic">
      {helpTextContent}
    </Text>
  ) : (
    ''
  );
  return (
    <>
      <Modal opened={opened} onClose={() => {}} title={title} centered withCloseButton={false}>
        {helpText}
        <List>{possibleCommands}</List>
      </Modal>
    </>
  );
}
