import React from 'react';
import { Badge } from '@mantine/core';

interface StatusIndicatorProps {
    status: 'idle' | 'running' | 'failed' | 'success' | 'error' | 'empty' | string; //todo: fix this
    label?: string;
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({ label, status }) => {
    const getColor = () => {
        switch (status) {
            case 'idle': return 'blue';
            case 'running': return 'yellow';
            case 'failed': return 'red';
            case 'success': return 'green';
            case 'error': return 'orange';
            case 'empty': return 'gray';
            default: return 'gray';
        }
    };

    return (
        <Badge color={getColor()} size="lg" fullWidth>
            {label} {status ? status.toUpperCase() : 'Unknown'}
        </Badge>
    );
};

export default StatusIndicator;
