import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SerialDevice, SerialDeviceInfo } from '@/utils/WebSerial/SerialDevice';

export interface SerialContextValue {
  requestDevice: (info: SerialDeviceInfo) => Promise<SerialDevice>;
}

export const SerialContext = createContext<SerialContextValue | null>(null);

export const useSerial = (deviceInfo: SerialDeviceInfo) => {
  const serial = useContext(SerialContext);
  if (!serial) {
    throw new Error('useSerial must be used within a SerialProvider');
  }
  const [serialDevice, setSerialDevice] = useState<SerialDevice>();

  useEffect(() => {
    let _onStateChangeRemoveListener: () => void;
    async function requestDeviceFromContext() {
      if (!serial) {
        throw new Error('useSerial must be used within a SerialProvider');
      }

      const _serialDevice = await serial.requestDevice(deviceInfo);

      if (!_serialDevice.isLoading) {
        setSerialDevice(_serialDevice);
      }

      _onStateChangeRemoveListener = _serialDevice.onStateChange((state) => {
        if (state.isLoading) {
          return;
        }

        setSerialDevice(_serialDevice);
      });
    }

    requestDeviceFromContext().catch(console.error);

    return () => {
      _onStateChangeRemoveListener?.();
    };
  }, [deviceInfo]);

  return serialDevice;
};

type SerialProviderProps = {};

export const SerialProvider = ({ children }: PropsWithChildren<SerialProviderProps>) => {
  const _serialDevices = useRef(new Map<SerialDeviceInfo, SerialDevice>());

  const requestDevice = useCallback(async (info: SerialDeviceInfo) => {
    if (_serialDevices.current.has(info)) {
      return _serialDevices.current.get(info)!;
    }

    const serialDevice = new SerialDevice({ info });
    _serialDevices.current.set(info, serialDevice);

    return serialDevice;
  }, []);

  const serialContextValue: SerialContextValue = useMemo(
    () => ({
      requestDevice,
    }),
    []
  );

  return <SerialContext.Provider value={serialContextValue}>{children}</SerialContext.Provider>;
};
