import React from 'react';
import { Grid, Box } from '@mantine/core';
import { useParallelExecutionsStore } from '@/store/states/parallel-executions/store';
import QAExecutionCell from './QAExecutionCell';
import { getIndicesFromKey } from './grid';

const ParallelExecutionGrid: React.FC = () => {
    const executions = useParallelExecutionsStore(state => state.executions);
    const gridKeys = Object.keys(executions);
    // Determine the grid dimensions with a fallback to 1 if there are no executions
    const maxColumn = gridKeys.length > 0
        ? Math.max(...gridKeys.map(key => getIndicesFromKey(key).column))
        : 1;
    const maxRow = gridKeys.length > 0
        ? Math.max(...gridKeys.map(key => getIndicesFromKey(key).row))
        : 1;

    // Create a 2D array to represent the grid
    const grid = Array(maxRow).fill(null).map(() => Array(maxColumn).fill(null));

    // Place executions in the correct grid positions
    Object.entries(executions).forEach(([key, execution]) => {
        const { column, row } = getIndicesFromKey(key);
        // When the data is restored, the controller must be initialized to get the functions in the state.
        grid[row - 1][column - 1] = { key, execution };
    });

    // If there are no executions, display a message instead of an empty grid
    if (gridKeys.length === 0) {
        return <Box p="md">No executions available.</Box>;
    }

    return (
        <Box>
            {grid.map((row, rowIndex) => (
                <Grid key={rowIndex} gutter="md">
                    {row.map((cell, colIndex) => (
                        <Grid.Col key={`${rowIndex}-${colIndex}`} span={12 / maxColumn}>
                            {cell ? (
                                <QAExecutionCell
                                  executionKey={cell.key}
                                  execution={cell.execution} />
                            ) : (
                                <Box style={{ height: '100px', background: '#f0f0f0' }} />
                            )}
                        </Grid.Col>
                    ))}
                </Grid>
            ))}
        </Box>
    );
};

export default ParallelExecutionGrid;
