import '@mantine/core/styles.css';
import { MantineProvider } from '@mantine/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SerialProvider } from '@/utils/WebSerial/WebSerial';
import { Router } from './Router';
import { theme } from './theme';
import { CommandModal } from '@/components/CommandModal';
import { NumpadInput, NumpadInputContext } from '@/utils/KeyInput/NumpadCommand';
import '@/i18n/i18n';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 /*min*/ * 60_000,
    },
  },
});

export default function App() {
  const numpadInput = NumpadInput.use();

  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={theme} defaultColorScheme="dark">
        <SerialProvider>
          <NumpadInputContext.Provider value={numpadInput}>
            <ReactQueryDevtools initialIsOpen={false} />
            <CommandModal />
            <Router />
          </NumpadInputContext.Provider>
        </SerialProvider>
      </MantineProvider>
    </QueryClientProvider>
  );
}
