/// <reference types="w3c-web-usb" />

export namespace BQL {
  export const VendorID = 0x04f9; // Brother Industries, Ltd.

  export const ProductID = 0x2042; // QL-700

  export const ProductID_EditorMode = 0x2049; // QL-700 Editor mode

  type Pins = number;

  export type LabelInfo = {
    printWidth: Pins;
    rightMargin: Pins;
  };

  export enum MediaType {
    None,
    ContinuousTape,
    DieCutLabels,
  }

  export enum PaperType {
    Continuous = 0x0a,
  }

  export enum ValidFlag {
    PI_KIND = 0x02,
    PI_WIDTH = 0x04,
    PI_LENGTH = 0x08,
    PI_QUALITY = 0x40,
    PI_RECOVER = 0x80,
  }

  export type Media = {
    type: MediaType;
    width: number;
    length: number;
  };

  export enum StatusType {
    ReplyToStatusRequest,
    PrintingCompleted,
    ErrorOccurred,
    Notification,
    PhaseChange,
  }

  export interface Response {
    statusType: StatusType;
    media: Media;
    error: string[];
  }

  //continuous tape definition table (see page10, 3.2.5)
  export const Labels: { [labelSize: string]: BQL.LabelInfo } = {
    12: {
      printWidth: 106,
      rightMargin: 29,
    },
    29: {
      printWidth: 306,
      rightMargin: 6,
    },
    38: {
      printWidth: 413,
      rightMargin: 12,
    },
    50: {
      printWidth: 554,
      rightMargin: 12,
    },
    54: {
      printWidth: 590,
      rightMargin: 0,
    },
    62: {
      printWidth: 696,
      rightMargin: 12,
    },
  };

  export const Commands = {
    //Skip
    invalid: new Uint8Array([0x00]),

    clear: new Uint8Array(200),

    //Initialize mode settings. Also used to cancel printing.
    initialize: new Uint8Array([0x1b, 0x40]),

    //Status information request
    status: new Uint8Array([0x1b, 0x69, 0x53]),

    //Command mode switch -- nope?
    mode: new Uint8Array([0x1b, 0x69, 0x61, 1]),

    //set "Auto Cut"
    autoCut: new Uint8Array([0x1b, 0x69, 0x4d, 0x40]),

    //set "Cut at end flag"
    cutAtEnd: new Uint8Array([0x1b, 0x69, 0x4b, 0x08]),

    print: new Uint8Array([0x1a]),

    //Set margin amount (feed amount)
    setMargins: new Uint8Array([0x1b, 0x69, 0x64, 35, 0]),

    raster: (line: Uint8Array) => new Uint8Array([0x67, 0x00, 90, ...line]),

    media: (
      width: number,
      length: number,
      validFlags = ValidFlag.PI_KIND |
        ValidFlag.PI_LENGTH |
        ValidFlag.PI_QUALITY |
        ValidFlag.PI_RECOVER |
        ValidFlag.PI_WIDTH
    ) =>
      new Uint8Array([
        0x1b,
        0x69,
        0x7a,
        validFlags, //n1
        PaperType.Continuous, //n2
        width, //n3
        length, //n4
        0, //n5
        0, //n6
        0, //n7
        0, //n8
        0x01, //n9 - starting page
        0, //n10 - fixed to 0
      ]),
  };
}
export enum BarcodeType {
  QRCODE = 'qrcode',
  DATAMATRIX = 'datamatrix',
  MICROQRCODE = 'microqrcode',
  AZTECCODE = 'azteccode',
}
