// mockManager.ts
import {
  mockApi,
  mockBarcode,
  mockDataLoader,
  mockExecutionConfig,
  mockExecutionData,
  mockFab,
  mockPrinter,
  mockScanner,
  mockTester,
} from './mocks';

export interface MockState {
  executionData: ReturnType<typeof mockExecutionData>;
  config: ReturnType<typeof mockExecutionConfig>;
  scanner: ReturnType<typeof mockScanner>;
  printer: ReturnType<typeof mockPrinter>;
  barcode: ReturnType<typeof mockBarcode>;
  fab: ReturnType<typeof mockFab>;
  tester: ReturnType<typeof mockTester>;
  dataLoader: ReturnType<typeof mockDataLoader>;
  api: ReturnType<typeof mockApi>;
}

export class MockManager {
  private static instance: MockManager;
  private mockState: MockState;

  private constructor() {
    this.mockState = this.createInitialMockState();
  }

  public static getInstance(): MockManager {
    if (!MockManager.instance) {
      MockManager.instance = new MockManager();
    }
    return MockManager.instance;
  }

  private createInitialMockState(): MockState {
    return {
      executionData: mockExecutionData(),
      config: mockExecutionConfig(),
      scanner: mockScanner(),
      printer: mockPrinter(),
      barcode: mockBarcode(),
      fab: mockFab(),
      tester: mockTester(),
      dataLoader: mockDataLoader(),
      api: mockApi(),
    };
  }

  public getMockState(): MockState {
    return this.mockState;
  }

  public resetMockState(): void {
    this.mockState = this.createInitialMockState();
  }

  public updateMockState(partialState: Partial<MockState>): void {
    this.mockState = { ...this.mockState, ...partialState };
  }
}

export const mockManager = MockManager.getInstance();
