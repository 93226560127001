import Decimal from 'decimal.js';
import { IState } from '../../../interfaces';
import {
  IInputDataLoader,
  IInputStage,
  IInputStageData,
  IInputStagePayload,
} from '../../../interfaces/stages/input';
import { Logger } from '../../../../logger';
import {
  ExecutionState,
  PartIdentifier,
  SerialNumber,
  WorkbenchExecution,
} from '@/utils/api/fab.types';
import { ExecutionRecipeConfig } from '@/pages/debug/NewExe/types';
import { partIdEq } from '@/pages/debug/NewExe/util';
import { IBarcodeIO } from '@/store/interfaces/barcode';
import { createErrorHandler } from '@/store/utils/error-handler';

const logger = new Logger('InputStage');

const DATA_VERSION = 1;

interface IInputIO {
  barcode: IBarcodeIO;
}

interface InputStageParams {
  executionData: WorkbenchExecution;
  config: ExecutionRecipeConfig;
  loader: IInputDataLoader;
  state: IState<IInputStage>;
  io: IInputIO;
  onCompletion: () => void;
}

export async function createInputStage({
  executionData,
  config,
  loader,
  state,
  io,
  onCompletion,
}: InputStageParams): Promise<IInputStage> {
  logger.addMetadata({ executionId: executionData.id });
  logger.debug('Creating input stage', { executionId: executionData.id });
  const initialData = await loader.loadData(executionData, DATA_VERSION);
  const errorHandler = createErrorHandler(state, logger);

  const handleLotNumberScan = (partId: number, identifier: PartIdentifier): void => {
    logger.info('Handling lot number scan', { partId, identifier });
    const { data } = state.get();

    if (!isValidScan(partId, identifier, data)) {
      return;
    }

    errorHandler.removeError('scan');
    updatePartData(partId, identifier, config, io);
    checkIsCompleted();
  };

  const handleSerialNumberScan = (
    serialNumbers: SerialNumber[],
    identifier: PartIdentifier
  ): void => {
    logger.info('Handling serial number scan', { serialNumbers, identifier });
    const { data } = state.get();

    for (const serialNumber of serialNumbers) {
      const partId = serialNumber.part_id;

      if (isValidScan(partId, identifier, data)) {
        logger.info('Serial number scan processed successfully', { partId, identifier });
        updatePartData(partId, identifier, config, io);
        errorHandler.removeError('scan');
      } else {
        logger.warn('Invalid serial number scan detected', { partId, identifier });
      }
    }

    checkIsCompleted();
  };

  const isValidScan = (
    partId: number,
    identifier: PartIdentifier,
    data: IInputStageData
  ): boolean => {
    if (!(partId in data.parts)) {
      const errorMessage = 'Unknown part ID';
      logger.warn(errorMessage, { partId });
      errorHandler.addError('scan', errorMessage);
      return false;
    }

    const { loaded, serialized } = data.parts[partId];
    if (loaded.some((part) => partIdEq(part.identifier, identifier))) {
      const errorMessage = 'Duplicate scan detected';
      logger.warn(errorMessage, { partId, identifier });
      errorHandler.addError('scan', errorMessage);
      return false;
    }

    if (serialized && !identifier.serial_number) {
      const errorMessage = 'Missing serial number for serialized part';
      logger.warn(errorMessage, { partId, identifier });
      errorHandler.addError('scan', errorMessage);
      return false;
    }

    if (!serialized && !identifier.lot_number) {
      const errorMessage = 'Missing lot number for non-serialized part';
      logger.warn(errorMessage, { partId, identifier });
      errorHandler.addError('scan', errorMessage);
      return false;
    }

    return true;
  };

  const updatePartData = (
    partId: number,
    identifier: PartIdentifier,
    _config: ExecutionRecipeConfig,
    _io: IInputIO
  ): void => {
    logger.debug('Updating part data', { partId, identifier });
    state.set((prev) => {
      const part = prev.data.parts[partId];
      const qty = part.serialized ? new Decimal(1.0) : part.reqQty;

      updateLoadedParts(part, qty, identifier);

      if (identifier.lot_number) {
        persistLotNumber(partId, identifier.lot_number, _config, _io);
      }
    });
  };

  const updateLoadedParts = (
    part: IInputStageData['parts'][number],
    qty: Decimal,
    identifier: PartIdentifier
  ): void => {
    // default behavior is to just 'push' into the loaded array
    // and eliminate entries if total qty is too big.
    let totalQty = part.loaded.reduce((acc, loadedPart) => acc.add(loadedPart.qty), new Decimal(0));

    while (totalQty.add(qty).gt(part.reqQty) && part.loaded.length) {
      const { qty: removedQty } = part.loaded.shift()!;
      totalQty = totalQty.sub(removedQty);
      logger.debug('Removed excess part', {
        removedQty: removedQty.toString(),
        remainingQty: totalQty.toString(),
      });
    }

    part.loaded.push({ identifier, qty });
    logger.info('Loaded parts updated', {
      partId: part.partId,
      totalQty: totalQty.add(qty).toString(),
    });
  };

  const persistLotNumber = (
    partId: number,
    lotNumber: string,
    _config: ExecutionRecipeConfig,
    _io: IInputIO
  ): void => {
    const executions =
      _config.input?.[partId]?.lotNumberPersistence || _config.input?.lotNumberPersistence || 20;
    logger.debug('Persisting lot number', { partId, lotNumber, executions });
    _io.barcode.persistBarcode(partId, lotNumber, executions);
  };

  const getPayload = (): IInputStagePayload => {
    const { data } = state.get();
    const inputParts = Object.values(data.parts).flatMap((part) =>
      part.loaded.map(({ qty, identifier }) => ({
        part_id: part.partId,
        qty: qty.toString(),
        identifier,
      }))
    );

    logger.debug('Generated payload', { inputParts });
    return {
      name: ExecutionState.Input,
      input_parts: inputParts,
    };
  };

  const checkIsCompleted = (): void => {
    logger.info('Checking completion status');

    if (state.get().completed) {
      logger.warn('Stage already completed');
      return;
    }

    const { data } = state.get();
    const completed = isCompleted(data);
    logger.debug('Checking completion status', { completed });
    state.set((stage) => {
      stage.completed = completed;
    });

    if (completed) {
      logger.info('Input stage completed');
      onCompletion();
    }
  };

  const loadParts = (): void => {
    logger.debug('Loading parts');
    state.set(({ data }) => {
      Object.values(data.parts).forEach((part) => {
        if (part.serialized || part.loaded.length > 0) return;

        const lotNumber = io.barcode.requestLotNumber(part.partId);
        if (lotNumber) {
          part.loaded.push({
            qty: part.reqQty,
            identifier: { lot_number: lotNumber },
          });
          logger.debug('Loaded part from persisted lot number', { partId: part.partId, lotNumber });
        }
      });
    });
    logger.info('Parts loaded');
  };

  const onEnter = (): void => {
    logger.info('Entering input stage');
    loadParts();
    checkIsCompleted();
  };

  const onExit = (): void => {
    logger.info('Exiting input stage');
  };

  const onPostExit = (): void => {
    logger.info('Post-exit of input stage');
    const { data } = state.get();
    const lotNumbersToDecrement = Object.values(data.parts)
      .filter((part) => !part.serialized)
      .flatMap((part) => part.loaded.map((loaded) => loaded.identifier.lot_number!));

    logger.debug('Decrementing lot numbers', { lotNumbersToDecrement });
    io.barcode.decrementBarcodes(lotNumbersToDecrement);
    logger.info('Lot numbers decremented');
  };

  return {
    type: ExecutionState.Input,
    completed: false,
    errors: {},
    data: initialData,
    getPayload,
    handleLotNumberScan,
    handleSerialNumberScan,
    onEnter,
    onExit,
    onPostExit,
  };
}

function isCompleted(data: IInputStageData): boolean {
  return Object.values(data.parts).every(({ partId }) => getLoadedInputStatus(partId, data)[0]);
}

export function getLoadedInputStatus(partId: number, data: IInputStageData): [boolean, string] {
  if (!(partId in data.parts)) {
    return [false, 'unknown part id'];
  }

  const { serialized, reqQty, loaded } = data.parts[partId];

  const totalQty = loaded.reduce((acc, part) => acc.add(part.qty), new Decimal(0));

  if (!totalQty.eq(reqQty)) {
    return [false, 'req. qty not met'];
  }

  const hasValidIdentifiers = loaded.every((part) =>
    serialized ? !!part.identifier.serial_number : !!part.identifier.lot_number
  );

  if (!hasValidIdentifiers) {
    return [false, serialized ? 'missing serial number' : 'missing lot number'];
  }

  return [true, 'ok'];
}
