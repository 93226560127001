import type { ExecutionRenderStore } from '@/pages/debug/NewExe/ExecutionRender';
import { type ExecutionRecipeConfig } from '@/pages/debug/NewExe/types';
import { log } from '@/utils/log';
import { jsonDeserialize, jsonSerialize } from '../util';

const DATA_VERSION = 1;
type Data = {
  recipeId: number;
  version: number;
  //todo: maybe a better option would be to save all execution.ids and use .length?
  executedCounter: number;
};

const STORAGE_KEY = 'exe.finished';

type ExecutionFinishedProps = {
  data: Data;
  config: ExecutionRecipeConfig;
  store: ExecutionRenderStore;
};

type ExecutionFinishedLoadProps = {
  recipeId: number;
  config: ExecutionRecipeConfig;
  store: ExecutionRenderStore;
};

export class ExecutionFinished {
  data: Data;
  config: ExecutionRecipeConfig;
  store: ExecutionRenderStore;

  constructor(props: ExecutionFinishedProps) {
    const { data, config, store } = props;
    this.data = data;
    this.store = store;
    this.config = config;
  }

  private saveData() {
    if (!this.data) {
      return;
    }
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(this.data, jsonSerialize));
  }

  private static loadDataFromStorage(recipeId: number): Data | null {
    const serializedData = sessionStorage.getItem(STORAGE_KEY);
    if (!serializedData) {
      return null;
    }

    try {
      const data = JSON.parse(serializedData, jsonDeserialize) as Data;
      if (data.recipeId !== recipeId || data.version !== DATA_VERSION) {
        return null;
      }
      return data;
    } catch (_) {
      return null;
    }
  }

  static async load(props: ExecutionFinishedLoadProps): Promise<ExecutionFinished> {
    const { recipeId, config, store } = props;
    const storageData = ExecutionFinished.loadDataFromStorage(recipeId);
    if (storageData) {
      return new ExecutionFinished({ data: storageData, config, store });
    }

    const obj = new ExecutionFinished({
      data: {
        executedCounter: 0,
        recipeId,
        version: DATA_VERSION,
      },
      config,
      store,
    });
    obj.saveData();

    return obj;
  }

  async onEnter() {
    log.info('ExecutionFinished::onEnter()');
    this.data.executedCounter += 1;

    const { restartCounterOn = Infinity } = this.config.finished ?? {};
    if (this.data.executedCounter >= restartCounterOn) {
      log.info('ExecutionFinished::onEnter() - limit reached, restarting counter..');
      this.data.executedCounter = 0;
    }

    this.saveData();
  }
}
