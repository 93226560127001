import elliptic from 'elliptic';
import { SigCard } from '@/utils/CardProg/SigCard';
import { hexToBytes } from '@/utils/CardProg/utils';

// eslint-disable-next-line new-cap
const ec = new elliptic.ec('secp256k1');

export class Sigs {
  private rawHmacKey = Uint8Array.from([
    0xb5, 0x8d, 0x77, 0x85, 0x11, 0x8a, 0x17, 0x4e, 0xf9, 0x1d, 0x4e, 0x64, 0x62, 0xbf, 0xf8, 0x12,
    0xeb, 0xd6, 0xd2, 0x32, 0x57, 0xc0, 0x05, 0xa9, 0x35, 0x40, 0x4b, 0x27, 0xd9, 0xbe, 0x2d, 0xde,
  ]);

  private rawEcdsaPrivateKey = Uint8Array.from([
    0x24, 0xef, 0x99, 0x00, 0x4c, 0x06, 0x8a, 0x33, 0xb5, 0xd1, 0xc6, 0xf8, 0x39, 0xeb, 0x11, 0x63,
    0xe7, 0xd7, 0xf1, 0xf1, 0xd4, 0xc5, 0x1f, 0xbc, 0xcd, 0xa7, 0x60, 0xac, 0xa3, 0xeb, 0x9e, 0x15,
  ]);

  private ecKey: elliptic.ec.KeyPair;
  private hmacKey: CryptoKey | null = null;

  private hmacAlgorithm = { name: 'HMAC', hash: 'SHA-256' };

  constructor() {
    this.ecKey = ec.keyPair({
      // @ts-ignore
      priv: this.rawEcdsaPrivateKey,
    });

    this.initHmacKey().then((key) => {
      this.hmacKey = key;
    });
  }

  public getEcSignature(msg: Uint8Array) {
    return this.ecKey.sign(msg);
  }

  private async initHmacKey() {
    if (this.hmacKey) {
      return this.hmacKey;
    }

    this.hmacKey = await window.crypto.subtle.importKey(
      'raw',
      this.rawHmacKey,
      this.hmacAlgorithm,
      false,
      ['sign', 'verify']
    );

    return this.hmacKey;
  }

  public async getHmacSignature(hexString: string) {
    if (!this.hmacKey) {
      this.hmacKey = await this.initHmacKey();
    }

    const data = hexToBytes(hexString);
    const signature = await crypto.subtle.sign(this.hmacAlgorithm.name, this.hmacKey, data);

    return new Uint8Array(signature);
  }

  public async getSha256(msgBuffer: Uint8Array) {
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    return new Uint8Array(hashBuffer);
  }

  public async checkSignatures(card: SigCard, signatures: { hmac: string; ecdsa: string }) {
    const { hmac, ecdsa } = signatures;
    const msg = card.getSignature();
    const msgHash = await this.getSha256(msg);

    const [isEcdsaOk, isHmacOk] = await Promise.all([
      this.verifyEcdsa(msgHash, hexToBytes(ecdsa)),
      this.verifyHmac(msg, hexToBytes(hmac)),
    ]);

    return { isEcdsaOk, isHmacOk };
  }

  public async verifyHmac(msg: Uint8Array, hmac: Uint8Array) {
    try {
      this.hmacKey = await this.initHmacKey();

      return await window.crypto.subtle.verify(this.hmacAlgorithm, this.hmacKey, hmac, msg);
    } catch (e) {
      console.error('verifyHmac:failed| [%o -- %o] \n%o', msg, hmac, e);
    }
    return false;
  }

  public async verifyEcdsa(msg: Uint8Array, ecdsa: Uint8Array) {
    try {
      return this.ecKey.verify(msg, ecdsa);
    } catch (e) {
      console.error('verifyEcdsa:failed| [%o -- %o] \n%o', msg, ecdsa, e);
    }

    return false;
  }
}
