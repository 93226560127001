import {
  Accordion,
  Button,
  Card,
  Code,
  Container,
  Group,
  Stack,
  Switch,
  Text,
} from '@mantine/core';
import { useId } from '@mantine/hooks';
import { QRCodeSVG } from 'qrcode.react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { type ActionFunctionArgs, redirect, useFetcher, useLocation } from 'react-router-dom';
import { useNamedCommand } from '@/utils/KeyInput/NumpadCommand';
import { useFabLocalStorage } from '@/utils/hooks';
import { StationName } from '@/pages/Config/StationName';
import { appState } from '@/utils/AppState/AppState';
import { useBrotherQLEnsurePermissions } from '@/utils/BrotherQL/hooks';
import { TestPrintButton } from '@/utils/BrotherQL/TestPrintButton';
import { CardProgWebSerialConfig } from '@/utils/CardProg/config';
import { uniq } from '@/utils/fns';
import { useKeydownEvents } from '@/utils/KeyInput/KeydownEvents';
import { FalconWebSerialConfig } from '@/utils/LaserEngraver/falconConfig';
import { useSerial } from '@/utils/WebSerial/WebSerial';
import classes from './styles.module.css';

export enum DeviceType {
  Numpad = 'NUMPAD',
  CodeReader = 'CODE_READER',
  LabelPrinter = 'LABEL_PRINTER',
  LaserEngraver = 'LASER_ENGRAVER',
  CardProg = 'CARD_PROG',
}

export enum SupportedDevices {
  A4TechNumpad = 'A4TECH_NUMPAD',
  MetterBarcodeReader = 'METTER_BARCODE_READER',
  BrotherQL700 = 'BROTHER_QL700',
  CardProg = 'PERSIDIUS_LMB_PROG',
}

const supportedDevices = [
  {
    type: DeviceType.Numpad,
    title: DeviceType.Numpad,
    description: 'Usb attached numpad keyboard',
    devices: [SupportedDevices.A4TechNumpad],
  },
  {
    type: DeviceType.CodeReader,
    title: 'QR / Barcode Scanner',
    description: 'Usb attached QR / Barcode Scanner',
    devices: [SupportedDevices.MetterBarcodeReader],
  },
  {
    type: DeviceType.LabelPrinter,
    title: DeviceType.LabelPrinter,
    description: 'Usb attached "Brother QL-700" printer',
    devices: [SupportedDevices.BrotherQL700],
  },
  {
    type: DeviceType.LaserEngraver,
    title: DeviceType.LaserEngraver,
    description: 'Usb attached "Creality Falcon 2"',
    devices: [],
  },
  {
    type: DeviceType.CardProg,
    title: DeviceType.CardProg,
    description: 'Usb attached "Card Programmer"',
    devices: [SupportedDevices.CardProg],
  },
];

export function getDeviceTypes(devices: SupportedDevices[]): DeviceType[] {
  try {
    return uniq(
      devices.map((device) => supportedDevices.find((sp) => sp.devices.includes(device))!.type)
    );
  } catch (e) {
    console.error('getDeviceTypes', e);
    return [];
  }
}

export function ConfigPage() {
  const location = useLocation();
  const fetcher = useFetcher();
  // const actionData = useActionData() as Awaited<ReturnType<typeof configAction>> | null;
  const brotherQLDevice = useBrotherQLEnsurePermissions();
  const engraverFalcon = useSerial(FalconWebSerialConfig);
  const [enabledDevices, setEnabledDevices] = useFabLocalStorage<SupportedDevices[]>(
    'enabled-devices',
    []
  );
  const [formSelectedDevices, setFormSelectedDevices] = useState<string[]>(
    getDeviceTypes(enabledDevices)
  );
  useNamedCommand('FullPageRefresh', () => window.location.reload());

  const params = new URLSearchParams(location.search);
  const from = params.get('from') || '/';

  useEffect(() => {
    setFormSelectedDevices((d) => uniq(d.concat(getDeviceTypes(enabledDevices))));
  }, [enabledDevices]);

  useEffect(() => {
    console.debug('mounted config! brother =>', brotherQLDevice);
    console.debug('mounted config! serial => ', engraverFalcon);

    const r = engraverFalcon?.subscribe((message) => {
      console.debug('serial.subscribe -> message', message);
    });

    return () => {
      r?.();
    };
  }, []);

  const onDeviceEnabled = useCallback(
    (value: string[]) =>
      setEnabledDevices((prevState) => {
        const val = value as SupportedDevices[];
        console.debug(prevState, val);
        const newValue = val.filter((v) => !prevState.includes(v));
        console.debug('newValue', newValue);

        if (newValue[0] === 'BROTHER_QL700') {
          brotherQLDevice.requestPermissions();
        }

        return val;
      }),
    []
  );

  const setConfigured = () => {
    //todo: some validation here
    fetcher.submit({ configured: 'true', redirectTo: from }, { method: 'patch' });
  };

  // const sendCommand = () => {
  //   engraverFalcon?.write('\x18\n').catch((e) => console.error(e));
  // };
  // const sendCommand2 = () => {
  //   engraverFalcon?.write('G0 X0 Y0\n').catch((e) => console.error(e));
  // };

  ///
  const items = supportedDevices.map((device) => (
    <Accordion.Item key={device.type} value={device.type}>
      <Accordion.Control>
        <Stack gap={0}>
          <Text>{device.title}</Text>
          <Text size="sm" c="dimmed">
            {device.description}
          </Text>
        </Stack>
      </Accordion.Control>
      <Accordion.Panel>
        {device.devices.map((d) => (
          <Stack key={d}>
            <Group justify="space-between" className={classes.item} wrap="nowrap">
              <Text>{d}</Text>

              <Switch value={d} onLabel="ON" offLabel="OFF" className={classes.switch} size="lg" />
            </Group>
            <DeviceDetails device={d} />
          </Stack>
        ))}
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <Container mt="lg">
      <Card withBorder radius="md" p="xl" className={classes.card}>
        <Text fz="h1" className={classes.title} fw={500}>
          Configurations Page
        </Text>
        <Text fz="h5" c="dimmed" mt={3} mb="xl">
          This page is designed to be filled out only once during setup, but you're welcome to
          revisit it manually anytime by accessing '/config'.
        </Text>

        <StationName />

        <Switch.Group value={enabledDevices} onChange={onDeviceEnabled}>
          <Accordion
            multiple
            value={formSelectedDevices}
            onChange={setFormSelectedDevices}
            variant="separated"
          >
            {items}
          </Accordion>
        </Switch.Group>

        <Group my="xl">
          <Button onClick={setConfigured} color="blue">
            OK, I'm done
          </Button>
        </Group>
      </Card>
      {/*
      <h3>Config Page //TODO</h3>
      {actionData && actionData.error ? <p style={{ color: 'red' }}>{actionData.error}</p> : null}

      <p>
        Brother QL-700:{' '}
        {brotherQLDevice.status
          ? 'ok' in brotherQLDevice.status
            ? brotherQLDevice.status.serialNumber
            : brotherQLDevice.status.error
          : 'unknown error'}
      </p>

      <button type="button" onClick={setConfigured}>
        Start configuration process
      </button>
      <button type="button" onClick={sendCommand}>
        SERIAL: Send command
      </button>
      <button type="button" onClick={sendCommand2}>
        SERIAL: Send command2
      </button>*/}
    </Container>
  );
}

type DeviceDetailsProps = {
  device: SupportedDevices;
};

export function DeviceDetails(props: DeviceDetailsProps) {
  const { device } = props;
  const ref = useRef({ listenForInitCommand: false, listenForQrCode: false });
  const qrId = useId('DEVICE_DETAILS_QR_CUSTOM_ID');
  const brotherQLDevice = useBrotherQLEnsurePermissions();
  const cardProgSerial = useSerial(CardProgWebSerialConfig);

  const [enabledDevices] = useFabLocalStorage<SupportedDevices[]>('enabled-devices', []);

  const [configuredDevices, setConfiguredDevices] = useFabLocalStorage<SupportedDevices[]>(
    'configured-devices',
    []
  );

  // FIXME: this was removed when numpad control was refactored
  const onNumpadRead = useCallback(([code1, code2]: string[]) => {
    if (!ref.current.listenForInitCommand) {
      return;
    }

    if (code1 === 'Numpad0' && code2 === 'Numpad0') {
      setConfiguredDevices((prevState) => uniq(prevState.concat(device)));
    }
  }, []);

  const onBarcodeRead = useCallback((code: string) => {
    console.debug('onBarcodeRead', code, qrId);
    if (!ref.current.listenForQrCode) {
      return;
    }

    if (code === qrId) {
      setConfiguredDevices((prevState) => uniq(prevState.concat(device)));
    }
  }, []);

  useKeydownEvents({ onBarcodeRead });

  const isConfigured = configuredDevices.includes(device);
  const isCardProgOk = cardProgSerial?.portState === 'open' && !cardProgSerial?.isLoading;

  useEffect(() => {
    if (device !== SupportedDevices.CardProg) {
      return;
    }

    if (isCardProgOk && !isConfigured) {
      setConfiguredDevices((prevState) => uniq(prevState.concat(device)));
    }

    if (isConfigured && !isCardProgOk) {
      setConfiguredDevices((prevState) => uniq(prevState.filter((x) => x !== device)));
    }
  }, [isCardProgOk, isConfigured]);

  if (!enabledDevices.includes(device)) {
    return null;
  }

  switch (device) {
    case SupportedDevices.A4TechNumpad: {
      ref.current.listenForInitCommand = !isConfigured;

      const status = [`isConfigured: ${isConfigured}`];
      if (ref.current.listenForInitCommand) {
        status.push('listening for "00" event to confirm configuration...');
      }

      return (
        <>
          <Code block>{status.join('\n')}</Code>
        </>
      );
    }

    case SupportedDevices.MetterBarcodeReader: {
      ref.current.listenForQrCode = !isConfigured;

      const status = [`isConfigured: ${isConfigured}`];
      if (ref.current.listenForQrCode) {
        status.push('please scan the nearby QR code...');
      }

      return ref.current.listenForQrCode ? (
        <Group grow>
          <Code block>{status.join('\n')}</Code>

          <QRCodeSVG value={qrId} level="M" />
        </Group>
      ) : (
        <Code block>{status.join('\n')}</Code>
      );
    }

    case SupportedDevices.BrotherQL700: {
      console.debug('SupportedDevices.BrotherQL700', brotherQLDevice);
      const status = [`isConfigured: ${isConfigured}`];
      const qlStatus = brotherQLDevice.status;
      let showUsbConnectButton = false;
      let showTestPrintButton = false;

      if ('error' in qlStatus) {
        status.push(qlStatus.error);

        if (
          qlStatus.error.includes('show a permission request') ||
          qlStatus.error.includes('No device selected')
        ) {
          ///Failed to execute 'requestDevice' on 'USB': Must be handling a user gesture to show a permission request.
          //Failed to execute 'requestDevice' on 'USB': No device selected.
          showUsbConnectButton = true;
        }
      } else {
        status.push(`serialNumber: ${qlStatus.serialNumber}`);
        showTestPrintButton = true;
      }

      return (
        <Stack>
          <Code block>{status.join('\n')}</Code>
          <div>
            {showUsbConnectButton ? (
              <Button variant="light" color="red" onClick={brotherQLDevice.requestPermissions}>
                Please enable permissions for QL-700
              </Button>
            ) : null}
            {showTestPrintButton ? <TestPrintButton /> : null}
          </div>
        </Stack>
      );
    }

    case SupportedDevices.CardProg: {
      console.debug('SupportedDevices.CardProg', cardProgSerial);
      const status = [`isConfigured: ${isConfigured}`];
      let showUsbConnectButton = false;
      let requestPermission = () => {};

      if (!cardProgSerial) {
        status.push('Status: is starting up..');
      } else {
        status.push(`PortState: ${cardProgSerial.portState}`);
        status.push(`isLoading: ${cardProgSerial.isLoading}`);

        status.push(`\nisOk: ${isCardProgOk}`);

        if (!isCardProgOk) {
          showUsbConnectButton = true;
          requestPermission = async () => {
            cardProgSerial
              .manualConnectToPort()
              .then((r) => {
                if (r) {
                  setConfiguredDevices((prevState) => uniq(prevState.concat(device)));
                }
              })
              .catch((e) => console.warn('manualConnectToPort ', e));
          };
        }
      }

      return (
        <Stack>
          <Code block>{status.join('\n')}</Code>
          <div>
            {showUsbConnectButton ? (
              <Button variant="light" color="red" onClick={requestPermission}>
                Please enable permissions for {device}
              </Button>
            ) : null}
          </div>
        </Stack>
      );
    }
  }

  return (
    <>
      <Code block>unimplemented</Code>
    </>
  );
}

/////

export async function configAction(args: ActionFunctionArgs) {
  const { request } = args;
  const formData = await request.formData();
  const configured = (formData.get('configured') as string | null) === 'true';

  try {
    await appState.setConfigured(configured);
  } catch (error) {
    return {
      error: 'Error configuring config',
    };
  }

  console.info('[config.action] redirecting to "/"');
  return redirect('/');
}
