import { mockManager } from '../../../mocks';
import { createInputStage } from '../stages/input/init';
import { createOutputStage } from '../stages/output/init';
import { createCardProgStage } from '../stages/cardprog/init';
import { Logger } from '@/logger';
import { ISingleExecutionDependencies } from '@/store/interfaces/dependencies';
import { createScanner } from './scanner';
import { createPrinter } from './printer';
import { createApi } from './api';
import { createFab } from './fab';
import { createTester } from './tester';
import { createQaStage } from '../stages/qa/init';

const logger = new Logger('ControllerDependencies');

interface CreateDependenciesParams {
  mockEnabled?: boolean;
}

export const createDependencies = (
  args?: CreateDependenciesParams
): ISingleExecutionDependencies => {
  const mockEnabled = args?.mockEnabled;
  if (mockEnabled) {
    logger.info('Mock dependencies enabled');
    return createMockDependencies();
  }

  logger.info('Production dependencies enabled');
  return createProductionDependencies();
};

function createMockDependencies(): ISingleExecutionDependencies {
  const mockState = mockManager.getMockState();
  return {
    io: {
      scanner: mockState.scanner,
      printer: mockState.printer,
      fab: {
        cardprog: mockState.fab.cardprog,
      },
      tester: mockState.tester,
    },
    api: mockState.api,
    stageFactory: {
      createInputStage,
      createOutputStage,
      createCardProgStage,
      createQaStage,
    },
  };
}

function createProductionDependencies(): ISingleExecutionDependencies {
  return {
    io: {
      scanner: createScanner(),
      printer: createPrinter(),
      fab: createFab(),
      tester: createTester(),
    },
    api: createApi(),
    stageFactory: {
      createInputStage,
      createOutputStage,
      createCardProgStage,
      createQaStage,
    },
  };
}
