type Nullable<T> = T | null | undefined;
// type Arrayable<T> = T | Array<T>;
export type Decimal = string;
export type DateTime = string;

export interface Part {
  id: number;
  created_at: DateTime;
  mfr_part_number: Nullable<string>;
  mfr_name: Nullable<string>;
  description: Nullable<string>;
  description_ro: Nullable<string>;
  deprecated: boolean;
  serialized: boolean;
  taric_number: Nullable<string>;
  hs_number: Nullable<string>;
  catalog: Catalog;
}

export interface PartWithSuppliers extends Part {
  suppliers: PartSupplier[];
}

export interface PartEdit {
  mfr_part_number?: Nullable<string>;
  mfr_name?: Nullable<string>;
  description?: Nullable<string>;
  description_ro?: Nullable<string>;
  taric_number?: Nullable<string>;
  hs_number?: Nullable<string>;
  suppliers: PartSupplierEdit[];
  deprecated: boolean;
}

export interface PartSupplierEdit {
  supplier_id: number;
  part_id: number;
  supplier_part_number?: Nullable<string>;
  moq: Decimal;
  multiple: Decimal;
}

export interface PartSupplier {
  supplier_id: number;
  part_id: number;
  supplier_part_number: Nullable<string>;
  moq: Decimal; // default 1.00
  multiple: Decimal; // default 1.00
}

export interface Supplier {
  id: number;
  name: string;
  notes: Nullable<string>;
  active: boolean;
  tag: Nullable<string>;
}

export interface SupplierWithParts extends Supplier {
  parts: PartSupplier[];
}

export interface SupplierUpdate {
  name: string;
  notes: Nullable<string>;
  active: boolean;
}

export interface SupplierCreate {
  name: string;
  notes: Nullable<string>;
}

export enum PurchaseOrderStatus {
  Planned = 'Planned',
  Ordered = 'Ordered',
  PartiallyFilled = 'PartiallyFilled',
  Filled = 'Filled',
  Cancelled = 'Cancelled',
}

export interface PurchaseOrder {
  id: number;
  created_at: DateTime;
  comments: Nullable<string>;
  status: PurchaseOrderStatus;
  supplier_id: number;
  supplier_po_number: Nullable<string>;
  stock_id: number;
  parts: POParts[];
}

export interface POParts {
  id: number;
  estimated_lead_time: DateTime;
  purchase_order_id: number;
  part_id: number;
  qty: Decimal;
}

export interface PurchaseOrderWithDeliveries extends PurchaseOrder {
  deliveries: PODeliveries[];
}

export interface PODeliveries {
  id: number;
  created_at: DateTime;
  delivered_at: DateTime;
  comments: string;
  purchase_order_id: number;
  parts: PODeliveriesParts[];
}

export interface PODeliveriesParts {
  purchase_order_delivery_id: number;
  part_id: number;
  qty: Decimal;
  lot_number: string;
}

export interface PurchaseOrderEdit {
  status: Nullable<PurchaseOrderStatus>;
  supplier_po_number: Nullable<string>;
  parts: Nullable<POEditPart[]>;
}

// when editing an existing line item - you specify ID and ignore part_id
// when adding a new line -> you specify part_id and ignore ID
// when deleting a line -> specify ID but set QTY as zero.
export interface POEditPart {
  /// ID of the original item in the PO (before editing)
  id?: Nullable<number>;
  part_id?: Nullable<number>;
  estimated_lead_time?: Nullable<DateTime>;
  qty: Decimal;
}

export interface PurchaseOrderCreate {
  supplier_id: number;
  parts: POCreateParts[];
  created_at?: Nullable<DateTime>;
  comments?: Nullable<string>;
  supplier_po_number?: Nullable<string>;
  stock_id?: Nullable<number>;
}

export interface POCreateParts {
  estimated_lead_time?: Nullable<DateTime>;
  part_id: number;
  qty: Decimal;
}

export interface POCreateDelivery {
  delivered_on: DateTime;
  comments?: Nullable<string>;
  parts: POCreateDeliveryPart[];
}

export interface POCreateDeliveryPart {
  part_id: number;
  qty: Decimal;
}

export interface Catalog {
  part_id: number;
  cat_id: string;
  subcat_id: string;
  catalog_number: string;
  name: string;
  name_ro?: Nullable<string>;
  deprecated: boolean;
}

export interface Stock {
  id: number;
  name: string;
  location: string;
  reserved: boolean;
  reserved_stocks: StockReserved[];
}

export interface StockReserved extends Omit<Stock, 'reserved_stocks'> {
  parent_stock_id: number;
}

export type StockWithInventoryReservedStock = StockReserved & {
  inventory: StockReservedInventory[];
};
export interface StockWithInventory extends Omit<Stock, 'reserved_stocks'> {
  inventory: StockInventory[];
  reserved_stocks: StockWithInventoryReservedStock[];
}

export interface StockInventory {
  part_id: number;
  total_qty: Decimal;
  free_qty: Decimal;
  reserved_qty: Decimal;
  last_update: DateTime;
}

export interface StockHistory {
  stock_id: number;
  part_id: number;
  qty: Decimal;
  created_at: DateTime;
  delivery_id: Nullable<number>;
}

export interface StockReservedInventory {
  part_id: number;
  total_qty: Decimal;
  last_update: DateTime;
}

export interface Recipe {
  id: number;
  name: string;
  output_part_id: number;
  output_part_qty: Decimal;
  deprecated: boolean;
  inputs: RecipeInput[];
}

export interface RecipeInput {
  part_id: number;
  part_qty: Decimal;
}

export interface RecipeDeprecate {
  deprecated: boolean;
}

export interface RecipeCreate {
  output_part_id: number;
  output_part_qty: Decimal;
  name: string;
  inputs: Array<RecipeCreateInput>;
}

export interface RecipeCreateInput {
  part_id: number;
  part_qty: Decimal;
}

export interface Cat {
  id: string;
  name: string;
  name_ro: string;
  description?: Nullable<string>;
  description_ro?: Nullable<string>;
  active: boolean;
  shade: string;
}

export interface Subcat {
  id: string;
  catalog_cat_id: string;
  name: string;
  name_ro: string;
  description: any;
  description_ro: any;
  active: boolean;
}

export interface CreateUserAuthorization {
  id: number;
  key: string;
}

export interface UserAuthorization extends User {
  token: string;
}

export interface User {
  active: boolean;
  id: number;
  name: string;
  roles: UserRoles[];
}
export enum UserRoles {
  Admin = 'ADMIN',
}

export interface WorkbenchesCreateKeyResponse {
  key: string;
}

export interface GenericStateData {
  name:
    | ExecutionState.Qa
    | ExecutionState.Cancelled
    | ExecutionState.Finished
    | ExecutionState.Input
    | ExecutionState.Output;
}
export interface CardProgStateData {
  name: ExecutionState.CardProg;
  card_id: string;
  card_uid?: Nullable<string>;
  card_noa_id?: Nullable<string>;
  box_serial_number?: Nullable<string>;
}

export type ExecutionStateData = GenericStateData | CardProgStateData;

export interface WorkbenchExecution {
  id: number;
  created_at: DateTime;
  finished_at: DateTime | null;
  assigned_at: DateTime;
  assigned_to: number;
  workbench_id: number;
  work_order_kind: WorkOrderKind;
  work_order_id: number;
  state: ExecutionState;
  state_data: ExecutionStateData;
  next_states: ExecutionState[];
  recipe?: WorkbenchExecutionRecipe;
}

export interface WorkbenchExecutionRecipe extends Recipe {
  parallelism: number;
  work_order_kind: WorkOrderKind;
}

export enum WorkOrderKind {
  Default = 'DEFAULT',
  CardProg = 'CARD_PROG',
  Qa = 'QA',
  //   LaserEngrave = 'LASER_ENGRAVE',
}

export enum ExecutionState {
  Input = 'INPUT',
  CardProg = 'CARD_PROG',
  Output = 'OUTPUT',
  Qa = 'QA', //todo
  Finished = 'FINISHED',
  Cancelled = 'CANCELLED',
}

export type ExecutionAdvanceParams<T = ExecutionInputBody> = {
  id: string;
  nextState: ExecutionState;
  body: T;
};

export type ExecutionInputBody = {
  name: ExecutionState;
  input_parts: ExecutionInputPart[];
};

export type ExecutionOutputBody = {
  name: ExecutionState.Output;
  part_id: number;
  artefacts: number[];
  parts: Omit<ExecutionInputPart, 'part_id'>[];
};

export type CardProgAdvanceBody = {
  name: ExecutionState.CardProg;
  card_noa_id: string;
  card_uid: string;
  box_serial_number: string;
};

export type ExecutionBody = ExecutionInputBody | ExecutionOutputBody | CardProgAdvanceBody;

export type ExecutionInputPart = {
  part_id: number;
  qty: Decimal;
  identifier: PartIdentifier;
};

export type PartIdentifier = {
  lot_number?: Nullable<string>;
  serial_number?: Nullable<string>;
};

export type ExecutionStateData2 = {
  [ExecutionState.Input]: {};
  [ExecutionState.Output]: {};
  [ExecutionState.Finished]: {};
  [ExecutionState.Cancelled]: {};
  [ExecutionState.CardProg]: {
    card_id: string;
    card_uid?: Nullable<string>;
    card_noa_id?: Nullable<string>;
    box_serial_number?: Nullable<string>;
  };
};

export interface FabUploadRequest {
  body: Uint8Array;
  contentType: UploadContentType;
  filename: string;
}

export enum UploadContentType {
  text = 'text/plain',
  json = 'application/json',
}

export interface Artefact {
  id: number;
  created_at: DateTime;
  size: number;
  name: string;
  content_type: UploadContentType;
  created_by: number;
}

export interface SerialNumber {
  created_at: DateTime;
  id: number;
  part_id: number;
  serial_number: string;
}

export interface SerialNumberCreate {
  part_id: number;
}

export interface Lot {
  id: number;
  created_at: DateTime;
  part_id: number;
  delivery_part_id: Nullable<number>;
}

export type CardBoxContents = Array<CardData>;

export type CardData = {
  card_id: string;
  card_uid: Nullable<string>;
  noa_id: Nullable<string>;
  box_serial_number: string;
};
