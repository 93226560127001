import { useCallback, useRef } from 'react';
import { ISingleExecutionDependencies } from '@/store/interfaces/dependencies';
import { createDependencies } from '../../dependencies';
import { StorageKeys, useFabLocalStorage } from '@/utils/hooks';
import { useParallelExecutionsStore } from '../store';
import { Logger } from '@/logger';

const logger = new Logger('UseParallelExecution');

interface IUseParallelExecutions {
  (params: { gridKeys: string[] }): {
    startExecution: (executionKey: string) => void;
    focusExecution: (executionKey: string) => void;
  };
}

export const useParallelExecution: IUseParallelExecutions = ({ gridKeys }) => {
  const store = useParallelExecutionsStore();

  console.log('useParallelExecution', store);

  const isInitializedRef = useRef(false);
  const [workbenchIdNum] = useFabLocalStorage(StorageKeys.WorkbenchId, '');
  const workbenchId = workbenchIdNum.toString();

  if (!isInitializedRef.current) {
    isInitializedRef.current = true;

    try {
      console.log('Initializing grid');
      const dependencies = getDependencies();

      store.initializeGrid({ gridKeys, workbenchId, dependencies });
    } catch (err) {
      isInitializedRef.current = false;
    }
  }

  const startExecution = useCallback((executionKey: string) => {
    logger.info('Starting execution', executionKey);
    const _store = useParallelExecutionsStore.getState();
    const dependencies = getDependencies();
    _store.startExecution({ executionKey, workbenchId, dependencies });
  }, []);

  const focusExecution = useCallback((executionKey: string) => {
    logger.info('Focusing execution', executionKey);
    const _store = useParallelExecutionsStore.getState();
    _store.focusExecution({ executionKey });
  }, []);

  return { startExecution, focusExecution };
};

let dependencies: ISingleExecutionDependencies | null = null;

function getDependencies() {
  // TODO: use prod dependencies
  //   const mockEnabled = Config.isTestMode && Config.isMockEnabled;
  if (!dependencies) {
    dependencies = createDependencies({ mockEnabled: true });
  }
  return dependencies;
}
