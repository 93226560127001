import { isStringWithLength } from '@/utils/fns';

interface AppState {
  _isConfigured: boolean;
  isConfigured: () => Promise<boolean>;
  setConfigured: (value: boolean) => Promise<boolean>;
}

const storageKey = 'app.isConfigured';
export const appState: AppState = {
  _isConfigured: localStorage.getItem(storageKey) === 'true',
  async isConfigured() {
    //todo: check permissions/configurations and stuff
    const wbId = localStorage.getItem('wb.id');

    return Promise.resolve(isStringWithLength(wbId));
  },

  async setConfigured(value: boolean) {
    localStorage.setItem(storageKey, `${value}`);
    appState._isConfigured = value;
    return Promise.resolve(value);
  },
};
