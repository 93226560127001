import { SigCard } from '@/utils/CardProg/SigCard';
import { isValidHex } from '@/utils/CardProg/utils';
import { isString } from '@/utils/fns';
import { SerialDevice } from '@/utils/WebSerial/SerialDevice';

export class CardProg {
  private readonly serial: SerialDevice;
  private commandExecTimeout = 5000;
  private _log: string[] = [];

  constructor(serial: SerialDevice) {
    this.serial = serial;
  }

  log(from: string, msg: string) {
    this._log.push(`[${from}]: ${msg}`);
  }

  async executeCommand(command: string): Promise<string> {
    this.log('executeCommand', command);
    if (this.serial.portState !== 'open') {
      this.log('executeCommand:error', 'Port not opened!');
      throw new Error('Port not opened!');
    }

    return new Promise((resolve, reject) => {
      const responseListener = this.serial.subscribe((message) => {
        console.debug('[executeCommand] res (%o)', message);
        this.log('executeCommand:response', message.value);

        const cleanValue = cleanupResponse(message.value);
        const [resCommand, ...responseStingArray] = cleanValue.split('\r\n');
        const response = responseStingArray.join('\r\n');

        if (!command.startsWith(resCommand)) {
          this.log('executeCommand:error', 'command mismatch');
          console.debug('[executeCommand] command mismatch (%o) -- waiting more', resCommand);

          if (localStorage.card_prog_skip_command_check !== 'true') {
            return;
          }
        }

        clearTimeout(timeout);
        if (response.startsWith('err')) {
          reject(response.replace('err', '').trim());
        }

        responseListener();
        resolve(response);
      });

      const timeout = setTimeout(() => {
        responseListener();
        this.log('executeCommand:error', 'command timeout');
        reject(new Error('command timeout'));
      }, this.commandExecTimeout);

      this.serial.write(`${command}\n`);
    });
  }

  async getMifareKeys() {
    return this.executeCommand('get_mifare_keys');
  }

  async setMifareKeys(keys: { [key: string]: string }) {
    const command = `set_mifare_keys ${Object.entries(keys)
      .map(([k, v]) => `--${k}=${v}`)
      .join(' ')}`;
    return this.executeCommand(command);
  }

  async getSigKeys(): Promise<CardProgTypes.SigKeys> {
    const response = await this.executeCommand('get_sig_keys');
    const keys = response.split('\r\n');
    if (keys.length !== 3) {
      this.log('getSigKeys:error', 'Invalid keys response');
      throw new Error('Invalid keys response');
    }

    return Object.fromEntries(keys.map(mapKeys)) as CardProgTypes.SigKeys;
  }

  async setSigKeys(keys: Partial<CardProgTypes.SigKeys>) {
    const command = `set_sig_keys ${Object.entries(keys)
      .map(([k, v]) => `--${k}=${v}`)
      .join(' ')}`;
    return this.executeCommand(command);
  }

  async setSigData(data: CardProgTypes.SigData) {
    const { cardId, cardUid, sigDate } = data;

    if (!cardId || !cardUid || !sigDate) {
      this.log('setSigData:error', 'Invalid data');
      throw new Error('Invalid data');
    }

    const command = `set_sig_data --card_id=${cardId} --card_uid=${cardUid} --sig_date=${sigDate}`;

    /**
     * response example:
     * (auto removed) set_sig_data --card_id=3344556677889900 --card_uid=91924855 --sig_date=19887
     * -----
     * card_id=33445566 77889900, card_uid=91924855, flags=00000000, sig_date=19887
     * hmac=43E4CE09...
     * ecdsa_r=B929D73B...
     * ecdsa_s=D4034D88...
     * ecdsa_der=30460221...
     * block04=33445566...
     * block05=43E4CE09...
     * block06=D31C885E...
     * block08=B929D73B...
     * block09=C59A6CAD...
     * block10=D4034D88...
     * block12=8C64E707...
     */
    const response = await this.executeCommand(command);
    //extracted data from response
    const [cardIds, ...keys] = response.split('\r\n');

    if (!(cardIds.includes(cardUid) && cardIds.includes(sigDate))) {
      this.log('setSigData:error', 'Invalid response; command mismatch');
      throw new Error('Invalid response; command mismatch');
    }

    if (keys.length !== 11) {
      this.log('setSigData:error', 'Invalid response; missing data');
      throw new Error('Invalid response; missing data');
    }

    const responseNormalized = Object.fromEntries(
      cardIds.replace(/\s/, '').split(' ').concat(keys).map(mapKeys)
    ) as CardProgTypes.SetSigDataResponse;

    await SigCard.checkBlocksIntegrity(responseNormalized);
    if (responseNormalized.card_id !== cardId) {
      this.log('setSigData:error', 'Invalid response; expected output does not match!');
      throw new Error('Invalid response; expected output does not match!');
    }

    const isSignatureSeemsValid = (
      ['hmac', 'ecdsa_r', 'ecdsa_s', 'ecdsa_der'] as (keyof CardProgTypes.SetSigDataResponse)[]
    ).every((key) => isString(responseNormalized[key]) && isValidHex(responseNormalized[key]));

    if (!isSignatureSeemsValid) {
      this.log('setSigData:error', 'Invalid response; signature data is invalid');
      throw new Error('Invalid response; signature data is invalid');
    }

    return responseNormalized;
  }

  async commitCardSig() {
    /**
     * response exemple:
     * block04=33445566 ...
     * block05=984cb36e ...
     * block06=abb5c49e ...
     * block08=38300ecc ...
     * block09=dadef014 ...
     * block10=14f45680 ...
     * block12=0a32b44c ...
     */
    const response = await this.executeCommand('commit_card_sig');
    //extracted data from response
    const blocks = response.split('\r\n');

    if (blocks.length !== 7) {
      this.log('commitCardSig:error', 'Invalid response; missing data');
      throw new Error('Invalid response; missing data');
    }

    const responseNormalized = Object.fromEntries(blocks.map(mapKeys)) as CardProgTypes.CardBlocks;

    await SigCard.checkBlocksIntegrity(responseNormalized);

    return responseNormalized;
  }

  async detectCard(options: {fast?: boolean} = { fast: false }) {
    const flags = options.fast ? ' -f' : '';
    const response = await this.executeCommand(`detect_card ${flags}`);
    if (!response.startsWith('card_uid=')) {
      this.log('detectCard:error', 'Card not detected');
      throw new Error('Card not detected');
    }

    return response.replace('card_uid=', '').trim();
  }

  /// Reads card sig blocks.
  /// does not throw, instead it contains an 'err' param in the response.
  async testForCard(keyGrp: CardProgTypes.KeyGroup): Promise<{
    err?: string,
    uid?: string,
    isBlockErr?: boolean,
    isFirstBlockErr?: boolean
  }> {
    const command = `read_card_sig --key_grp=${keyGrp} -f`;

    let lines = [];
    
    try {
      const response = await this.executeCommand(command);
      lines = response.split('\r\n');
    } catch(e) {
      return {err: `${e}`};
    }

    if (lines.length < 2) {
      return {err: 'not enough response data'};
    }
    // 1st line contains keygrp used -- can be ignored
    // 2nd line contains card uid, or error
    if (lines[1].startsWith('err')) {
      return {err: lines[1].substring(5)};
    }

    if (!lines[1].startsWith('card_uid=')) {
      return {err: `unexpected response: ${lines[1]}`};
    }

    const uid = lines[1].substring(10);

    let isBlockErr = false;
    let isFirstBlockErr = false;
    for(let i = 2; i < lines.length; ++i) {
      if (lines[i].includes('err')) {
        if(i === 2) {
          isFirstBlockErr = true;
        }

        isBlockErr = true;
        break;
      }
    }

    if (isBlockErr || isFirstBlockErr) {
      return {uid, err: 'unreadable block', isBlockErr, isFirstBlockErr };
    }

    return { uid, isBlockErr, isFirstBlockErr };
  }

  async readCardSig(keyGrp: CardProgTypes.KeyGroup = 'fa', fast: boolean = false) {
    const flags = fast ? '-f' : '';
    const command = `read_card_sig --key_grp=${keyGrp} ${flags}`;

    /**
     * response example:
     * key_grp=a
     * card_uid=9192q855
     * block04=33445566 ...
     * block05=984cb36e ...
     * block06=abb5c49e ...
     * block08=38300ecc ...
     * block09=dadef014 ...
     * block10=14f45680 ...
     * block12: err: cannot authenticate
     */
    const response = await this.executeCommand(command);
    const blocks = response.split('\r\n');

    if (blocks.length !== 9) {
      this.log('readCardSig:error', 'Invalid response; missing data');
      throw new Error('Invalid response; missing data');
    }

    const responseNormalized = Object.fromEntries(
      blocks.map(mapKeys)
    ) as CardProgTypes.ReadCardSigResponse;

    await SigCard.checkBlocksIntegrity(responseNormalized);

    if (responseNormalized.key_grp !== keyGrp || !isString(responseNormalized.card_uid)) {
      this.log('readCardSig:error', 'Invalid response; expected output does not match!');
      throw new Error('Invalid response; expected output does not match!');
    }

    return responseNormalized;
  }

  async protectCard(start?: string, skip?: string[]) {
    let command = 'protect_card';
    if (start) {
      command += ` --start=${start}`;
    }
    if (skip && skip.length > 0) {
      command += ` ${skip.map((sector) => `--skip=${sector}`).join(' ')}`;
    }
    return this.executeCommand(command);
  }

  getLog() {
    return this._log.join('\n');
  }
}

function cleanupResponse(str: string) {
  return str
    .trim()
    .replace(/[^\w\s-_=:]/gi, '')
    .toLowerCase();
}

function mapKeys(rawKey: string) {
  const [key, value] = rawKey.split('=');
  return [key?.trim(), value?.replace(/\s/g, '')];
}

export namespace CardProgTypes {
  export type KeyGroup = 'a' | 'b' | 'fa' | 'fb';
  export type SigKeys = { hmac_key: string; ecdsa_privkey: string; ecdsa_pubkey: string };

  export type SigData = { cardId: string; cardUid: string; sigDate: string };

  export type SetSigDataResponse = {
    card_id: string;
    card_uid: string;
    flags: string;
    sig_date: string;
    hmac: string;
    ecdsa_r: string;
    ecdsa_s: string;
    ecdsa_der: string;
  } & CardBlocks;

  export type ReadCardSigResponse = {
    key_grp: KeyGroup;
    card_uid: string;
  } & Partial<CardBlocks>;

  export type CardBlocks = {
    block04: string;
    block05: string;
    block06: string;
    block08: string;
    block09: string;
    block10: string;
    block12: string;
  };
}
