import {
  IOutputDataLoader,
  IOutputStage,
  IOutputStageData,
  IOutputStagePayload,
} from '../../../interfaces/stages/output';
import { Logger } from '../../../../logger';
import { isStringWithLength, wait } from '../../../../utils/fns';
import { IApi } from '../../../interfaces/api';
import { ExecutionState, PartIdentifier, WorkbenchExecution } from '@/utils/api/fab.types';
import { ExecutionRecipeConfig } from '@/pages/debug/NewExe/types';
import { IState } from '@/store/interfaces';
import { BarcodeType } from '@/utils/BrotherQL/types';
import { IStage } from '@/store/interfaces/stages/generic';
import { partIdEq } from '@/pages/debug/NewExe/util';
import { IPrinter } from '@/store/interfaces/printer';
import { createErrorHandler } from '@/store/utils/error-handler';

const logger = new Logger('OutputStage');
const DATA_VERSION = 1;

interface OutputStageParams {
  executionData: WorkbenchExecution;
  config: ExecutionRecipeConfig;
  loader: IOutputDataLoader;
  state: IState<IOutputStage>;
  io: {
    printer: IPrinter;
  };
  api: {
    serialNumbers: Pick<IApi['serialNumbers'], 'create'>;
  };
  onCompletion: () => void;
}

export async function createOutputStage({
  executionData,
  config,
  loader,
  state,
  io,
  api,
  onCompletion,
}: OutputStageParams): Promise<IOutputStage> {
  logger.addMetadata({ executionId: executionData.id });
  logger.info('Creating Output Stage', { executionId: executionData.id });
  const initialData = await loader.loadData(executionData, config, DATA_VERSION);

  const errorHandler = createErrorHandler(state, logger);
  const serialNumberGenerator = createSerialNumberGenerator(state, api, config, errorHandler);
  const printer = createPrinter(io.printer, config, state, errorHandler);

  const handleSerialNumberScan: IStage['handleSerialNumberScan'] = (_, identifier) => {
    logger.info('Serial number scan received', { identifier });
    const { serialNumberAction = 'generate' } = config.output ?? {};
    const handled =
      serialNumberAction === 'generate'
        ? handleGeneratedSerialNumber(identifier)
        : handleExistingSerialNumber(identifier);

    if (handled) checkIsCompleted();
    return handled;
  };

  const handlePrinterStateChanged = () => {
    const printerStatus = io.printer.getStatus();
    logger.info('Printer state changed', { printerStatus });
    errorHandler.removeError('printer');
    if (printerStatus.available) {
      printer.print();
    } else {
      errorHandler.addError('printer', 'Printer is not available');
    }
  };

  const checkIsCompleted = async () => {
    logger.debug('Checking if stage is completed');

    if (state.get().completed) {
      logger.warn('Stage already completed');
      return;
    }

    const { data } = state.get();
    const completed = isCompleted(data);
    logger.info('Stage completion status', { completed });
    state.set((stage) => {
      stage.completed = completed;
    });
    if (completed) {
      logger.info('Stage completed, calling onCompletion');
      await onCompletion();
    }
  };

  const getPayload = (): IOutputStagePayload => {
    logger.debug('Generating payload');
    const { data } = state.get();
    return {
      name: ExecutionState.Output,
      artefacts: data.artefacts,
      part_id: data.partId,
      parts: data.parts.map((part) => ({
        qty: part.qty.toString(),
        identifier: part.identifier,
      })),
    };
  };

  function handleGeneratedSerialNumber(identifier: PartIdentifier): boolean {
    logger.debug('Handling generated serial number', { identifier });
    let handled = false;
    state.set((stage) => {
      const part = stage.data.parts.find((p) => partIdEq(p.identifier, identifier));
      if (part && !part.scanned) {
        part.scanned = true;
        handled = true;
        logger.info('Part marked as scanned', { partId: part.identifier.serial_number });
      }
    });
    return handled;
  }

  function handleExistingSerialNumber(identifier: PartIdentifier): boolean {
    logger.debug('Handling existing serial number', { identifier });
    const { data } = state.get();
    if (data.parts.some((p) => partIdEq(p.identifier, identifier))) {
      logger.warn('Serial number already used', { identifier });
      return false;
    }

    state.set((stage) => {
      const part =
        stage.data.parts.find((p) => !isStringWithLength(p.identifier.serial_number)) ||
        stage.data.parts[0];
      part.identifier = identifier;
      part.scanned = true;
      logger.info('Existing serial number assigned to part', {
        partId: part.identifier.serial_number,
      });
    });
    return true;
  }

  return {
    type: ExecutionState.Output,
    completed: false,
    errors: {},
    data: initialData,
    _printCache: {},
    getPayload,
    handleSerialNumberScan,
    handlePrinterStateChanged,
    onEnter: async () => {
      logger.info('Entering output stage');
      await serialNumberGenerator.generate();
      await printer.print();
      await checkIsCompleted();
      logger.info('Output stage initialization completed');
    },
    onExit: () => logger.info('Exiting output stage'),
    onPostExit: () => logger.info('After exiting output stage'),
  };
}

function isCompleted(data: IOutputStageData): boolean {
  const completed = !data.scanRequired || data.parts.every((part) => part.scanned);
  logger.info('Checking stage completion', { scanRequired: data.scanRequired, completed });
  return completed;
}

function createSerialNumberGenerator(
  state: IState<IOutputStage>,
  api: OutputStageParams['api'],
  config: ExecutionRecipeConfig,
  errorHandler: ReturnType<typeof createErrorHandler>
) {
  return {
    generate: async () => {
      logger.info('Starting serial number generation');
      const { data } = state.get();
      const serialNumberAction = config.output?.serialNumberAction || 'generate';
      if (!data.serialized || serialNumberAction !== 'generate') {
        logger.info('Serial number generation skipped', {
          serialized: data.serialized,
          serialNumberAction,
        });
        return;
      }

      for (const part of data.parts) {
        if (!part.identifier.serial_number) {
          try {
            logger.debug('Generating serial number for part', { partId: data.partId });
            const sn = await api.serialNumbers.create({ part_id: data.partId });
            state.set((stage) => {
              const partToUpdate = stage.data.parts.find((p) => !p.identifier.serial_number);
              if (partToUpdate) {
                partToUpdate.identifier.serial_number = sn.serial_number;
                logger.info('Serial number generated and assigned', {
                  serialNumber: sn.serial_number,
                });
              } else {
                logger.error('No part found without serial number');
              }
            });
          } catch (error) {
            logger.error('Error generating serial number', { error });
            errorHandler.addError('api', 'Failed to generate serial number');
          }
        }
      }
      logger.info('Serial number generation completed');
    },
  };
}

function createPrinter(
  printer: IPrinter,
  config: ExecutionRecipeConfig,
  state: IState<IOutputStage>,
  errorHandler: ReturnType<typeof createErrorHandler>
) {
  return {
    print: async () => {
      logger.info('Starting print process');
      const shouldPrint = config.output?.serialNumberAction === 'generate';
      const printerStatus = printer.getStatus();
      errorHandler.removeError('printer');

      logger.debug('Print conditions', { shouldPrint, printerStatus });
      if (!shouldPrint) {
        logger.info('Printing skipped, not required');
        return;
      }

      if (!printerStatus.initialized || !printerStatus.available) {
        logger.error('Printer not ready', {
          initialized: printerStatus.initialized,
          available: printerStatus.available,
        });
        errorHandler.addError('printer', 'Printer is not ready');
        return;
      }

      const {
        labelPrefix = 'sn',
        codeType = BarcodeType.QRCODE,
        validLabelSizes = [12, 29, 62],
      } = config.output?.print ?? {};

      if (!printer.checkLabelSizeCompatibility(validLabelSizes)) {
        const labelSize = printer.getStatus().media?.width;
        logger.error('Invalid label size', { labelSize, validLabelSizes });
        errorHandler.addError(
          'printer',
          `Invalid label size "${labelSize ?? 'unknown'}"; allowed label sizes: ${validLabelSizes.join(', ')}`
        );
        return;
      }

      const { data, _printCache } = state.get();
      for (const part of data.parts) {
        const sn = part.identifier.serial_number;
        if (!part.scanned && isStringWithLength(sn) && !_printCache[sn]) {
          logger.debug('Printing label', { serialNumber: sn });
          state.set((stage) => {
            stage._printCache[sn] = true;
          });
          try {
            const prefix = isStringWithLength(labelPrefix) ? labelPrefix : labelPrefix.serialNumber;
            await printer.print({
              labelText: sn,
              barcodeValue: [prefix, sn].join(':'),
              barcodeType: codeType,
            });
            logger.info('Label printed successfully', { serialNumber: sn });
            await wait(1_000);
          } catch (e) {
            state.set((stage) => {
              stage._printCache[sn] = false;
            });
            logger.error('Printer error', { error: e, serialNumber: sn });
            const message = e instanceof Error ? e.message : 'Unknown print error';
            errorHandler.addError('printer', message);
          }
        }
      }
      logger.info('Print process completed');
    },
  };
}
