import { ExecutionRenderData } from '@/pages/debug/NewExe/types';
import { executionStateColor } from '@/pages/debug/NewExe/ui/colors';
import { StageCard } from '@/pages/debug/NewExe/ui/StageCard';
import { StageCardItem } from '@/pages/debug/NewExe/ui/StageCardItem';
import { StepsListProps } from '@/pages/debug/NewExe/ui/StepsList';
import { ExecutionState } from '@/utils/api/fab.types';
import { isStringWithLength } from '@/utils/fns';

export type InputProps = {
  inputs: ExecutionRenderData['inputs'];
  active: boolean;
  done: boolean;
};

export function Inputs(props: InputProps) {
  const { inputs, active, done } = props;

  return (
    <StageCard {...{ active, done, stage: ExecutionState.Input }}>
      {inputs.map((input) => {
        const { loadedParts, serialized, reqQty, loadedQty } = input;
        const actionText = active ? input.actionText : null;
        let bg = active ? 'red' : 'gray';
        if (input.valid) {
          bg = executionStateColor[ExecutionState.Input] ?? 'green';
        }

        return (
          <StageCardItem
            key={input.cmd}
            cmd={input.cmd}
            focused={input.focused}
            stage={ExecutionState.Input}
            statusColor={bg}
            title={input.partName}
            steps={[
              {
                title: '-',
                value: actionText,
                r: (ren) => (isStringWithLength(actionText) ? <>{ren.Value(reqQty)}</> : null),
                hidden: !isStringWithLength(actionText),
              },

              {
                title: '-',
                value: null,
                r: (ren) => (
                  <>
                    {ren.Text('required')}
                    {ren.Value(reqQty)}
                    {ren.Text('total')}
                    {ren.Value(loadedQty)}
                  </>
                ),
                status: reqQty === loadedQty ? 'ok' : 'partial',
              },

              ...loadedParts.map(
                (loaded) =>
                  ({
                    title: '',
                    value: null,
                    r: (ren) => (
                      <>
                        {ren.Value(loaded.qty)}
                        {ren.Text(serialized ? 'Serial Number' : 'Lot Number')}
                        {ren.Value(loaded.identifier[serialized ? 'serial_number' : 'lot_number'])}
                      </>
                    ),
                    status:
                      isStringWithLength(
                        loaded.identifier[serialized ? 'serial_number' : 'lot_number']
                      ) && isStringWithLength(loaded.qty)
                        ? 'ok'
                        : 'partial',
                  }) as StepsListProps['steps'][0]
              ),
            ]}
          />
        );
      })}
    </StageCard>
  );
}
