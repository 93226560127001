import type { SerialDeviceInfo } from '@/utils/WebSerial/SerialDevice';

export const FalconWebSerialConfig: SerialDeviceInfo = {
  // chrome://device-log/
  vendorId: 0x303a,
  productId: 0x4001,
  options: {
    baudRate: 115200,
  },
};
