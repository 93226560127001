import { useCallback, useEffect } from 'react';
import { StorageKeys, useFabLocalStorage } from '@/utils/hooks';
import { WorkbenchExecution } from '@/utils/api/fab.types';
import { ExecutionRenderData } from '@/pages/debug/NewExe/types';
import { useRenderData } from './useRenderData';
import { useController, useStatus } from './hooks';
import { Config } from '@/config';
import { ISingleExecutionDependencies } from '@/store/interfaces/dependencies';
import { ExecutionStatus } from '@/store/interfaces/single-execution/store';
import { useSingleExecutionStore } from '../store';
import { createDependencies } from '../../dependencies';

type UseSingleExecution = (params: { autoAssign: boolean }) => {
  startExecution: () => void;
  resetStage: () => void;
  status: ExecutionStatus;
  execution?: WorkbenchExecution;
  eRenderData?: ExecutionRenderData;
};

export const useSingleExecution: UseSingleExecution = () => {
  const store = useSingleExecutionStore();
  const controller = useController();
  const status = useStatus();
  const eRenderData = useRenderData();
  const execution = controller?.executionData;

  const [workbenchIdNum] = useFabLocalStorage(StorageKeys.WorkbenchId, '');
  const workbenchId = workbenchIdNum.toString();

  useEffect(() => {
    // Restore the execution if the tab is reloaded
    if (store.isExecutionPending()) {
      const dependencies = getDependencies();
      store.startExecution({ workbenchId, dependencies });
    }
  }, []);

  const startExecution = useCallback(() => {
    if (store.isExecutionPending()) {
      return;
    }
    const dependencies = getDependencies();

    store.startExecution({ workbenchId, dependencies });
  }, [workbenchId, store]);

  const resetStage = useCallback(() => {
    const _store = useSingleExecutionStore.getState();
    _store.resetStage();
  }, []);

  return { startExecution, resetStage, status, execution, eRenderData };
};

let dependencies: ISingleExecutionDependencies | null = null;

function getDependencies() {
  const mockEnabled = Config.isTestMode && Config.isMockEnabled;
  if (!dependencies) {
    dependencies = createDependencies({ mockEnabled });
  }
  return dependencies;
}
