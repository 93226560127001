import { QueryClient } from '@tanstack/react-query';
import Decimal from 'decimal.js';
import { ExecutionFinished } from '@/pages/debug/NewExe/stage/Finished';
import { StageSuccession } from '@/pages/debug/NewExe/configs';
import type { ExecutionRenderStore } from '@/pages/debug/NewExe/ExecutionRender';
import { isStringWithLength } from '@/utils/fns';
import { BarcodeStorage } from '@/pages/debug/NewExe/BarcodeStorage';
import { ExecutionRecipeConfig } from '@/pages/debug/NewExe/types';
import { getRecipeConfig } from '@/pages/debug/NewExe/util';
import { log } from '@/utils/log';
import { fabApi } from '@/config';
import {
  ExecutionBody,
  ExecutionState,
  PartIdentifier,
  WorkOrderKind,
  WorkbenchExecution,
} from '@/utils/api/fab.types';
import { ExecutionOutput } from './stage/Output';

import { ExecutionInput } from './stage/Input';
import { ExecutionCardProg } from '@/pages/debug/NewExe/stage/CardProg';

/// Stage class design
/// load() -> load stage from existing data, async
/// onEnter() -> called *once* when newly entering this state
/// afterExit() -> called *once* after exiting this state

export type RequiredInput = {
  part_name: string;
  part_id: number;
  serialized: boolean;
  qty: Decimal;
};

export type LoadedInput = {
  qty: Decimal;
  identifier: PartIdentifier;
};
type ErrorKeys = 'output' | 'advance' | string;

export class Execution {
  store: ExecutionRenderStore;
  config: ExecutionRecipeConfig = {};
  errors: Partial<Record<ErrorKeys, string>> = {};

  output?: ExecutionOutput;
  input?: ExecutionInput;
  cardProg?: ExecutionCardProg;
  finished?: ExecutionFinished;

  barcodeStorage?: BarcodeStorage;

  private id: number;
  recipeName: string;
  private currentStage: ExecutionState;
  private workOrderKind: WorkOrderKind;
  dispatchUpdate: () => any;

  constructor(store: ExecutionRenderStore) {
    this.id = 0;
    this.store = store;
    this.dispatchUpdate = store.dispatchUpdate;
    this.currentStage = ExecutionState.Input;
    this.recipeName = '';
    this.workOrderKind = WorkOrderKind.Default;
    log.debug('Execution::constructor()', this);
  }

  error = (key: keyof typeof this.errors, msg: string | null) => {
    if (msg === null) {
      this.errors[key] = undefined;
    } else {
      this.errors[key] = msg;
    }
    this.dispatchUpdate();
  };

  async load(execution: WorkbenchExecution, q: QueryClient) {
    if (!execution.recipe) {
      this.error('load', 'invalid execution data supplied. probably UI bug');
      return;
    }

    this.config = getRecipeConfig(execution.recipe.id);
    this.recipeName = execution.recipe.name;
    this.currentStage = execution.state;
    this.id = execution.id;
    this.workOrderKind = execution.work_order_kind;

    this.barcodeStorage = new BarcodeStorage(execution.recipe.id);

    this.input = await ExecutionInput.load({
      execution,
      q,
      config: this.config,
      barcodeStorage: this.barcodeStorage,
    });

    this.output = await ExecutionOutput.load(execution, this.dispatchUpdate, q, this.config).catch(
      (e) => {
        this.error('output', `${e}`);
        return undefined;
      }
    );

    this.finished = await ExecutionFinished.load({
      recipeId: execution.recipe.id,
      config: this.config,
      store: this.store,
    });

    if (this.isCardProgWorkKind()) {
      this.cardProg = await ExecutionCardProg.load({
        execution,
        q,
        config: this.config,
        store: this.store,
      });
    }

    await this.tryToAdvanceOrReEnterStage(execution);
  }

  async tryToAdvance() {
    const isCurrentStageReady = this.isCurrentStageReady();
    if (!isCurrentStageReady) {
      return false;
    }

    const nextStage = this.getNextStage();
    log.debug(
      'Execution::tryToAdvance -> isCurrentStageReady[%o], nextStage[%o].. try auto-advance..',
      true,
      nextStage
    );

    if (nextStage) {
      await this.advance(nextStage, this.store.q);
      return true;
    }

    return false;
  }

  async tryToAdvanceOrReEnterStage(execution: WorkbenchExecution) {
    const advanced = await this.tryToAdvance();
    if (advanced) {
      return;
    }

    log.debug(
      'Execution::tryToAdvance -> isCurrentStageReady[%o], try to re-enter current stage',
      false
    );
    await this.enterStage(this.currentStage, execution, this.store.q);
  }

  async onWorkbenchExecutionUpdate(execution: WorkbenchExecution, q: QueryClient) {
    log.info('Execution::onWorkbenchExecutionUpdate[%o]', execution);

    // await this.enterStage(nextState, execution, q);
    this.currentStage = execution.state;

    let updated = false;
    if (this.isCardProgWorkKind()) {
      updated = (await this.cardProg?.onWorkbenchExecutionUpdate(execution, q)) ?? false;
    }

    if (updated) {
      this.dispatchUpdate();
    }

    await this.tryToAdvanceOrReEnterStage(execution);
  }

  getNextStage(): ExecutionState | null {
    const succession = StageSuccession[this.workOrderKind];
    const index = succession.indexOf(this.currentStage);
    if (index === -1 || index === succession.length - 1) {
      return null;
    }

    return succession[index + 1];
  }

  async advance(nextState: ExecutionState, q: QueryClient) {
    log.log('Execution::advance -> nextState[%o]', nextState);
    let body: ExecutionBody | undefined;
    const prevStage = this.currentStage;
    this.error('advance', null);

    switch (this.currentStage) {
      case ExecutionState.Input: {
        if (!this.input) {
          this.error('advance', 'empty input when transitioning out of input state');
          return;
        }
        body = this.input.getAdvanceBody();
        break;
      }

      case ExecutionState.CardProg: {
        if (!this.cardProg) {
          this.error('advance', 'empty card-prog when transitioning out of card-prog state');
          return;
        }

        try {
          body = this.cardProg.getAdvanceBody();
        } catch (e: any) {
          const defError = 'can not get card-prog advance body';
          this.error('advance', e && isStringWithLength(e.message) ? e.message : defError);
        }
        break;
      }

      case ExecutionState.Output: {
        if (!this.output) {
          this.error('advance', 'empty output when transitioning out of output state');
          return;
        }
        body = this.output.getAdvanceBody();
        break;
      }
    }

    const params = {
      nextState,
      body,
      id: this.id.toString(),
    };

    const execution = await q
      .fetchQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: fabApi.workbenches.executions.key.concat(this.id.toString()),
        queryFn: () => fabApi.workbenches.executions.advance(params),
        staleTime: 1,
      })
      .catch((e) => {
        log.error('Failed to advance stage (POST) %o', e);

        this.error(
          'advance',
          e && isStringWithLength(e.message) ? e.message : 'Failed to advance stage (POST)'
        );
      });

    if (execution) {
      try {
        await this.afterExitStage(prevStage);
      } catch (e) {
        log.error(e);
      }

      try {
        await this.onWorkbenchExecutionUpdate(execution, q);
        //TODO
      } catch (e) {
        log.error('Execution::advance -> load(): %o', e);
      }
    }
  }

  private async afterExitStage(previousStage: ExecutionState | undefined) {
    if (previousStage === ExecutionState.Input) {
      this.input?.afterExit();
    }

    if (previousStage === ExecutionState.CardProg) {
      this.cardProg?.afterExit();
    }

    if (previousStage === ExecutionState.Output) {
      this.output?.afterExit();
    }
  }

  private async enterStage(
    newStage: ExecutionState,
    execution: WorkbenchExecution,
    q: QueryClient
  ) {
    log.info('Execution::enterStage[%o]', newStage);

    if (newStage !== execution.state) {
      log.error(`Execution::enterStage -> unexpected next state: ${execution.state}`);
    }

    this.currentStage = execution.state;
    switch (this.currentStage) {
      case ExecutionState.Input: {
        // fucking hack, fix this properly.
        // check if we can't auto advance?
        // this should be called *after* the enter/exit processes are done anyway.
        log.warn('CHECK: disabled advance in Execution::enterStage[%o]');
        /*if (this.input?.isReady()) {
          const nextStage = this.getNextStage();
          if (nextStage) {
            await this.advance(nextStage, q);
          }
        }*/
        break;
      }

      case ExecutionState.CardProg: {
        // TODO: check if really needed
        /* if (await this.cardProg?.isReady()) {
          const nextStage = this.getNextStage();
          if (nextStage) {
            await this.advance(nextStage, q);
          }
        } else {
          await this.cardProg?.onEnter(execution, q);
        }*/
        await this.cardProg?.onEnter(execution, q);

        break;
      }

      case ExecutionState.Output: {
        await this.output?.enter(q);
        break;
      }

      case ExecutionState.Finished: {
        await this.finished?.onEnter();
        break;
      }
    }
  }

  isCurrentStageReady(): boolean {
    return this.isStageReady(this.currentStage);
  }

  getCurrentStage(): ExecutionState {
    return this.currentStage;
  }

  getOutput() {
    return this.output;
  }

  getInput() {
    return this.input;
  }

  isStageReady(stage: ExecutionState): boolean {
    log.info('Execution::isStageReady -> check');
    switch (stage) {
      case ExecutionState.Input: {
        const isReady = this.input?.isReady() || false;
        log.info('Execution::isStageReady -> ExecutionState.Input = [%o]', isReady);
        return isReady;
      }

      case ExecutionState.CardProg: {
        const isReady = this.cardProg?.isReady() || false;
        log.info('Execution::isStageReady -> ExecutionState.CardProg = [%o]', isReady);
        return isReady;
      }

      case ExecutionState.Output: {
        const isReady = this.output?.isReady() || false;
        log.info('Execution::isStageReady -> ExecutionState.Output = [%o]', isReady);
        return isReady;
      }
    }

    log.warn('isStageReady() called with unknown stage[%o]', stage);
    return false;
  }

  getWorkOrderKind() {
    return this.workOrderKind;
  }

  isCardProgWorkKind() {
    return this.getWorkOrderKind() === WorkOrderKind.CardProg;
  }
}
