import Decimal from 'decimal.js';
import { IInputStageData } from '../store/interfaces/stages/input';
import { Logger } from '../logger';
import { IStagesDataLoader } from '../store/interfaces/stages';
import {
  Artefact,
  CardData,
  ExecutionAdvanceParams,
  ExecutionState,
  FabUploadRequest,
  Part,
  UploadContentType,
  WorkbenchExecution,
  WorkbenchExecutionRecipe,
  WorkOrderKind,
} from '@/utils/api/fab.types';
import { ExecutionRecipeConfig } from '@/pages/debug/NewExe/types';
import { BarcodeType } from '@/utils/BrotherQL/types';
import {
  FabExposed,
  ProbeCardOptions,
  ProbeCardResult,
  ProgramCardResult,
} from '@/utils/fab-wb/types';
import { IApi } from '@/store/interfaces/api';
import { IPrinter } from '@/store/interfaces/printer';
import { ITester } from '@/store/interfaces/tester';
import { wait } from '@/utils/fns';

const logger = new Logger('Mocks');

export function mockExecutionData(): WorkbenchExecution {
  return {
    id: 1,
    created_at: new Date().toISOString(),
    finished_at: null,
    assigned_at: new Date().toISOString(),
    assigned_to: 1,
    workbench_id: 1,
    work_order_kind: WorkOrderKind.Qa,
    work_order_id: 1,
    state: ExecutionState.Qa,
    state_data: {
      name: ExecutionState.Qa,
    },
    next_states: [],
    recipe: mockRecipe(),
  };
}

export function mockAdvancedExecutionData(): WorkbenchExecution {
  const executionData = mockExecutionData();
  executionData.state = ExecutionState.Output;
  executionData.state_data.name = ExecutionState.Output;
  return executionData;
}

export function mockRecipe(): WorkbenchExecutionRecipe {
  return {
    id: 1,
    name: 'Mock Recipe',
    output_part_id: 1,
    output_part_qty: '1.0',
    deprecated: false,
    inputs: [
      {
        part_id: 1,
        part_qty: '1.0',
      },
    ],
    parallelism: 1,
    work_order_kind: WorkOrderKind.Qa,
  };
}

export function mockExecutionConfig(): ExecutionRecipeConfig {
  return {
    input: {
      lotNumberPersistence: 0,
    },
    output: {
      allowEarlyScan: false,
      serialNumberAction: 'generate',
      scanRequired: false,
      print: {
        validLabelSizes: [12, 29, 62],
        codeType: BarcodeType.QRCODE,
        formatAsLink: false,
        linkPrefix: '',
        labelPrefix: '',
      },
    },
    finished: {
      restartCounterOn: 0,
    },
  };
}

export function mockScanner() {
  type Callback = (code: string) => void;

  let cbListeners: Callback[] = [];
  logger.debug('Registering scanner listener');

  const simulateScan = (code: string) => {
    cbListeners.forEach((cb) => cb(code));
  };

  // @ts-expect-error - Expose simulateScan for testing
  window.simulateScan = simulateScan;

  const onScan = (callback: Callback) => {
    cbListeners.push(callback);
    return {
      unsubscribe: () => {
        cbListeners = cbListeners.filter((cb) => cb !== callback);
      },
    };
  };

  return {
    onScan,
  };
}

export function mockPrinter(): IPrinter {
  const cbListeners: Array<() => void> = [];

  const onPrinterStateChanged = (callback: () => void) => {
    cbListeners.push(callback);
    return {
      unsubscribe: () => {
        cbListeners.filter((cb) => cb !== callback);
      },
    };
  };

  return {
    print: async () => {
      logger.debug('Printing label');
      return true;
    },
    onPrinterStateChanged,
    checkLabelSizeCompatibility: (_labelSizes: number[]) => true,
    getStatus: () => ({
      initialized: true,
      available: true,
    }),
  };
}

export function mockBarcode() {
  let partId = 0;
  let lotNumber = '';
  let iterations = 0;

  const requestLotNumber = (id: number) => {
    partId = id;
    return 'mockLotNumber';
  };

  const useLotNumber = (lot: string) => {
    lotNumber = lot;
  };

  const persistBarcode = (id: number, lot: string, iter: number) => {
    partId = id;
    lotNumber = lot;
    iterations = iter;
  };

  return {
    requestLotNumber,
    useLotNumber,
    persistBarcode,
    getPartId: () => partId,
    getLotNumber: () => lotNumber,
    getIterations: () => iterations,
  };
}

export function mockFab(): { cardprog: FabExposed['cardprog'] } {
  return {
    cardprog: {
      probeCard: async (_options: ProbeCardOptions): Promise<ProbeCardResult> =>
        Promise.resolve({ keyGrp: 'a', status: 'ready', cardUid: '1234-card-id-mock' }),
      programCard: async (_cardId: string, _cardUid: string): Promise<ProgramCardResult> =>
        Promise.resolve({ ok: true, status: 'programmed' }),
      clearLog: async (): Promise<void> => {},
      getLog: async (): Promise<ArrayBuffer> => Promise.resolve(new ArrayBuffer(0)),
      getConfig: async (): Promise<any> => Promise.resolve({}),
      setConfig: async (_: any): Promise<boolean> => Promise.resolve(true),
      restart: async (): Promise<void> => Promise.resolve(),
    },
  };
}

export function mockTester(): ITester {
  return {
    test: async (deviceId: string) => {
      await wait(3000);
      return { deviceId, passed: true, message: 'Test passed' };
    },
  };
}

export function mockDataLoader(): IStagesDataLoader {
  return {
    inputLoader: {
      loadData: async (executionData) => {
        const inputStageData: IInputStageData = {
          executionId: executionData.id,
          recipeId: 1,
          version: 1,
          parts: {
            1: {
              serialized: false,
              partId: 1,
              partName: 'Mock Part',
              reqQty: new Decimal(1),
              loaded: [],
            },
          },
        };
        return inputStageData;
      },
    },
    outputLoader: {
      loadData: async (executionData) => ({
        version: 1,
        executionId: executionData.id,
        partId: 1,
        partName: 'Mock Part',
        scanRequired: false,
        serialized: false,
        artefacts: [],
        parts: [],
      }),
    },
    cardProgLoader: {
      loadData: async () => ({
        version: 1,
        executionId: 1,
        cardId: 'mockCardId',
        programmed: false,
        liveCardStatus: 'missing',
        probeCardResult: {
          keyGrp: 'a',
          status: 'missing',
        },
      }),
      saveArtifactIntoOutput: () => {},
      saveNoaSerialIntoOutputStage: () => {},
      storeCardDataInCache: () => {},
      loadCardDataFromCache: () => null,
      isNoaSerialNumberUsed: () => false,
    },
    qaLoader: {
      loadData: async () => ({
        version: 1,
        executionId: 1,
      }),
    },
  };
}

export function mockApi(): IApi {
  let shouldThrow = false;
  return {
    workbenches: {
      executions: {
        advance: <T>(params: ExecutionAdvanceParams<T>) => {
          logger.debug('Advancing execution', params);
          if (shouldThrow) {
            shouldThrow = false;
            return Promise.reject(new Error('Mock error'));
          }
          return Promise.resolve(mockAdvancedExecutionData());
        },
        assign: (workbenchId: string) => {
          logger.debug('Assigning workbench', workbenchId);
          return Promise.resolve({});
        },
        byId: (id: string) => {
          logger.debug('Fetching execution', id);
          return Promise.resolve([mockExecutionData()]);
        },
      },
    },
    parts: {
      byIds: (ids: string | string[]): Promise<Part[]> => {
        logger.debug('Fetching parts', ids);
        if (Array.isArray(ids)) {
          return Promise.resolve(ids.map((id) => generateMockPart(Number(id))));
        }
        return Promise.resolve([generateMockPart(Number(ids))]);
      },
    },
    lots: {
      getByLotNumber: (lotNumber: string) => {
        logger.debug('Fetching lot number', lotNumber);
        return Promise.resolve({
          id: 0,
          created_at: '2022-01-01T00:00:00.000Z',
          part_id: 0,
          delivery_part_id: 0,
        });
      },
    },
    serialNumbers: {
      create: (params: { part_id: number }) => {
        logger.debug('Creating serial number', params);
        return Promise.resolve({
          serial_number: Math.random().toString(36).substring(7),
        });
      },
      getBySN: (serialNumber: string) => {
        logger.debug('Fetching serial number', serialNumber);
        return Promise.resolve([]);
      },
    },
    artefacts: {
      upload: (arg: FabUploadRequest): Promise<Artefact> => {
        logger.debug('Uploading artefact', arg);

        return Promise.resolve({
          id: 1,
          created_at: '2022-01-01T00:00:00.000Z',
          part_id: '12345',
          file_name: 'example.txt',
          file_size: 1024,
          file_type: 'text/plain',
          size: 1024,
          name: 'example.txt',
          content_type: UploadContentType.json,
          created_by: 1,
        });
      },
    },
    fixes: {
      getCard: (cardId: string): Promise<CardData> => {
        logger.debug('Fetching card', cardId);
        return Promise.resolve({
          card_id: 'mockCardId',
          card_uid: 'mockCardUid',
          box_serial_number: 'mockBoxSerial',
          noa_id: 'mockNoaId',
        });
      },
    },
  };
}

function generateMockPart(id: number): Part {
  return {
    id,
    created_at: '2022-01-01T00:00:00.000Z',
    mfr_part_number: 'Mock Part Number',
    mfr_name: 'Mock Manufacturer',
    description: 'Mock Description',
    description_ro: 'Mock Read-Only Description',
    deprecated: false,
    serialized: true,
    taric_number: 'Mock Taric Number',
    hs_number: 'Mock HS Number',
    catalog: {
      part_id: 1,
      cat_id: 'Mock Cat Id',
      subcat_id: 'Mock Subcat Id',
      catalog_number: 'Mock Catalog Number',
      name: 'Mock Name',
      name_ro: 'Mock Read-Only Name',
      deprecated: false,
    },
  };
}
