type PortState = 'open' | 'close';
type SerialMessageCallback = (message: SerialMessage) => void;
export type SerialDeviceInfo = {
  options: SerialOptions;
  // chrome://device-log/
  vendorId: number;
  productId: number;
  preferAutoPortSelect?: boolean;
  isDesiredDevice?: (value: SerialPort) => boolean;
  filterPorts?: (value: SerialPort, index: number, obj: SerialPort[]) => boolean;
  customReadableTransformer?: (stream: ReadableStream<string>) => ReadableStream<string>;
};

export type SerialMessage = {
  value: string;
  timestamp: number;
};

export type DeviceState = {
  isLoading: boolean;
  portState: PortState;
};
type DeviceStateCallback = (state: DeviceState) => void;

export class SerialDevice {
  readonly _info: Required<SerialDeviceInfo>;
  private _reader: ReadableStreamDefaultReader | null = null;
  private _writer: WritableStream<string> | null = null;
  private _readerClosedPromise: Promise<void> = Promise.resolve();
  private _writerClosedPromiseRef: Promise<void> = Promise.resolve();
  private _subscribers = new Map<number, SerialMessageCallback>();
  public _portState: PortState = 'close';
  private port?: SerialPort | null = null;
  private _aborted = false;
  private _onConnectEventTimer: ReturnType<typeof setTimeout> = 0 as any;
  private _state = {
    isAutoSelectingPort: false,
    nextAutoPortIndex: -1,
    autoSelectPortTimeout: 4000,
    loading: true,
  };

  get portState() {
    return this._portState;
  }

  set portState(x) {
    if (x !== this.portState) {
      setTimeout(this._emitOnStateChange.bind(this), 1);
    }

    this._portState = x;
  }

  get isLoading() {
    return this._state.loading;
  }

  set isLoading(x) {
    if (x !== this._state.loading) {
      setTimeout(this._emitOnStateChange.bind(this), 1);
    }

    this._state.loading = x;
  }

  private _stateChangeListeners = new Map<number, DeviceStateCallback>();
  public onStateChange(callback: DeviceStateCallback) {
    const id = this._stateChangeListeners.size;
    this._stateChangeListeners.set(id, callback);

    return () => {
      this._stateChangeListeners.delete(id);
    };
  }

  private _emitOnStateChange() {
    this._stateChangeListeners.forEach((callback) =>
      callback({
        isLoading: this.isLoading,
        portState: this.portState,
      })
    );
  }

  constructor({ info }: { info: SerialDeviceInfo }) {
    this._info = {
      preferAutoPortSelect: true,
      isDesiredDevice(port: SerialPort) {
        const { usbVendorId, usbProductId } = port.getInfo();
        return usbVendorId === info.vendorId && usbProductId === info.productId;
      },
      filterPorts: (_, index, ports) => index === ports.length - 1,
      customReadableTransformer: (readable) => readable,
      ...info,
    };

    this._autoConnect()
      .catch(console.error)
      .finally(() => {
        this.isLoading = false;
      });

    navigator.serial.addEventListener('connect', this._onPortConnect.bind(this));
    navigator.serial.addEventListener('disconnect', this._onPortDisconnect.bind(this));
  }

  public subscribe(callback: SerialMessageCallback) {
    const id = this._subscribers.size;
    this._subscribers.set(id, callback);

    return () => {
      this._subscribers.delete(id);
    };
  }

  public async write(chunk?: string) {
    if (!this._writer) {
      throw new Error('no port.writable!');
    }
    const writer = this._writer.getWriter();

    try {
      console.debug('[write]', chunk);
      await writer.write(chunk);
      console.debug('[write] done', chunk);
    } catch (e) {
      console.debug('error writing [%o]\n', chunk, e);
    } finally {
      writer.releaseLock();
    }
  }

  private async checkPort(): Promise<boolean> {
    if (this.portState !== 'open') {
      return false;
    }

    return new Promise((resolve) => {
      const clearSubscription = this.subscribe(() => {
        clearTimeout(timeout);
        resolve(true);
      });

      const timeout = setTimeout(() => {
        clearSubscription();
        resolve(false);
      }, this._state.autoSelectPortTimeout);

      //todo: extract to config file and check response?
      this.write('ping\n');
    });
  }

  private async init(): Promise<boolean> {
    if (this.portState !== 'open') {
      return false;
    }

    console.info('init: opened port');
    await this._reader?.cancel();
    await this._readerClosedPromise.then(() => {
      if (!this._aborted) {
        this._reader = null;
        this._readerClosedPromise = this._readUntilClosed();
      }
    });

    await this._writer?.abort();
    await this._writerClosedPromiseRef.then(() => {
      if (!this._aborted) {
        this._writer = null;
        this._writerClosedPromiseRef = this._writeUntilClosed();
      }
    });

    //TODO: getSignals() and react accordingly

    if (this._info.preferAutoPortSelect) {
      console.info('init: in auto select mode -> check if port is ok');
      const isPortOK = await this.checkPort();
      if (!isPortOK) {
        console.info('init: port checked, not good!, try again..');
        //disconnect from current port
        await this.manualDisconnectFromPort();
        return this._autoConnect();
      }
    }

    return true;
  }

  public clear() {
    this._aborted = true;
    navigator.serial.removeEventListener('disconnect', this._onPortDisconnect.bind(this));
    navigator.serial.removeEventListener('connect', this._onPortConnect.bind(this));
  }

  private async _onPortDisconnect(e: Event) {
    const port = e.target as SerialPort;
    if (!this._info.isDesiredDevice(port)) {
      return;
    }

    this._state.nextAutoPortIndex = -1;
    await this._disconnectPort();
  }

  private async _onPortConnect(e: Event) {
    const port = e.target as SerialPort;
    console.debug('onPortConnet', port, this.port);

    clearTimeout(this._onConnectEventTimer);
    this._onConnectEventTimer = setTimeout(() => {
      this._autoConnect();
    }, 100);
  }

  async manualConnectToPort() {
    try {
      const filters: SerialPortFilter[] = [
        {
          usbVendorId: this._info.vendorId,
          usbProductId: this._info.productId,
        },
      ];

      this.port = await navigator.serial.requestPort({ filters });

      return await this.openPort();
    } catch (error) {
      console.error('User did not select port');
    }
    return false;
  }

  async manualDisconnectFromPort() {
    console.info('manualDisconnectFromPort..');
    if (this.portState !== 'open' || !this.port) {
      console.info('manualDisconnectFromPort.. exit, condition does not match');
      return;
    }

    await this._disconnectPort();
  }

  private async _disconnectPort() {
    await this._reader?.cancel().catch(console.warn);
    await this._readerClosedPromise;
    this._reader = null;
    this._readerClosedPromise = Promise.resolve();

    await this._writer?.abort().catch(console.warn);
    await this._writerClosedPromiseRef;
    this._writer = null;
    this._writerClosedPromiseRef = Promise.resolve();

    await this.port?.close().catch(console.warn);
    this.port = null;
    this.portState = 'close';
  }

  private async _autoConnect() {
    console.info('auto connect..');
    if (this.portState === 'open') {
      console.info('auto connect.. break');
      return false;
    }

    const availablePorts = await navigator.serial.getPorts();
    const ports = availablePorts.filter(this._info.isDesiredDevice);

    if (this._info.preferAutoPortSelect) {
      this._state.isAutoSelectingPort = true;
      this._state.nextAutoPortIndex += 1;
      if (this._state.nextAutoPortIndex >= ports.length) {
        console.warn('can not auto select port :/');
        return false;
      }

      console.info('auto selected port %o, checking..', this._state.nextAutoPortIndex);
      this.port = ports[this._state.nextAutoPortIndex];
    } else {
      this.port = ports.find(this._info.filterPorts);
    }

    if (!this.port) {
      return false;
    }

    try {
      return await this.openPort();
    } catch (e) {
      console.error('SerialDevice:_autoConnect =>', e);
    }

    return false;
  }

  private async openPort() {
    if (!this.port) {
      return false;
    }

    try {
      try {
        await this.port.open(this._info.options);
      } catch (e: unknown) {
        const isPortAlreadyOpened = e?.toString().includes('The port is already open');
        console.warn('isPortAlreadyOpened', isPortAlreadyOpened);
        if (!isPortAlreadyOpened) {
          throw e;
        }
      }

      this.portState = 'open';
      return await this.init();
    } catch (error) {
      this.portState = 'close';
      console.error('SerialDevice: could not open port', error);
      return false;
    } finally {
      this.isLoading = false;
    }
  }

  private async _readUntilClosed() {
    if (!this.port?.readable) {
      return;
    }

    const textDecoder = new TextDecoderStream();
    const readableStreamClosed = this.port.readable.pipeTo(textDecoder.writable);
    this._reader = this._info.customReadableTransformer(textDecoder.readable).getReader();

    try {
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { value, done } = await this._reader.read();

        if (done) {
          break;
        }
        const timestamp = Date.now();
        console.info('[read][%o]', timestamp, value);
        this._subscribers.forEach((callback) => callback({ value, timestamp }));
      }
    } catch (error) {
      if (`${error}`.includes('The device has been lost')) {
        await this.manualDisconnectFromPort().catch(() => {});
      } else {
        console.error(error);
      }
    } finally {
      this._reader.releaseLock();
    }

    await readableStreamClosed.catch(() => {});
  }

  private async _writeUntilClosed() {
    if (!this.port?.writable) {
      return;
    }

    const textEncoder = new TextEncoderStream();
    const writableStreamClosed = textEncoder.readable.pipeTo(this.port.writable);
    this._writer = textEncoder.writable;

    await writableStreamClosed.catch(async (error) => {
      if (`${error}`.includes('The device has been lost')) {
        await this.manualDisconnectFromPort().catch(() => {});
      } else {
        console.error(error);
      }
    });
  }
}
