export type LRUCacheState<K extends string | number, V> = {
  capacity: number;
  cache: Record<K, V>;
  order: K[];
};

type LRUCacheOperations<K extends string | number, V> =
  | { type: 'get'; key: K }
  | { type: 'put'; key: K; value: V };

// LRU cache implementation for serializable state. The lru returns a new state
export function lru<K extends string | number, V>(
  state: LRUCacheState<K, V>,
  operation: LRUCacheOperations<K, V>
): LRUCacheState<K, V> {
  const newState: LRUCacheState<K, V> = {
    capacity: state.capacity,
    cache: { ...state.cache },
    order: [...state.order],
  };

  switch (operation.type) {
    case 'get':
      if (operation.key in newState.cache) {
        // Move the accessed key to the end of the order array
        newState.order = newState.order.filter((k) => k !== operation.key);
        newState.order.push(operation.key);
      }
      break;

    case 'put':
      if (operation.key in newState.cache) {
        // If key exists, remove it from the current position in order
        newState.order = newState.order.filter((k) => k !== operation.key);
      } else if (newState.order.length >= newState.capacity) {
        // If at capacity, remove the least recently used item
        const lruKey = newState.order.shift()!;
        delete newState.cache[lruKey];
      }
      // Add new key-value pair and update order
      newState.cache[operation.key] = operation.value;
      newState.order.push(operation.key);
      break;
  }

  return newState;
}
