import { CardProgManager } from '@/utils/CardProg/CardProgManager';
import { BarcodeInput } from '@/utils/KeyInput/Barcode';

const GlobalInitializers = {
  barcodeInput: () => new BarcodeInput(),
  cardProgMgr: () => new CardProgManager(),
} as const;

export const getGlobal = <K extends keyof typeof GlobalInitializers>(
  key: K
): ReturnType<(typeof GlobalInitializers)[K]> => {
  if (!(key in GlobalInitializers)) {
    console.error(
      `Attempted to initialize undefined global name ${key}, which is not registered in the GlobalInitializers list`
    );
  }

  if (!(key in window) || !window[key as any]) {
    /* @ts-ignore */
    window[key] = GlobalInitializers[key]();
  }
  /* @ts-ignore */
  return window[key as any] as T;
};
