// Save execution progress in case of failure due to network issues or other reasons.

import { IStageController, IState } from '@/store/interfaces';
import { ControllerDependencies } from '@/store/interfaces/dependencies';
import { IStage } from '@/store/interfaces/stages/generic';
import { ExecutionState, WorkbenchExecution, WorkOrderKind } from '@/utils/api/fab.types';
import { Logger } from '@/logger';
import { getNextStage, getStage } from './stage-map';

const logger = new Logger('Controller:Executor');

// This logic allow retrying the execution from the last successful stage.
export function createRetryableExecutor<T extends WorkOrderKind>(
  state: IState<IStageController<T>>,
  api: Pick<ControllerDependencies, 'api'>['api'],
  executionData: WorkbenchExecution
) {
  logger.addMetadata({ executionId: executionData.id });

  let prevStage: IStage;
  let nextStage: IStage | null;

  return {
    initExecution: (prevStageType: ExecutionState) => {
      logger.info('Initializing execution', { prevStageType });
      state.set((controller) => {
        if (!controller.execState) {
          logger.info('Initializing execution state');
          const _nextStage = getNextStage(prevStageType, controller.stages, controller.kind);
          const nextStageType = _nextStage && _nextStage.type;
          controller.execState = getInitialExecState(prevStageType, nextStageType);
        }
      });

      const _controller = state.get();

      prevStage = getStage(_controller.execState!.prevStage, _controller.stages);
      nextStage =
        _controller.execState!.nextStage &&
        getStage(_controller.execState!.nextStage, _controller.stages);
    },
    prevStage: {
      onExit: async () => {
        logger.info('Executing prevStage onExit');
        const _controller = state.get();
        if (_controller.execState?.hasRunPrevStageOnExit) {
          logger.info('prevStage onExit already executed');
          return;
        }
        try {
          await prevStage.onExit();
          state.set((controller) => {
            controller.execState!.hasRunPrevStageOnExit = true;
          });
          logger.info('prevStage onExit executed');
        } catch (error) {
          logger.error('Error on prevStage onExit: ', error);
          throw error;
        }
      },
      onPostExit: async () => {
        logger.info('Executing prevStage onPostExit');
        const _controller = state.get();
        if (_controller.execState?.hasRunPrevStageOnPostExit) {
          logger.info('prevStage onPostExit already executed');
          return;
        }
        try {
          await prevStage.onPostExit();
          state.set((controller) => {
            controller.execState!.hasRunPrevStageOnPostExit = true;
          });
          logger.info('prevStage onPostExit executed');
        } catch (error) {
          logger.error('Error on prevStage onPostExit: ', error);
          throw error;
        }
      },
    },
    nextStage: {
      onEnter: async () => {
        logger.info('Executing nextStage onEnter');
        const _controller = state.get();
        if (_controller.execState?.hasRunNextStageOnEnter) {
          logger.info('nextStage onEnter already executed');
          return;
        }
        try {
          await nextStage!.onEnter();
          state.set((controller) => {
            controller.execState!.hasRunNextStageOnEnter = true;
          });
          logger.info('nextStage onEnter executed');
        } catch (error) {
          logger.error('Error on nextStage onEnter: ', error);
          throw error;
        }
      },
    },
    moveToNextStage: async () => {
      logger.info('Moving to next stage');
      const _controller = state.get();
      if (_controller.execState?.hasMovedToNextStage) {
        logger.info('Already moved to next stage');
        return;
      }
      try {
        if (!prevStage) {
          throw new Error('Invalid stages');
        }
        const advanceParams = {
          nextState: nextStage?.type || ExecutionState.Finished,
          body: prevStage.getPayload(),
          id: executionData.id.toString(),
        };

        const newExecution = await api.workbenches.executions.advance(advanceParams);
        nextStage = getStage(newExecution.state, _controller.stages);
        state.set((controller) => {
          controller.execState!.hasMovedToNextStage = true;
          // Copy the recipe to the new execution state. The response for output stage doesn't have the recipe.
          newExecution.recipe = newExecution.recipe || controller.executionData.recipe;
          controller.executionData = newExecution;
          // The api is providing the next stage
          controller.execState!.nextStage = newExecution.state;
          controller.currentStageType = newExecution.state;
        });
        logger.info('Moved to next stage');
      } catch (error) {
        logger.error('Error advancing execution: ', error);
        // If advancing fails, reset completion to allow the state to be fixed. Ex: Scan noaid in cardprog
        await _controller.getCurrentStage().onCompletionFailed?.();

        throw error;
      }
    },
    onExecutionDataUpdated: async () => {
      logger.info('Executing onExecutionDataUpdated');
      const _controller = state.get();
      if (_controller.execState?.hasRunOnExecutionDataUpdated) {
        logger.info('onExecutionDataUpdated already executed');
        return;
      }
      try {
        for (const stage of Object.values(_controller.stages)) {
          if (stage.onExecutionDataUpdated) {
            await stage.onExecutionDataUpdated(_controller.executionData);
          }
        }
        state.set((controller) => {
          controller.execState!.hasRunOnExecutionDataUpdated = true;
        });
        logger.info('onExecutionDataUpdated executed');
      } catch (error) {
        logger.error('Error onExecutionDataUpdated: ', error);
        throw error;
      }
    },
    isFinishStage: () => {
      const _controller = state.get();
      return _controller.currentStageType === ExecutionState.Finished;
    },
    finishExecution: (completedStage: ExecutionState) => {
      logger.info('Finishing execution', { completedStage });
      state.set((controller) => {
        controller.execState = undefined;
        controller.completionQueue = controller.completionQueue.filter((s) => s !== completedStage);
        controller.completedStages = [...controller.completedStages, completedStage];
      });
    },
  };
}

function getInitialExecState(
  currentStageType: ExecutionState,
  nextStageType: ExecutionState | null
): IStageController['execState'] {
  return {
    hasMovedToNextStage: false,
    hasRunOnExecutionDataUpdated: false,
    hasRunPrevStageOnExit: false,
    hasRunPrevStageOnPostExit: false,
    hasRunNextStageOnEnter: false,
    prevStage: currentStageType,
    nextStage: nextStageType,
  };
}
