import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { devPatch } from '@/utils/dev.patch';
import App from './App';

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: 'https://3fefc3d3932b35a5c9583cbc9987608c@o394445.ingest.us.sentry.io/4507499883593728',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 0.05,          //  Capture 5% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: [/https:\/\/fab-workbench\.j\.persidius\.(com|dev)/],
  replaysSessionSampleRate: 0.01,   // 1% of sessions replayed
  replaysOnErrorSampleRate: 1.0,    // 100% of errors replayed
});

devPatch();

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
