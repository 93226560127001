import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import equal from 'fast-deep-equal/react';
import { isString } from '@/utils/fns';

export const StorageKeys = {
  WorkbenchId: 'wb.id',
  AutoAssign: 'wb.autoAssign',
} as const;

export function useFabLocalStorage<T = string>(
  key: string,
  initialValue?: T
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T | undefined>(
    () =>
      (localStorage.getItem(key) === null
        ? initialValue
        : fromStore<T>(localStorage.getItem(key), initialValue!)) as T
  );

  useEffect(() => {
    // console.debug('useFabLocalStorage valueUpdate [%o] setting value to [%o]', key, value);
    localStorage.setItem(key, toStore(value));
  }, [value]);

  const handleStorage = useCallback(
    (event: StorageEvent) => {
      if (event.key === key && !equal(fromStore(event.newValue, initialValue), value)) {
        setValue(fromStore(event.newValue, initialValue) as T);
      }
    },
    [value]
  );

  useEffect(() => {
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, [handleStorage]);

  return [value as T, setValue as Dispatch<SetStateAction<T>>];
}

export function useFabSessionStorage<T = string>(
  key: string,
  initialValue?: T
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T | undefined>(
    () =>
      (sessionStorage.getItem(key) === null
        ? initialValue
        : fromStore<T>(sessionStorage.getItem(key), initialValue!)) as T
  );

  useEffect(() => {
    sessionStorage.setItem(key, toStore(value));
  }, [value]);

  return [value as T, setValue as Dispatch<SetStateAction<T>>];
}

// TODO: check value is same type as fallback value
// and if not -> return fallback value.
function fromStore<T>(val: string | null, fallbackValue: T): T {
  if (!isString(val)) {
    return fallbackValue;
  }

  try {
    return JSON.parse(val) as T;
  } catch (e) {
    return fallbackValue;
  }
}

function toStore(val: any): string {
  try {
    return JSON.stringify(val);
  } catch (e) {
    return val;
  }
}

export function fabReadFromLocalStorage<T>(key: string, fallbackValue?: T): T {
  return fromStore<T>(localStorage.getItem(key), fallbackValue!);
}

export function fabWriteToLocalStorage<T>(key: string, value: T) {
  return localStorage.setItem(key, toStore(value));
}
