import { QueryClient } from '@tanstack/react-query';
import Decimal from 'decimal.js';
import { Config } from '@/config';
import { ExecutionRenderData } from '@/pages/debug/NewExe/types';
import { WorkbenchExecution } from '@/utils/api/fab.types';
import { log } from '@/utils/log';
import { Execution } from './Execution';
import { ExecutionIO } from './ExecutionIO';

// execution has an update callback that is called
const EVENT_UPDATED = 'updated';

const EmptyRenderData = {
  outputs: [],
  inputs: [],
};

export class ExecutionRenderStore extends EventTarget {
  e: Execution;
  io: ExecutionIO;
  q: QueryClient; //todo: just use external queryClient someday
  loading: boolean = true;
  cachedSnapshot: ExecutionRenderData | null;
  constructor(q: QueryClient) {
    super();
    this.q = q;
    this.e = new Execution(this);
    this.io = new ExecutionIO(this);
    this.cachedSnapshot = null;
  }

  get execution() {
    return this.e;
  }

  dispatchUpdate = () => {
    this.cachedSnapshot = null;
    this.dispatchEvent(new Event(EVENT_UPDATED));
  };

  async load(data: WorkbenchExecution, q: QueryClient) {
    log.info('ExecutionRenderStore::load()', data);
    this.q = q;
    this.loading = true;
    await this.e.load(data, q);
    this.loading = false;
    this.dispatchUpdate();
  }

  private getInputData(): ExecutionRenderData['inputs'] {
    const input = this.e.getInput();
    if (!input) {
      return EmptyRenderData.inputs;
    }

    return Object.values(input.data.parts).map((part, index) => {
      const [valid, actionText] = input.getLoadedInputStatus(part.partId);
      const cmd = (101 + index).toString();
      const focused = this.io.isFocused(cmd);
      const loadedParts = part.loaded.map((loaded) => ({
        qty: loaded.qty.toFixed(Config.decimalPrecision),
        identifier: loaded.identifier,
      }));
      const loadedQty = part.loaded.reduce((acc, loaded) => acc.add(loaded.qty), new Decimal(0));

      return {
        partName: part.partName,
        reqQty: part.reqQty.toFixed(Config.decimalPrecision),
        loadedQty: loadedQty.toFixed(Config.decimalPrecision),
        serialized: part.serialized,
        valid,
        focused,
        cmd,
        actionText,
        loadedParts,
      };
    });
  }

  private getOutputData(): ExecutionRenderData['outputs'] {
    const output = this.e.output?.data;
    if (!output) {
      return EmptyRenderData.outputs;
    }

    const { serialized } = output;
    const outputParts = output.parts || [];
    return outputParts.map((outputPart, index) => {
      const cmd = (201 + index).toString();
      const focused = this.io.isFocused(cmd);

      const barcodeScan = {
        required: output.scanRequired,
        done: outputPart.scanned,
      };

      const { identifier } = outputPart;
      const valid = barcodeScan.required ? barcodeScan.done : true;
      return {
        cmd,
        valid,
        barcodeScan,
        focused,
        serialized,
        identifier,
        partName: output.partName,
        qty: outputPart.qty.toFixed(Config.decimalPrecision),
      };
    });
  }

  private getCardProgData(): ExecutionRenderData['cardProg'] {
    const { cardProg } = this.e;

    if (cardProg) {
      return {
        liveCardStatus: cardProg.data.liveCardStatus,
        liveCardUid: cardProg.data.liveCardUid,
        boxSerialNumber: cardProg.data.boxSerialNumber,
        cardId: cardProg.data.cardId,
        noaId: cardProg.data.noaId,
        cardUid: cardProg.data.cardUid,
      };
    }

    return {
      liveCardStatus: 'missing',
    };
  }

  private getErrors(): ExecutionRenderData['errors'] {
    type ErrObj = Record<string, string | undefined>;
    const hasErrors = (obj?: ErrObj) => obj && Object.keys(obj).length > 0;
    const getErrors = (obj?: ErrObj) => (hasErrors(obj) ? obj : undefined);

    return {
      output: getErrors(this.e.output?.errors),
      execution: getErrors(this.e.errors),
      io: getErrors(this.io.errors),
      cardProg: getErrors(this.e.cardProg?.errors),
    };
  }

  private getExecutionCount(): ExecutionRenderData['executionCount'] {
    return (this.e.config.finished?.restartCounterOn ?? -1) > 0
      ? {
          total: this.e.config.finished?.restartCounterOn,
          current: this.e.finished?.data.executedCounter,
        }
      : null;
  }

  subscribe = (callback: () => void) => {
    this.addEventListener(EVENT_UPDATED, callback);
    return () => {
      this.removeEventListener(EVENT_UPDATED, callback);
    };
  };

  snapshot = () => {
    if (!this.cachedSnapshot) {
      this.cachedSnapshot = {
        recipeName: this.e.recipeName,
        loading: this.loading,
        hasCardProg: this.e.isCardProgWorkKind(),
        inputs: this.getInputData(),
        activeStage: this.e.getCurrentStage(),
        outputs: this.getOutputData(),
        cardProg: this.getCardProgData(),
        errors: this.getErrors(),
        executionCount: this.getExecutionCount(),
      };
    }

    return this.cachedSnapshot;
  };
}
