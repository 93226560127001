import { ILogger, LogLevel } from './interfaces';

// Define a global variable to store the console-set log level
let globalLogLevel: LogLevel | null = null;

// Function to set the global log level from console
export function setGlobalLogLevel(level: LogLevel): void {
  globalLogLevel = level;
  console.log(`Global log level set to: ${level}`);
}

export function getGlobalLogLevel(): LogLevel | null {
  return globalLogLevel;
}

// Add the function to the window object
if (typeof window !== 'undefined') {
  // @ts-expect-error - Allow assignment to window object
  window.setGlobalLogLevel = setGlobalLogLevel;
  // @ts-expect-error - Allow assignment to window object
  window.getGlobalLogLevel = getGlobalLogLevel;
}

export class Logger implements ILogger {
  private level: LogLevel;
  private metadata: Record<string, unknown>;
  private moduleName: string;
  private timers: Map<string, number>;

  constructor(moduleName: string = 'root', defaultLevel: LogLevel = LogLevel.WARN) {
    this.level = globalLogLevel || defaultLevel;
    this.metadata = {};
    this.moduleName = moduleName;
    this.timers = new Map();
  }

  private shouldLog(level: LogLevel): boolean {
    const levels = Object.values(LogLevel);
    return levels.indexOf(level) >= levels.indexOf(globalLogLevel || this.level);
  }

  private formatMessage(level: LogLevel, message: string, args: unknown[]): string {
    const timestamp = new Date().toISOString();
    const formattedArgs = args
      .map((arg) => (typeof arg === 'object' ? JSON.stringify(arg) : String(arg)))
      .join(' ');
    return `[${timestamp}] [${level.toUpperCase()}] [${
      this.moduleName
    }] ${message} ${formattedArgs}`;
  }

  debug(message: string, ...args: unknown[]): void {
    if (this.shouldLog(LogLevel.DEBUG)) {
      console.debug(this.formatMessage(LogLevel.DEBUG, message, args), this.metadata);
    }
  }

  info(message: string, ...args: unknown[]): void {
    if (this.shouldLog(LogLevel.INFO)) {
      console.info(this.formatMessage(LogLevel.INFO, message, args), this.metadata);
    }
  }

  warn(message: string, ...args: unknown[]): void {
    if (this.shouldLog(LogLevel.WARN)) {
      console.warn(this.formatMessage(LogLevel.WARN, message, args), this.metadata);
    }
  }

  error(message: string, ...args: unknown[]): void {
    if (this.shouldLog(LogLevel.ERROR)) {
      console.error(this.formatMessage(LogLevel.ERROR, message, args), this.metadata);
    }
  }

  log(level: LogLevel, message: string, context?: Record<string, unknown>): void {
    if (this.shouldLog(level)) {
      const logMethod = console[level] || console.log;
      logMethod(this.formatMessage(level, message, []), {
        ...this.metadata,
        ...context,
      });
    }
  }

  startTimer(label: string): void {
    this.timers.set(label, performance.now());
    this.debug(`Timer started: ${label}`);
  }

  endTimer(label: string): void {
    const startTime = this.timers.get(label);
    if (startTime === undefined) {
      this.warn(`Timer "${label}" does not exist.`);
      return;
    }

    const endTime = performance.now();
    const duration = endTime - startTime;
    this.timers.delete(label);

    this.info(`Timer ended: ${label}`, {
      duration: `${duration.toFixed(2)}ms`,
      label,
    });
  }

  setLogLevel(level: LogLevel): void {
    this.level = level;
  }

  addMetadata(metadata: Record<string, unknown>): void {
    this.metadata = { ...this.metadata, ...metadata };
  }

  child(options: { module: string }): ILogger {
    return new Logger(`${this.moduleName}:${options.module}`, this.level);
  }
}
