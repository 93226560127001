import { Button } from '@mantine/core';
import { QRCodeCanvas } from 'qrcode.react';
import { useEffect } from 'react';
import { BrotherQL } from '@/utils/BrotherQL/BrotherQL';
import { useBrotherQLDevice } from '@/utils/BrotherQL/hooks';

const printer = new BrotherQL();

export function TestPrintButton() {
  const brotherDevice = useBrotherQLDevice();

  let canvas: HTMLCanvasElement;
  let canvasQr: HTMLCanvasElement;
  let ctx: CanvasRenderingContext2D;
  useEffect(() => {
    canvas = document.getElementById('canvas') as HTMLCanvasElement;
    canvasQr = document.getElementById('qr_canvas') as HTMLCanvasElement;
    ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  });

  const draw = async () => {
    const width = 128;
    const height = 708; //auto?

    canvas.width = width;
    canvas.height = height;

    ctx.drawImage(canvasQr, 0, 0, 128, 128);

    ctx.font = '32px monospace';
    ctx.fillText('X1QGTL', 4, 128 + 32 + 4);
  };

  /*const onDraw3 = async () => {
    const width = 708;
    const height = 708; //auto?

    canvas.width = width;
    canvas.height = height;

    ctx.drawImage(canvasQr, 50, 0, 600, 600);

    ctx.font = '120px monospace';
    ctx.fillText('X1QGTL', 150, 650 + 32 + 15);
  };*/

  const onPrint = async () => {
    await draw();

    if (!brotherDevice) {
      console.error('no device!');
      return;
    }

    const status = await printer.start(brotherDevice);
    console.log('status', status);

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const rasterLines = await printer.convertImageToRasterLines(
      new Uint8Array(imageData.data.buffer),
      canvas.width
    );

    await printer.print(rasterLines);
  };

  return (
    <>
      <Button variant="light" color="green" onClick={onPrint}>
        Test print QR
      </Button>

      <QRCodeCanvas
        value="X1QGTL"
        id="qr_canvas"
        bgColor="transparent"
        size={708}
        style={{ display: 'none' }}
      />
      <canvas
        id="canvas"
        style={{ border: '2px solid black', background: 'white', display: 'none' }}
        width={400}
        height={708}
      ></canvas>
    </>
  );
}
