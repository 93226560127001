import { QueryClient } from '@tanstack/react-query';
import Decimal from 'decimal.js';
import { type ExecutionRecipeConfig } from '@/pages/debug/NewExe/types';
import { fabApi } from '@/config';
import {
  ExecutionOutputBody,
  ExecutionState,
  PartIdentifier,
  WorkbenchExecution,
} from '@/utils/api/fab.types';
import { BarcodeType } from '@/utils/BrotherQL/types';
import { isStringWithLength, wait } from '@/utils/fns';
import { LabelPrinter } from '@/utils/LabelPrinter/LabelPrinter';
import { log } from '@/utils/log';
import { jsonDeserialize, jsonSerialize, partIdEq } from '../util';

const DATA_VERSION = 1;
type Data = {
  version: number;
  executionId: number;
  partId: number;
  partName: string;
  scanRequired: boolean;
  serialized: boolean;
  artefacts: number[];
  parts: Array<{
    qty: Decimal;
    identifier: PartIdentifier;
    scanned: boolean;
  }>;
};

const STORAGE_KEY = 'exe.output';
type ErrorKeys = 'printer' | string;

export class ExecutionOutput {
  data: Data;
  config: ExecutionRecipeConfig;
  private readonly labelPrinter: LabelPrinter | null = null;
  private readonly shouldPrint: boolean = false;
  errors: Partial<Record<ErrorKeys, string>> = {};
  private readonly dispatchUpdate: () => any;
  private _printCache: Record<string, boolean> = {};

  constructor(data: Data, config: ExecutionRecipeConfig, dispatchUpdate: () => any) {
    this.data = data;
    this.config = config;
    this.dispatchUpdate = dispatchUpdate;

    this.shouldPrint = this.config.output?.serialNumberAction === 'generate';
    if (this.shouldPrint) {
      this.labelPrinter = new LabelPrinter({ onPrinterChangeState: this.onPrinterChangeState });
      this.labelPrinter.init().catch((error) => {
        this.error('printer', error.message);
      });
    }
  }

  onPrinterChangeState = (isPrinterReady: boolean) => {
    log.info('ExecutionOutput::onPrinterChangeState [isPrinterReady=%o]', isPrinterReady);
    if (isPrinterReady) {
      this.error('printer', null);
      this.print();
    } else {
      this.error('printer', LabelPrinter.ERRORS.NO_PRINTER);
    }
  };

  error = (key: keyof typeof this.errors, msg: string | null) => {
    if (msg === null) {
      this.errors[key] = undefined;
    } else {
      this.errors[key] = msg;
    }
    this.dispatchUpdate();
  };

  private saveData() {
    if (this.data) {
      sessionStorage.setItem(STORAGE_KEY, JSON.stringify(this.data, jsonSerialize));
    }
  }

  private static loadDataFromStorage(executionId: number): Data | null {
    const serializedData = sessionStorage.getItem(STORAGE_KEY);
    if (!serializedData) {
      return null;
    }

    try {
      const data = JSON.parse(serializedData, jsonDeserialize) as Data;
      if (data.executionId !== executionId || data.version !== DATA_VERSION) {
        return null;
      }
      return data;
    } catch (_) {
      return null;
    }
  }

  static async load(
    data: WorkbenchExecution,
    dispatchUpdate: () => any,
    q: QueryClient,
    config: ExecutionRecipeConfig
  ) {
    const savedData = ExecutionOutput.loadDataFromStorage(data.id);
    if (savedData) {
      return new ExecutionOutput(savedData, config, dispatchUpdate);
    }

    const outputParts = await q.fetchQuery({
      queryKey: [fabApi.parts.key, data.recipe!.output_part_id],
      queryFn: () => fabApi.parts.byIds(data.recipe!.output_part_id.toString()),
    });
    //todo: wtf? sometimes server returns array sometimes object?
    const outputPart = Array.isArray(outputParts) ? outputParts[0] : outputParts;

    let parts = [];
    if (outputPart.serialized) {
      const partNumDecimal = new Decimal(data.recipe!.output_part_qty).div(new Decimal(1));
      if (!partNumDecimal.isInt()) {
        throw new Error('Output part quantity is fractional, not supported');
      }
      const partNum = partNumDecimal.toNumber();

      parts = [];
      for (let i = 0; i < partNum; i += 1) {
        parts.push({
          qty: new Decimal(1),
          identifier: {},
          scanned: false,
        });
      }
    } else {
      throw new Error('support for lot-numbered output parts not implemented');
    }

    // todo: handle case where output is with lot number!!!
    const scanRequired =
      config.output?.scanRequired ||
      (outputPart.serialized && config.output?.serialNumberAction === 'generate');

    const loadedData = {
      version: DATA_VERSION,
      executionId: data.id,
      partId: outputPart.id,
      serialized: outputPart.serialized,
      partName: outputPart.description || 'N/A',
      artefacts: [],
      scanRequired,
      parts,
    };

    const obj = new ExecutionOutput(loadedData, config, dispatchUpdate);
    obj.saveData();
    return obj;
  }

  async enter(q: QueryClient) {
    let updated = false;
    if (this.data.serialized) {
      const serialNumberAction = this.config.output?.serialNumberAction || 'generate';
      for (const part of this.data.parts) {
        if (!part.identifier.serial_number) {
          if (serialNumberAction === 'generate') {
            const sn = await q.fetchQuery({
              queryKey: [fabApi.serialNumbers.key, this.data.executionId],
              queryFn: () => fabApi.serialNumbers.create({ part_id: this.data.partId }),
              staleTime: 1,
            });
            part.identifier.serial_number = sn.serial_number;
            updated = true;
          }
        }
      }
    }

    this.print();

    if (updated) {
      this.saveData();
    }
  }

  private async print() {
    const {
      labelPrefix = 'sn',
      codeType = BarcodeType.QRCODE,
      validLabelSizes = [12, 29, 62],
    } = this.config.output?.print ?? {};

    if (!this.shouldPrint) {
      return;
    }

    if (!this.labelPrinter) {
      this.error('printer', 'Printer was not initialized');
      return;
    }

    log.debug('waiting for printer ..');
    const isPrinterReady = await this.labelPrinter.isInitActive;
    log.debug('waiting for printer -> [isPrinterReady=%o]', isPrinterReady);

    const labelSize = this.labelPrinter.status?.media?.width;
    if (!validLabelSizes.includes(labelSize as any)) {
      //todo: translate error message?
      this.error(
        'printer',
        `invalid label size "${labelSize ?? 'unknown'}"; allowed label size: ${validLabelSizes.join(', ')}`
      );
      return;
    }

    //todo: check if auto-print is ON
    for (const part of this.data.parts) {
      const sn = part.identifier.serial_number;
      if (!part.scanned && isStringWithLength(sn) && !this._printCache[sn]) {
        this._printCache[sn] = true;
        await this.labelPrinter
          .print({
            labelText: sn,
            barcodeValue: [labelPrefix, sn].join(':'),
            barcodeType: codeType,
          })
          .then(() => wait(1_000))
          .catch((e) => {
            this._printCache[sn] = false;
            this.error('printer', e.message);
          });
      }
    }
  }

  afterExit() {
    this.data.artefacts = [];
  }

  getAdvanceBody(): ExecutionOutputBody {
    const parts = this.data.parts.map((part) => ({
      qty: part.qty.toString(),
      identifier: part.identifier,
    }));

    return {
      name: ExecutionState.Output,
      artefacts: this.data.artefacts,
      part_id: this.data.partId,
      parts,
    };
  }

  onScanPart(identifier: PartIdentifier): boolean {
    log.info('ExecutionOutput::onScanPart [identifier=%o]', identifier);
    const { serialNumberAction = 'generate' } = this.config.output ?? {};

    switch (serialNumberAction) {
      case 'generate': {
        const part = this.data?.parts.filter((p) => partIdEq(p.identifier, identifier)) || [];
        if (part.length === 1) {
          if (!part[0].scanned) {
            part[0].scanned = true;

            return true;
          }
        }
        break;
      }

      case 'useExisting': {
        const snAlreadyUsed = this.data.parts.some((p) => partIdEq(p.identifier, identifier));
        log.info('ExecutionOutput::onScanPart serial number already used[%o]', snAlreadyUsed);
        if (snAlreadyUsed) {
          return false;
        }

        const firstUnfilledPart = this.data.parts.find(
          (p) => !isStringWithLength(p.identifier.serial_number)
        );

        if (!firstUnfilledPart) {
          log.warn('ExecutionOutput::onScanPart firstUnfilledPart[nope] - REWRITING first one');
        }

        this.data.parts[0].identifier = identifier;
        this.data.parts[0].scanned = true;

        return true;
      }
    }

    return false;
  }

  isReady(): boolean {
    // todo: generated, etc.
    log.info('ExecutionOutput::isReady() [scanRequired=%o]', this.data.scanRequired);
    if (this.data.scanRequired) {
      if (!Object.values(this.data.parts).every((part) => part.scanned)) {
        return false;
      }
    }

    log.info('ExecutionOutput::isReady() => %o', true);
    return true;
  }
}
