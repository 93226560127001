import { Group, Title, Text } from '@mantine/core';
import type { ReactNode } from 'react';
import { StageTitle } from '@/pages/debug/NewExe/ui/StageTitle';
import { ExecutionRenderData } from '@/pages/debug/NewExe/types';

type ExecutionTitleProps = Pick<ExecutionRenderData, 'recipeName' | 'activeStage'> & {
  append?: ReactNode;
};
export function ExecutionTitle(props: ExecutionTitleProps) {
  const { recipeName, activeStage, append } = props;

  return (
    <Group align="flex-end">
      <Title order={4}>
        Task:
        <Text
          ml="2px"
          inherit
          fw={900}
          variant="gradient"
          gradient={{ from: 'blue', to: 'cyan' }}
          span
        >
          {recipeName}
        </Text>
      </Title>
      <Title order={5}>
        Stage: <StageTitle stage={activeStage} />
      </Title>
      {append}
    </Group>
  );
}
