import { IStageController, IState } from '../../interfaces';
import { IInputStage } from '../../interfaces/stages/input';
import { IOutputStage } from '../../interfaces/stages/output';
import { createEncapsulatedState } from './state-factory';
import { Logger } from '../../../logger';
import { IStageFactory, IStagesDataLoader, StageConfig } from '../../interfaces/stages';
import { IApi } from '../../interfaces/api';
import {
  ExecutionState,
  PartIdentifier,
  WorkbenchExecution,
  WorkOrderKind,
} from '@/utils/api/fab.types';
import { ICardProgStage } from '../../interfaces/stages/card-prog';
import { ControllerDependencies, IControllerIO } from '@/store/interfaces/dependencies';
import { createRetryableExecutor } from './executor';
import { getStage } from './stage-map';
import { IQaStage } from '@/store/interfaces/stages/qa';
import { createErrorHandler } from '@/store/utils/error-handler';

const logger = new Logger('Controller');

// TODO: Create better dependencies interface
interface CreateControllerParams<T extends WorkOrderKind> extends ControllerDependencies {
  state: IState<IStageController<T>>;
  io: IControllerIO;
  executionKind: T;
  restoredController?: IStageController<T> | null;
  onCompletion: () => void;
}

export async function createController<T extends WorkOrderKind = WorkOrderKind.Default>({
  executionData,
  config,
  loader,
  api,
  state,
  io,
  stageFactory,
  executionKind,
  restoredController,
  onCompletion,
}: CreateControllerParams<T>): Promise<IStageController<T>> {
  logger.addMetadata({ executionId: executionData.id });
  logger.debug('Creating controller', { executionData, config, executionKind });

  const errorHandler = createErrorHandler(state, logger);

  let executingMoveToNextStage = false;

  const moveToNextStage = async () => {
    logger.info('Handle stage completed');
    if (executingMoveToNextStage) {
      logger.warn('Already executing moveToNextStage');
      return;
    }

    const completedStage = state.get().completionQueue[0];
    if (!completedStage) {
      logger.warn('No completed stage to move to');
      return;
    }

    executingMoveToNextStage = true;
    errorHandler.removeError('stage');
    try {
      const exec = createRetryableExecutor(state, api, executionData);
      exec.initExecution(completedStage);

      await exec.moveToNextStage();
      await exec.onExecutionDataUpdated();
      await exec.prevStage.onExit();
      await exec.prevStage.onPostExit();

      if (exec.isFinishStage()) {
        state.set((controller) => {
          controller.completed = true;
        });
        onCompletion();
      } else {
        await exec.nextStage.onEnter();
      }
      exec.finishExecution(completedStage);

      executingMoveToNextStage = false;
      const nextCompletedStage = state.get().completionQueue[0];
      if (nextCompletedStage) {
        await moveToNextStage();
      }
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Error handling stage completion';
      logger.error(`Error handling stage completion: ${errorMessage}`);

      errorHandler.addError('stage', errorMessage);
      executingMoveToNextStage = false;
      throw error;
    }
  };

  const handleStageCompletion = async (stageType: ExecutionState) => {
    logger.info('Controller received stage completion', stageType);
    const isInQueue = state.get().completionQueue.includes(stageType);
    const isCompleted = state.get().completedStages.includes(stageType);
    logger.debug('isInQueue', isInQueue, 'isCompleted', isCompleted);
    if (!isInQueue && !isCompleted) {
      logger.info('Adding stage to completion queue', stageType);
      state.set((controller) => {
        controller.completionQueue = [...controller.completionQueue, stageType];
      });
    }

    await moveToNextStage();
  };

  const handleLotNumberScan = (partId: number, identifier: PartIdentifier) => {
    logger.debug('Controller received scan: ', partId, identifier);
    const controller = state.get();
    controller.getCurrentStage().handleLotNumberScan?.(partId, identifier);
  };

  const handleSerialNumberScan: IStageController['handleSerialNumberScan'] = (
    serialNumbers,
    identifier,
    itemType
  ) => {
    logger.debug('Controller received serial number scan', serialNumbers, identifier);
    const controller = state.get();
    controller.getCurrentStage().handleSerialNumberScan?.(serialNumbers, identifier, itemType);
  };

  const handlePrinterStateChanged: IStageController['handlePrinterStateChanged'] = () => {
    logger.debug('Controller received printer state change');
    const controller = state.get();
    controller.getCurrentStage().handlePrinterStateChanged?.();
  };

  const handleRetry = async (): Promise<void> => {
    logger.info('Controller received retry');
    const controller = state.get();
    // Retry only if the execution is not completed
    if (controller.execState) {
      await moveToNextStage();
    }
  };

  const handleResetStage = () => {
    logger.info('Controller received reset stage');
    if (executingMoveToNextStage) {
      logger.warn('Already executing moveToNextStage');
      return;
    }

    const currentStage = state.get().getCurrentStage();
    const { handleReset } = currentStage;

    if (!handleReset) {
      logger.warn('Reset not implemented for stage', currentStage.type);
      return;
    }

    logger.info('Resetting stage', currentStage.type);
    handleReset();
  };

  const stages = await initializeStages({
    executionData,
    executionKind,
    config,
    loader,
    state,
    io,
    api,
    stageFactory,
    handleStageCompletion,
  });

  const getCurrentStage = () => {
    const { currentStageType } = state.get();
    return getStage(currentStageType, stages);
  };

  return {
    completed: restoredController?.completed || false,
    kind: executionKind,
    stages,
    executionData,
    execState: restoredController?.execState,
    completionQueue: restoredController?.completionQueue || [],
    completedStages: restoredController?.completedStages || [],
    errors: {},
    currentStageType: executionData.state,
    getCurrentStage,
    handleLotNumberScan,
    handleSerialNumberScan,
    handlePrinterStateChanged,
    handleRetry,
    handleResetStage,
    onExit: () => {},
  };
}

async function initializeStages<T extends WorkOrderKind>({
  executionData,
  executionKind,
  config,
  loader,
  io,
  api,
  state,
  stageFactory,
  handleStageCompletion,
}: {
  executionData: WorkbenchExecution;
  executionKind: T;
  config: ControllerDependencies['config'];
  loader: IStagesDataLoader;
  io: IControllerIO;
  api: IApi;
  state: IState<IStageController<T>>;
  stageFactory: IStageFactory;
  handleStageCompletion: (stageType: ExecutionState) => void;
}): Promise<StageConfig[T]> {
  // Create encapsulated states
  const inputState = createEncapsulatedState<IStageController<T>, IInputStage>(
    state,
    (_state) => _state.stages.input as IInputStage
  );
  const outputState = createEncapsulatedState<IStageController<T>, IOutputStage>(
    state,
    (_state) => _state.stages.output as IOutputStage
  );

  // Initialize common stages
  const inputStage = await stageFactory.createInputStage({
    executionData,
    config,
    loader: loader.inputLoader,
    state: inputState,
    io,
    onCompletion: () => handleStageCompletion(ExecutionState.Input),
  });

  const outputStage = await stageFactory.createOutputStage({
    executionData,
    config,
    loader: loader.outputLoader,
    state: outputState,
    io,
    api,
    onCompletion: () => handleStageCompletion(ExecutionState.Output),
  });

  if (executionKind === WorkOrderKind.Default) {
    return {
      input: inputStage,
      output: outputStage,
    } as StageConfig[T];
  }

  if (executionKind === WorkOrderKind.CardProg) {
    const cardProgState = createEncapsulatedState<
      IStageController<WorkOrderKind.CardProg>,
      ICardProgStage
    >(
      state as IState<IStageController<WorkOrderKind.CardProg>>,
      (_state) => _state.stages.cardprog
    );

    const cardProgStage = await stageFactory.createCardProgStage({
      executionData,
      config,
      loader: loader.cardProgLoader,
      state: cardProgState,
      io,
      api,
      onCompletion: () => handleStageCompletion(ExecutionState.CardProg),
    });

    return {
      input: inputStage,
      cardprog: cardProgStage,
      output: outputStage,
    } as StageConfig[T];
  }

  if (executionKind === WorkOrderKind.Qa) {
    const qaState = createEncapsulatedState<IStageController<WorkOrderKind.Qa>, IQaStage>(
      state as IState<IStageController<WorkOrderKind.Qa>>,
      (_state) => _state.stages.qa
    );

    const qaStage = await stageFactory.createQaStage({
      executionData,
      config,
      loader: loader.qaLoader,
      state: qaState,
      io,
      onCompletion: () => handleStageCompletion(ExecutionState.Qa),
    });

    return {
      input: inputStage,
      qa: qaStage,
      output: outputStage,
    } as StageConfig[T];
  }

  throw new Error('Unsupported WorkOrderKind');
}
