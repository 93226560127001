import { Button, Container, Group, Stack } from '@mantine/core';
import { QRCodeCanvas } from 'qrcode.react';
import { useEffect } from 'react';
import { canvasToGCode, canvasToGcode3, loadImgAndFixCanvas } from '@/pages/debug/Laser/utils';

import i from './qr-code.png';

const config = {
  dpi: 10,
  width: 400,
  height: 415,
};

export function LaserPage() {
  let canvas: HTMLCanvasElement;
  let ctx: CanvasRenderingContext2D;
  let inputImage: HTMLImageElement;
  let canvasQr: HTMLCanvasElement;
  let textarea: HTMLTextAreaElement;

  const ppmm = (px: number) => px * (config.dpi / 25.4); // get the pixel-per-millimeter ratio

  const drawOnCanvas = () => {
    const x = ppmm(5); // 5mm
    const y = ppmm(5); // 5mm
    const w = ppmm(76); // 76mm
    const h = ppmm(76); // 76mm

    // ctx.rect(x, y, w, h); // drawing a rectangle the simple way
    // ctx.stroke();

    // ctx.font = '32px monospace';
    // ctx.fillText('X1QGTL', ppmm(10), ppmm(76));

    // ctx.drawImage(canvasQr, 0, 0, 128, 128);
    loadImgAndFixCanvas(canvas, canvasQr);
  };

  useEffect(() => {
    canvas = document.getElementById('canvas') as HTMLCanvasElement;
    ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    inputImage = document.getElementById('inputImage') as HTMLImageElement;
    canvasQr = document.getElementById('qr_canvas') as HTMLCanvasElement;
    textarea = document.getElementById('gcode') as HTMLTextAreaElement;

    canvas.width = 250;
    canvas.height = 250;

    console.debug({ ctx, canvas });
    // @ts-ignore
    window.ctx = ctx;

    if(canvas) {
      drawOnCanvas();
    }
  });

  const gcode = () => {
    const code = canvasToGcode3(canvas);
    textarea.innerHTML = code.join('\n');
  };

  return (
    <Container mt="lg">
      <Stack>
        <Group>
          <Button onClick={drawOnCanvas}>draw</Button>
          <Button onClick={gcode}>gcode</Button>
        </Group>
        <Group>
          <canvas
            id="canvas"
            width={10}
            height={15}
            style={{
              border: '2px dashed lime',
              background: 'white',
              // width: '100%',
            }}
          />
          <QRCodeCanvas
            value="X1QGTL"
            id="qr_canvas"
            bgColor="transparent"
            size={250}
            // style={{ display: 'none' }}
          />
          <img id="inputImage" src={i} />
          <textarea id="gcode" />
        </Group>
      </Stack>
    </Container>
  );
}

/////
