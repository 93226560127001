import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useSyncExternalStore } from 'react';
import { WorkbenchExecution } from '@/utils/api/fab.types';
import { useBarcode } from '@/utils/KeyInput/Barcode';
import { ExecutionRenderStore } from './ExecutionRender';

export const useExecution = (data: WorkbenchExecution) => {
  const q = useQueryClient();
  const store = useMemo(() => new ExecutionRenderStore(q), []);

  useEffect(() => {
    store.load(data, q);
  }, [data, q]);

  useBarcode((code) => {
    store.io.onBarcode(code, q);
  });

  const renderData = useSyncExternalStore(store.subscribe, store.snapshot);
  return [renderData, store.io] as const;
};
