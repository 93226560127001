import { QueryClient } from '@tanstack/react-query';
import { fabApi } from '@/config';
import { ExecutionState, SerialNumber } from '@/utils/api/fab.types';
import { log } from '@/utils/log';
import { type ExecutionRenderStore } from './ExecutionRender';

type ErrorKeys = 'barcode' | string;

export class ExecutionIO {
  store: ExecutionRenderStore;
  autoPrint: boolean;
  focusId: string;
  errors: Partial<Record<ErrorKeys, string>> = {};

  constructor(store: ExecutionRenderStore) {
    log.debug('ExecutionRenderStore::constructor()', this);
    this.store = store;
    this.autoPrint = false;
    this.focusId = '';
  }

  error = (key: keyof typeof this.errors, msg: string | null) => {
    if (msg === null) {
      this.errors[key] = undefined;
    } else {
      this.errors[key] = msg;
    }
    this.store.dispatchUpdate();
  };

  async onBarcode(code: string, q: QueryClient): Promise<boolean> {
    let updated = false;
    if (code.startsWith('ln:')) {
      const lotNumber = code.substring(3);
      log.info('ExecutionIO::onBarcode -> Scanned lot number:', lotNumber);
      updated = await this.handleLotNumber(lotNumber, q);
    }

    if (code.startsWith('sn:')) {
      const serialNumber = code.substring(3);
      log.info('ExecutionIO::onBarcode -> Scanned serial number:', serialNumber);
      updated = await this.handleSerialNumber(serialNumber, q);
    }

    // card SN
    if (code.startsWith('https://persidius.link/c/')) {
      const cardSN = code.substring(25);
      log.info('ExecutionIO::onBarcode -> Scanned Card SN:', cardSN);
      updated = await this.handleSerialNumber(cardSN, q, 'card');
    }

    this.error('barcode', updated ? null : 'Unknown code scanned!');

    //todo: should be called on Execution
    if (this.store.e.isCurrentStageReady()) {
      const nextStage = this.store.e.getNextStage();
      if (nextStage) {
        await this.store.e.advance(nextStage, q);
      }
    }

    if (updated) {
      this.store.dispatchUpdate();
    }

    return updated;
  }

  onCommand = (cmd: string) => {
    this.focusId = cmd;
    this.store.dispatchUpdate();
  };

  isFocused(id: string): boolean {
    return this.focusId === id;
  }

  async handleSerialNumber(serialNumber: string, q: QueryClient, hint?: 'box' | 'card'): Promise<boolean> {
    log.info('ExecutionIO::handleSerialNumber -> serialNumber[%o]', serialNumber);

    //todo: do not call api on stage.output and serialNumberAction === 'generate'
    let fabSerialNumbers: SerialNumber[] = [];
    try {
      fabSerialNumbers = await q.fetchQuery({
        queryKey: [fabApi.serialNumbers.key, serialNumber],
        queryFn: () => fabApi.serialNumbers.getBySN(serialNumber),
        staleTime: Infinity,
      });
      this.error('api', null);
    } catch (e) {
      log.error('query:fabSerialNumbers', e);
      this.error('api', 'Cannot verify the serial number with the server');
    }

    const { serialNumberAction } = this.store.e.config.output ?? {};
    const isOutputSerialGenerated = serialNumberAction === 'generate';

    try {
      if (this.store.e.isCardProgWorkKind()) {
        const isOkForCardProg = this.store.e.cardProg?.onScanPart(fabSerialNumbers, {
          serial_number: serialNumber,
        }, hint);
        if (isOkForCardProg) {
          return true;
        }
        log.info('ExecutionIO::handleSerialNumber -> isOkForCardProg[%o]', isOkForCardProg);
      } else {
        log.info(
          'ExecutionIO::handleSerialNumber -> NOT WorkOrderKind.CardProg[%o]',
          this.store.e.getWorkOrderKind()
        );
      }

      if (
        this.store.e.getCurrentStage() === ExecutionState.Output ||
        this.store.e.config.output?.allowEarlyScan
      ) {
        const output = this.store.e.getOutput();
        if (!output) {
          this.error('barcode', 'No output found!');
          return false;
        }

        if (isOutputSerialGenerated) {
          log.info(
            'ExecutionIO::handleSerialNumber -> isOutputSerialGenerated[%o]',
            isOutputSerialGenerated
          );
          this.error('api', null);

          return output.onScanPart({ serial_number: serialNumber });
        }

        const sn = fabSerialNumbers.find((fabSn) => fabSn.part_id === output.data.partId);
        if (!sn) {
          return false;
        }

        log.info(
          'ExecutionIO::handleSerialNumber -> output.data.partId[%o] === [%o]sn.part_id',
          output.data.partId,
          sn.part_id
        );

        return output.onScanPart({ serial_number: serialNumber });
      }
    } catch (e) {
      return false;
    }

    return false;
  }

  async handleLotNumber(lotNumber: string, q: QueryClient): Promise<boolean> {
    try {
      const lot = await q.fetchQuery({
        queryKey: [fabApi.lots.key, lotNumber],
        queryFn: () => fabApi.lots.getByLotNumber(lotNumber),
      });
      this.error('api', null);

      if (this.store.e.getCurrentStage() === ExecutionState.Input) {
        return this.store.e.getInput()?.onScanPart(lot.part_id, { lot_number: lotNumber }) || false;
      }
    } catch (e) {
      log.error(e);
      this.error('api', 'Cannot verify the lot number with the server');
      return false;
    }

    return false;
  }
}
