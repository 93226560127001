import { Code } from '@mantine/core';
import { useState } from 'react';
import { useBarcode } from '@/utils/KeyInput/Barcode';

export function CardsPage() {
  const [cards, setCards] = useState([] as string[]);

  useBarcode((code) => {
    const cardSN = code.substring(25);

    if (!cardSN) {
      return;
    }
    setCards((prev) => [...prev, cardSN]);
  });

  console.debug(new Set(cards).size, [...new Set(cards)]);

  return (
    <>
      <Code block>{new Set(cards).size}</Code>
      <Code block>{JSON.stringify([...new Set(cards)], null, 2)}</Code>
    </>
  );
}
