const settings = {
  feedRateMove: 6000,
  blackThreshold: 200, //0..255
  blackLevel: 7, //0..255
  width: 85, //mm
  height: 85,
  unit: 'mm',
  dpi: 254,
  moveCloser: true,
  timer: undefined,
  laserPowerMax: 1000,
  laserPowerMin: 0,
  laserOnCommand: 'M4',
  laserOffCommand: 'M5',
  positionCmd: 'G0',
  moveCmd: 'G1',
};

function toPos(n: number) {
  return n.toFixed(2);
}

const laserHeight = 415;
export const canvasToGcode3 = (canvas: HTMLCanvasElement) => {
  const context = canvas.getContext('2d')!;
  const { height, width } = canvas;

  let gcode = [
    `; width: ${width}px`,
    `; height: ${height}px`,
    'M4 S100',
    'M8',
    'G21',
    'G90',
    'G17',
    'G40',
    'G54',
    'G0 X0 Y0',
  ];

  const data = context.getImageData(0, 0, width, height).data;  
  // everything outside bounds is white (255)
  const getV = (x: number, y: number): number => {
    return data.at((y * width + x) * 4)!;
  }

  const posX = (canvasX: number) => canvasX / 10;
  const posY = (canvasY: number) => (height - canvasY) / 10;
  
  type DrawMove = {
    y: number,
    sx: number,
    ex: number,
  };

  // reversing the moves means reversing the array + reversing
  // the 
  const extractMoves = (y: number): DrawMove[] => {
    const moves = [];
    let v = getV(0, y);
    let vX = 0;
    for(let x = 1; x < width; ++x) {
      if (getV(x, y) !== v) {
        if (v === 0) {
          moves.push({
            y: posY(y), 
            sx: posX(vX), 
            ex: posX(x - 1)});
        }
        v = getV(x, y);
        vX = x;        
      }
    }

    if(v === 0) {
      moves.push({
        y: posY(y),
        sx: posX(vX),
        ex: posX(width),
      })
    }

    return moves;
  }

  const extractMovesReverse = (y: number): DrawMove[] => {
    // to reverse the draw moves
    // 1. the array needs reversing
    // 2. sx/ex need to be swapped
    const moves = extractMoves(y);

    moves.reverse();
    let ex;
    for(const move of moves) {
      ex = move.ex;
      move.ex = move.sx;
      move.sx = ex;
    }

    return moves;
  }
  
  const drawMoves: DrawMove[] = [];

  for (let y = height; y >= 0; --y) {
    if(y % 2) {
      drawMoves.push(...extractMovesReverse(y));
    } else {
      drawMoves.push(...extractMoves(y));
    }
  }

  let x = 0, y = 0;
  for(const move of drawMoves) {
    if(move.sx !== x || move.y !== y) {
      // emit a G0 position
      gcode.push(`G0 X${move.sx} Y${move.y} F6000`);
    }
    gcode.push(`G1 X${move.ex} F2000`);

    x = move.ex;
    y = move.y;
  }

  gcode.push(...[
    'M5',
    'G0 X0 Y0',
  ])

  return gcode;
}

//todo: refactor; cleanup; return text; move to worker? check bounds!
let line = 0;
export function canvasToGCode(canvas: HTMLCanvasElement, outPutDoc: HTMLTextAreaElement) {
  const ctx = canvas.getContext('2d')!;
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const pixels = imageData.data;
  let sLine = '';

  const stepX = settings.width / canvas.width; //mm per pixel
  const stepY = settings.height / canvas.height;
  if (line === 0) {
    outPutDoc.innerHTML += `; mm/Pixel ${toPos(stepX)} ${toPos(stepY)}\n`;
    outPutDoc.innerHTML += `; ${settings.dpi} dpi \n`;
    outPutDoc.innerHTML += 'G90 ;absolute Position\n';
    outPutDoc.innerHTML += 'G21 ;Set Units to Millimeters\n'; //
    outPutDoc.innerHTML += `G0 X0Y0 F${settings.feedRateMove} \n`;
  }

  let lPosX = 0;
  const lPosY = -line * stepY;
  const y = line;

  if (settings.moveCloser && line !== 0) {
    //TODO: Test
    sLine += `${settings.moveCmd} X${toPos(lPosX)} S0\n`;
    sLine += `${settings.moveCmd} Y${toPos(lPosY - 2)}\n`;
  }

  sLine += `G1 X${toPos(lPosX)} Y${toPos(lPosY)} S0\n`;
  sLine += `${settings.laserOnCommand}\n`;

  let valueCount = 0;
  let x = 0;
  let index = x * 4 + y * canvas.width * 4;

  let lastPixel = pixels[index];
  let minBlack = 255;
  lPosX += stepX;

  for (x = 1; x < canvas.width; x += 1) {
    index = x * 4 + y * canvas.width * 4; //index=rgba * y*width*rgba
    const red = pixels[index];

    //run lengths...
    if (red !== lastPixel || x === canvas.width - 1) {
      if (x === canvas.width - 1) {
        lPosX += stepX;
      }

      const isBlack = lastPixel === 0;
      const laserPower = isBlack ? settings.laserPowerMax : settings.laserPowerMin;
      const currentLine = `G1 X${toPos(lPosX)}  S${laserPower}\n`;

      if (x === canvas.width - 1) {
        //Remove empty runs at the end
        if (lastPixel < 255) {
          sLine += currentLine;
        }
      } else {
        sLine += currentLine;
      }

      valueCount += 1;
      if (minBlack > lastPixel) {
        minBlack = lastPixel;
      }

      lastPixel = red;
    }

    lPosX += stepX;
  }

  sLine += `${settings.laserOffCommand}\n`;

  //if line =0 then go straight to the next
  if (valueCount > 0 && minBlack < 255) {
    //do not create blank lines
    outPutDoc.innerHTML += sLine;
  }

  line += 1;
  if (line < canvas.height) {
    console.debug('..');
    window.setTimeout(() => canvasToGCode(canvas, outPutDoc), 0);
  } else {
    // /end
    outPutDoc.innerHTML += 'S0\n'; //
    outPutDoc.innerHTML += 'G0 X0 Y0\n';
    outPutDoc.innerHTML += ' ; end \n';
  }
}

export function loadImgAndFixCanvas(canvas: HTMLCanvasElement, inputImage?: HTMLCanvasElement) {

  const ctx = canvas.getContext('2d')!;
  if(inputImage) {
    ctx.drawImage(
      inputImage,
      0,
      0,
      inputImage.width,
      inputImage.height,
      0,
      0,
      canvas.width,
      canvas.height
    );
  }
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const pixels = imageData.data;

  for (let y = 0; y < canvas.height; y += 1) {
    for (let x = 0; x < canvas.width; x += 1) {
      const index = x * 4 + y * canvas.width * 4;

      //todo: rewrite
      let r = pixels[index];
      let g = pixels[index + 1];
      let b = pixels[index + 2];
      const a = pixels[index + 3];

      if (settings.blackLevel < 255) {
        const gg = 255 / settings.blackLevel;
        r = Math.min(Math.round(Math.round(r / gg) * gg), 255);
        g = Math.min(Math.round(Math.round(g / gg) * gg), 255);
        b = Math.min(Math.round(Math.round(b / gg) * gg), 255);
      }

      // get approx. luma value from RGB
      let luma = Math.round(r * 0.299 + g * 0.587 + b * 0.114);

      if (a < 255) {
        pixels[index + 3] = 255;
        if (luma === 0) {
          luma = 255;
        }
      }

      if (luma > settings.blackThreshold) {
        luma = 255;
      }

      pixels[index] = luma;
      pixels[index + 1] = luma;
      pixels[index + 2] = luma;
    }
  }

  ctx.putImageData(imageData, 0, 0);
}

export function canvasToGCode2(canvas: HTMLCanvasElement) {
  const context = canvas.getContext('2d')!;
  const { height, width } = canvas;

  let gcode = '';
  const laserOnCommand = 'M4';
  const laserOffCommand = 'M5';
  const moveCommand = 'G0';
  const fireCommand = 'G1';
  // const feedRate = 6000;

  // Add initial G-code commands
  gcode += 'G21 ; Set units to mm\n';
  gcode += 'G90 ; Use absolute coordinates\n';
  gcode += `${laserOnCommand} ; Turn on the laser\n`;

  // Iterate over the canvas pixels
  for (let y = 0; y < height; y += 1) {
    for (let x = 0; x < width; x += 1) {
      const pixelData = context.getImageData(x, y, 1, 1).data;
      const [r, g, b, a] = pixelData;

      // If the pixel is not transparent
      if (a > 0) {
        // if (r === 0 && g === 0 && b === 0) {
        gcode += `${fireCommand} X${x} Y${height - y} S${(r + g + b) / 3}\n`;
      } else {
        gcode += `${laserOffCommand}\n`;
        gcode += `${moveCommand} X${x} Y${height - y}\n`; // Move to position without cutting
      }
    }
  }

  // Add final G-code commands
  gcode += `${laserOffCommand} ; Turn off the laser\n`;
  gcode += 'G0 X0 Y0 ; Move back to origin\n';

  return gcode;
}
