import { Logger } from '@/logger';
import { IScanHandler, TScanPayload } from '../interfaces/single-execution/store';
import { IApi } from '../interfaces/api';

const logger = new Logger('ScanHandler');

export const handleScan: IScanHandler = async ({ scannedCode, api }) => {
  const lotNumberPrefix = 'ln:';
  const serialNumberPrefix = 'sn:';
  const cardSNPrefix = 'https://persidius.link/c/';

  if (scannedCode.startsWith(lotNumberPrefix)) {
    const lotNumber = scannedCode.substring(lotNumberPrefix.length);
    return handleLotNumberScan(lotNumber, api);
  }

  if (scannedCode.startsWith(serialNumberPrefix)) {
    const serialNumber = scannedCode.substring(serialNumberPrefix.length);
    return handleSerialNumberScan(serialNumber, api);
  }

  if (scannedCode.startsWith(cardSNPrefix)) {
    const cardSN = scannedCode.substring(cardSNPrefix.length);
    return handleSerialNumberScan(cardSN, api, 'card');
  }

  logger.error('Unknown code scanned:', scannedCode);
  throw new Error('Unknown code scanned');
};

async function handleSerialNumberScan(
  serialNumber: string,
  api: IApi,
  itemType?: 'card' | 'box'
): Promise<TScanPayload> {
  logger.debug('Scanned serial number:', serialNumber);

  const serialNumbers = await api.serialNumbers.getBySN(serialNumber);

  const payload: TScanPayload = {
    type: 'serial',
    itemType,
    serialNumbers,
    identifier: { serial_number: serialNumber },
  };

  return payload;
}

async function handleLotNumberScan(lotNumber: string, api: IApi): Promise<TScanPayload> {
  logger.debug('Scanned lot number:', lotNumber);

  const lot = await api.lots.getByLotNumber(lotNumber);

  const payload: TScanPayload = {
    type: 'lot',
    partId: lot.part_id,
    identifier: { lot_number: lotNumber },
  };

  return payload;
}
