import { Alert, Code } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { log } from '@/utils/log';
import { ExecutionRenderData } from '@/pages/debug/NewExe/types';

type ExecutionErrorsProps = {
  errors: ExecutionRenderData['errors'];
};
export function ExecutionErrors(props: ExecutionErrorsProps) {
  const { errors } = props;

  log.debug('ExecutionErrors:: errors[%o]', errors);
  const thereAreErrors =
    Object.values(errors).filter((x) => x && Object.values(x).filter((y) => y).length > 0).length >
    0;

  if (!thereAreErrors) {
    return null;
  }

  return (
    <Alert variant="light" color="red" title="Execution errors" my="md" icon={<IconInfoCircle />}>
      <Code block color="red">
        {JSON.stringify(errors, null, '  ')}
      </Code>
    </Alert>
  );
}
