import { useWindowEvent } from '@mantine/hooks';
import { useRef } from 'react';

type UseKeydownEventsOptions = {
  onBarcodeRead?: (code: string) => void;
};

export function useKeydownEvents(options: UseKeydownEventsOptions = {}) {
  const { onBarcodeRead = () => {} } = options;
  const keydownRef = useRef({
    msg: '',
  });

  useWindowEvent('keydown', (event) => {
    const { key, code } = event;
    const isEnter = code === 'Enter';

    if (isEnter) {
      const { msg } = keydownRef.current;

      keydownRef.current = {
        ...keydownRef.current,
        msg: '',
      };

      if (msg.length) {
        onBarcodeRead(msg);
      }

      return;
    }

    if (key.length > 1) {
      return;
    }

    keydownRef.current = {
      ...keydownRef.current,
      msg: `${keydownRef.current.msg}${key}`,
    };
  });
}
