import Decimal from 'decimal.js';
import { ExecutionRenderData } from '@/pages/debug/NewExe/types';
import { useController } from './hooks';
import { WorkbenchExecution, WorkOrderKind } from '@/utils/api/fab.types';
import { IInputStage } from '@/store/interfaces/stages/input';
import { Config } from '@/config';
import { IOutputStage } from '@/store/interfaces/stages/output';
import { ICardProgStage } from '@/store/interfaces/stages/card-prog';
import { IStageController } from '@/store/interfaces';
import { useSingleExecutionStore } from '../store';
import { getLoadedInputStatus } from '../../stages/input/init';

export function useRenderData(): ExecutionRenderData | undefined {
  const store = useSingleExecutionStore();
  const controller = useController() as unknown as IStageController<WorkOrderKind.CardProg>;
  if (!controller) {
    return undefined;
  }

  const execution = controller.executionData;

  if (!execution.recipe) {
    throw new Error('Execution recipe is missing');
  }

  const renderData: ExecutionRenderData = {
    recipeName: execution.recipe.name,
    loading: false, // TODO: remove
    hasCardProg: isCardProgWorkKind(execution),
    inputs: getInputData(controller.stages.input),
    outputs: getOutputData(controller.stages.output),
    cardProg: getCardProgData(controller.stages.cardprog),
    activeStage: controller.currentStageType,
    errors: getAllErrors(controller, store.errors),
    executionCount: store.executionsCounter
      ? {
          total: store.executionsCounter.limit,
          current: store.executionsCounter.counter,
        }
      : null,
  };

  return renderData;
}

const EmptyRenderData = {
  outputs: [],
  inputs: [],
};

function isCardProgWorkKind(execution: WorkbenchExecution): boolean {
  return execution.work_order_kind === WorkOrderKind.CardProg;
}

function getInputData(input: IInputStage): ExecutionRenderData['inputs'] {
  if (!input) {
    return EmptyRenderData.inputs;
  }

  return Object.values(input.data.parts).map((part, index) => {
    const [valid, actionText] = getLoadedInputStatus(part.partId, input.data);
    const cmd = (101 + index).toString();
    // const focused = this.io.isFocused(cmd);
    const focused = false; // TODO: fix this
    const loadedParts = part.loaded.map((loaded) => ({
      qty: loaded.qty.toFixed(Config.decimalPrecision),
      identifier: loaded.identifier,
    }));
    const loadedQty = part.loaded.reduce((acc, loaded) => acc.add(loaded.qty), new Decimal(0));

    return {
      partName: part.partName,
      reqQty: part.reqQty.toFixed(Config.decimalPrecision),
      loadedQty: loadedQty.toFixed(Config.decimalPrecision),
      serialized: part.serialized,
      valid,
      focused,
      cmd,
      actionText,
      loadedParts,
    };
  });
}

function getOutputData(output: IOutputStage): ExecutionRenderData['outputs'] {
  const outputData = output.data;
  if (!outputData) {
    return EmptyRenderData.outputs;
  }

  const { serialized } = outputData;
  const outputParts = outputData.parts || [];
  return outputParts.map((outputPart, index) => {
    const cmd = (201 + index).toString();
    // const focused = this.io.isFocused(cmd);
    const focused = false; // TODO: fix this

    const barcodeScan = {
      required: outputData.scanRequired,
      done: outputPart.scanned,
    };

    const { identifier } = outputPart;
    const valid = barcodeScan.required ? barcodeScan.done : true;
    return {
      cmd,
      valid,
      barcodeScan,
      focused,
      serialized,
      identifier,
      partName: outputData.partName,
      qty: outputPart.qty.toFixed(Config.decimalPrecision),
    };
  });
}

function getCardProgData(cardProg: ICardProgStage): ExecutionRenderData['cardProg'] {
  if (cardProg) {
    return {
      liveCardStatus: cardProg.data.liveCardStatus,
      liveCardUid: cardProg.data.liveCardUid,
      boxSerialNumber: cardProg.data.boxSerialNumber,
      cardId: cardProg.data.cardId,
      noaId: cardProg.data.noaId,
      cardUid: cardProg.data.cardUid,
    };
  }

  return {
    liveCardStatus: 'missing',
  };
}

function getAllErrors(
  controller: IStageController<WorkOrderKind.CardProg>,
  storeErrors: Record<string, string | undefined | null>
): ExecutionRenderData['errors'] {
  type ErrObj = Record<string, string | undefined | null>;
  const hasErrors = (obj?: ErrObj) =>
    obj && Object.keys(obj).length > 0 && Object.values(obj).some(Boolean);
  const getErrors = (obj?: ErrObj) => (hasErrors(obj) ? obj : undefined);

  return {
    output: getErrors(controller.stages.output?.errors),
    execution: getErrors(storeErrors),
    io: getErrors(controller.errors), // TODO: fix this
    cardProg: getErrors(controller.stages.cardprog?.errors),
  };
}
